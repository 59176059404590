import React, { useEffect, useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';


function LoginComponent() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { isLoginLoading, loginApiStatusCode, loginMsg } = useSelector(state => state.auth);
    const [fieldValue, setFieldValue] = useState({})

    const onFinish = (values) => {
        setFieldValue(values)
        dispatch({ type: 'LOGIN_REQUEST', formData: values });
    };

    useEffect(() => {
        if (!isLoginLoading) {
            if (loginMsg === 'success') {
                message.success(t('login.success'));
                dispatch({ type: 'CLEAR_LOGIN_DATA' });
                navigate('/mfa?email=' + fieldValue.email);
            } else if (loginApiStatusCode === 503) {
                message.error(t('user.maintenance'));
            } else if (loginApiStatusCode === 401) {
                message.error(t('user.error_email_password_valid'));
            }
        }
    }, [isLoginLoading, loginApiStatusCode, loginMsg])

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onGoToForgetPassword = () => {
        navigate('/forget-password', { tab: 'firebase' });
    };

    return (
        <div className='sign'>
            <div className='sign-content'>
                <Form
                    className='sign-form'
                    name='basic'
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}>
                    <Form.Item
                        name='email'
                        className='form-field'
                        rules={[{ required: true, message: t("login.error_email") }]}>
                        <Input placeholder={t("login.email")} />
                    </Form.Item>

                    <Form.Item
                        name='password'
                        className='form-field'
                        rules={[{ required: true, message: t("login.error_password") }]}>
                        <Input.Password placeholder={t("login.password")} />
                    </Form.Item>

                    <div className='rememberMe'>

                        <span className='sign-link'>
                            <span onClick={onGoToForgetPassword}>{t("login.forget_password")}</span>
                        </span>
                    </div>

                    <div className='form-btn-field'>
                        <Button
                            type='primary'
                            htmlType='submit'
                            className='sign-btn'
                            loading={isLoginLoading}
                            disabled={isLoginLoading}
                        >
                            {t("login.sign_in")}
                        </Button>
                    </div>

                </Form>
            </div>

        </div>
    )
}
export default LoginComponent;