import React, {useState, useEffect} from 'react';
import { Form, Modal, Button, Popover, Spin, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SURVEY_LINK } from '../../../utils/constant';

const { Option } = Select;

const ShareSurveyModal = ({surveyRecord, langList, visible, onClose}) => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const [lang, setLang] = useState(i18n.language);
    const [filteredUploads, setFilteredUploads] = useState([]);
    const filteredLangList = langList.filter(item => surveyRecord.stream_languages.includes(item.value));

    const handleLangChange = (value) => {
        setLang(value);
    };

    const {qrCode} = useSelector(state => state.survey);

    const content = (text) => {
        return (<p style={{marginBottom: "0px"}}>{text}</p>)
    }

    const handleDownload = async () => {
        for (const file of filteredUploads) {
            const newTab = window.open(file.url, '_blank');
            if (newTab) {
                newTab.focus();
            } else {
                alert('Please allow pop-ups to open the file.');
            }
        }
    };

    const generateSurveyLink = (lang) => {
        return `${SURVEY_LINK}/?r=survey/index&newtest=Y&sid=${surveyRecord.survey_id}&lang=${lang}&list=${surveyRecord.survey_version}&OrgID=${surveyRecord.organization_id}&ProgID=${surveyRecord.program_id}`;
    };

    const generateQRCode = (lang) => {
        dispatch({
            type: 'GENERATE_QR_CODE',
            formData: {
                program_id: surveyRecord.id,
                survey_id: surveyRecord.program_survey_id,
                url: generateSurveyLink(lang),
                lang: lang
            }
        });
    };

    const openQRCode = (url, lang) => {
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');

                a.style.display = 'none';
                a.href = url;

                a.download = `qr-${surveyRecord.name}-${surveyRecord.survey_type}-${lang}.png`

                document.body.appendChild(a);

                a.click();

                document.body.removeChild(a);

                window.URL.revokeObjectURL(url);
            })
            .catch(error => console.error('Error downloading the image:', error));
    };

    useEffect(() => {
        generateQRCode(lang);
        setFilteredUploads(surveyRecord.uploads.filter(file => file.lang === lang));
    }, [lang]);

    const sectionStyle = {
        paddingTop: '10px',
        paddingBottom: '25px',
        paddingLeft: '30px',
        paddingRight: '30px',
        backgroundColor: '#EEEEEE',
        marginBottom: '16px',
        borderRadius: '16px',
    };

    const sectionHeading = {
        fontSize: '14px',
        marginBottom: 0,
        fontWeight: 700
    }

    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            footer={null}
            width={700}
        >
            <div>
                <h2 style={sectionHeading}>{t('share_survey.title')}</h2>
                <p style={{color: '#000000'}}>{t('share_survey.heading')}</p>
            </div>
            <div
                style={{
                    marginBottom: '20px'
                }}
            >
                <h2 style={sectionHeading}>{t('share_survey.survey_language_title')}</h2>
                <Form
                    className='sign-form'
                    name='basic'
                    layout="vertical"
                >
                    <Form.Item
                        name='lang'
                        className='form-field'
                    >
                        <Select
                            defaultValue={lang}
                            style={{
                                width: 400
                            }}
                            onChange={handleLangChange}
                            autoComplete='none'
                            size='large'
                            optionFilterProp="children"
                        >
                            {filteredLangList.length > 0 && filteredLangList.map((item) =>
                                <Option key={item.value} value={item.value}>{t(`languages.${item.value}`)}</Option>
                            )}
                        </Select>
                    </Form.Item>
                </Form>
            </div>
            <div style={sectionStyle}>
                <h3 style={sectionHeading}>{t('share_survey.survey_link_title')}</h3>
                <p style={{marginBottom: '10px', color: '#000000'}}>
                    {t('share_survey.survey_link_desc')}
                </p>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                    <Popover placement='top'
                             overlayClassName='password-tooltip'
                             content={() => content(t('common.copy'))}
                             trigger={'click'}
                    >
                        <Button type='primary'
                                shape='round'
                                style={{width: '120px'}}
                                onClick={() => navigator.clipboard.writeText(generateSurveyLink(lang))}
                        >
                            {t('common.copy_link')}
                        </Button>
                    </Popover>
                </div>
            </div>
            <div style={sectionStyle}>
                <h3 style={sectionHeading}>{t('share_survey.qr_code_title')}</h3>
                <p style={{marginBottom: '10px', color: '#000000'}}>
                    {t('share_survey.qr_code_desc')}
                </p>
                <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                    {
                        qrCode ? <img src={qrCode} style={{marginLeft: '10px'}}/>
                            : <Spin style={{marginLeft: '30px'}}/>
                    }
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                    {
                        qrCode &&
                        <Button type='primary'
                                shape='round'
                                style={{width: '120px'}}
                                onClick={() => openQRCode(qrCode, lang)}
                        >
                            {t('common.download')}
                        </Button>
                    }


                </div>
            </div>
            {
                filteredUploads.length > 0 &&
                <div style={sectionStyle}>
                    <h3 style={sectionHeading}>{t('share_survey.paper_version_title')}</h3>
                    <p style={{marginBottom: '10px', color: '#000000'}}>
                        {t('share_survey.paper_version_desc')}
                    </p>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Button type='primary'
                                shape='round'
                                size='default'
                                style={{width: '120px'}}
                                onClick={handleDownload}
                        >
                            {t('common.download')}
                        </Button>
                    </div>

                </div>
            }

        </Modal>
    );
};

export default ShareSurveyModal;