import {role} from "./functions";

export const API_URL = process.env.REACT_APP_API_URL || "https://uat-api.crg.ca/api/"; // API base URL
export const BASE_URL = process.env.REACT_APP_API_BASE_URL; // API base URL
export const CLIENT_NAME = process.env.REACT_APP_CLIENT || "OTTAWA"; // Client Name
export const BASE_BACKEND_URL = process.env.REACT_APP_API_BASE_URL; // API base URL
export const TABLEAU_APP_ID = process.env.REACT_APP_TABLEAU_APP_ID;
export const TABLEAU_SECRET_ID = process.env.REACT_APP_TABLEAU_SECRET_ID;
export const TABLEAU_SECRET_VALUE= process.env.REACT_APP_TABLEAU_SECRET_VALUE;
export const TABLEAU_USER_EMAIL = process.env.REACT_APP_TABLEAU_USER_EMAIL;

export const PER_PAGE = 15; // API base URL

export const VERSION_NUMBER = "1.1.0"

export const TABLEAU_URL = process.env.REACT_APP_TABLEUA_URL || "https://prod-ca-a.online.tableau.com/t/context/views/"

export const ROLE = role();

export const SURVEY_LINK = "https://survey.crg.ca/index.php";

export const ROLE_NAME = {
    "CRG_USER": "crg_user",
    "CLIENT_ADMIN": "client_admin_user",
    "CLIENT_USER": "client_user",
    "ORG_ADMIN": "org_admin_user",
    "ORG_USER": "org_user",
}

export const AGE_GROUPS = [
    {
        label: "0-6",
        value: "0-6"
    },
    {
        label: "7-13",
        value: "7-13"
    },
    {
        label: "14-19",
        value: "14-19"
    },
    {
        label: "20-29",
        value: "20-29"
    },
    {
        label: "30-54",
        value: "30-54"
    },
    {
        label: "55-64",
        value: "55-64"
    },
    {
        label: "65+",
        value: "65+"
    }
]
