/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {Button, Form, Input, Row, Col, message, Card, Switch, Spin, Select} from 'antd';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import Wrapper from '../comnon/wrapper';
import useTitle from '../comnon/title';
import {useTranslation} from 'react-i18next';
import DeleteModal from '../comnon/modal.js';
import useSettings from '../comnon/useSettings';
import ExportOrg from './exportOrganization';
import {ROLE_NAME} from '../../../utils/constant';
import {role} from '../../../utils/functions';
import ViewOrgEmbedCode from './ViewOrgEmbedCode';

const {Option} = Select;

const AddOrganization = () => {
    const {orgId} = useParams();
    const {t} = useTranslation();
    const isEnable = useSettings();
    const userRole = role();
    useTitle(`${!orgId ? t('organization.add_title') : t('organization.edit_title')}`);

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        isAddLoading,
        isAddedMsg,
        isGetLoading,
        organizationData,
        isToggleLoading,
        orgStatus,
        isDeleteLoading,
        deleteStatus
    } = useSelector(state => state.organization);
    const {isLoading: isStreamLoading, streamList,} = useSelector(state => state.stream);
    const {settingsData} = useSelector(state => state.user);

    const [checked, setChecked] = useState(false);
    const [isSwitchLoading, setIsSwitchLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [deleteId, setDeleteId] = useState("");
    const [filteredSurveys, setFilteredSurveys] = useState([]);


    const onFinish = (values) => {
        if (orgId) {
            dispatch({type: 'ORGANIZATION_UPDATE_REQUEST', id: orgId, formData: values});
        } else {
            dispatch({type: 'ORGANIZATION_ADD_REQUEST', formData: values});
        }
    }

    const loadSettings = (params = {}) => {
        dispatch({type: 'SETTINGS_REQUEST', formData: params});
    }

    useEffect(() => {
        if (orgId && !isStreamLoading) {
            dispatch({type: 'ORGANIZATION_DETAIL_REQUEST', id: orgId});
            loadSettings();
        }
    }, []);

    useEffect(() => {
        dispatch({type: 'STREAM_LIST_REQUEST', formData: {}});
    }, []);

    useEffect(() => {
        if (!isAddLoading && isAddedMsg === "success") {
            dispatch({type: 'CLEAR_STATUS'});
            if (!orgId) {
                message.success({content: t('organization.add_success_msg'), className: 'custom-success-class'});
            } else {
                message.success({content: t('organization.update_success_msg'), className: 'custom-success-class'});
            }
            navigate('/organization');
        }
    }, [isAddLoading, isAddedMsg]);

    useEffect(() => {
        if (!isToggleLoading && orgStatus !== "") {
            dispatch({type: 'CLEAR_STATUS'});
            if (orgStatus === "activated") {
                message.success({content: t('organization.active_success_msg'), className: 'custom-success-class'});
            } else {
                message.warn({content: t('organization.archived_success_msg'), className: 'custom-success-class'});
            }
        }
    }, [isToggleLoading, orgStatus]);

    useEffect(() => {
        if (orgId && !isGetLoading && Object.keys(organizationData).length > 0) {
            setDeleteId(orgId)
            setIsSwitchLoading(true);
            setChecked(organizationData.archived_at === null ? true : false);
            form.setFieldsValue({
                name: organizationData.name,
                organization_identifier: organizationData.organization_identifier
            });
            if (organizationData.streams.length > 0) {
                let tmpStreams = organizationData.streams.map(e => e.id);
                form.setFieldsValue({streams: tmpStreams});
                onStreamChange(tmpStreams);
            }
            if (organizationData.stream_surveys.length > 0) {
                let tmpStreamSurveys = organizationData.stream_surveys.map(e => e.id);
                form.setFieldsValue({surveys: tmpStreamSurveys});
            } else {
                form.setFieldsValue({surveys: []});
            }
            setTimeout(() => {
                setIsSwitchLoading(false);
            }, 500);
        }
    }, [isGetLoading, organizationData, streamList])

    const onFinishFailed = () => {
    };

    const onSwitchChange = (e) => {

        dispatch({type: "ORGANIZATION_TOGGLE_REQUEST", orgId: orgId, status: e})
    }

    const onDelete = (e) => {
        dispatch({type: 'ORGANIZATION_DELETE_REQUEST', id: e});
    }

    useEffect(() => {
        if (!isDeleteLoading && deleteStatus === "success") {
            setVisible(false);
            setDeleteId("");
            navigate('/organization');
        }
    }, [isDeleteLoading, deleteStatus]);

    const onStreamChange = (streams) => {
        const selectedSurveys = streamList
            .filter(stream => streams.includes(stream.id))
            .flatMap(stream => stream.surveys)
            .map(survey => ({
                id: survey.id,
                name: survey.name,
                name_fr: survey.name_fr
            }));
        setFilteredSurveys(selectedSurveys);
    };


    return (
        <Wrapper title={`Organization ${orgId ? "Update" : "Add"}`}>
            <div className='padding-around input-class'>
                <div style={{marginBottom: "20px"}}>
                    <ExportOrg/>
                </div>
                {isGetLoading && <Spin style={{width: "100%"}}/>}
                {!isGetLoading &&
                    (userRole === ROLE_NAME.CRG_USER || userRole === ROLE_NAME.CLIENT_ADMIN) &&
                    <>
                        {
                            orgId &&
                            <Card className='archive-card'>
                                <div className='content'>
                                    <p>{t('organization.status_msg')}</p>
                                    <div className='switch-delete'>
                                        <p>
                                            {isSwitchLoading && <Switch
                                                checkedChildren={<span>{t('common.active')}</span>}
                                                unCheckedChildren={<span>{t('common.archived')}</span>}
                                                defaultChecked={checked}
                                                size='large'
                                                loading={isSwitchLoading}
                                            />}
                                            {!isSwitchLoading && <p>
                                                <Switch
                                                    checkedChildren={<span>{t('common.active')}</span>}
                                                    unCheckedChildren={<span>{t('common.archived')}</span>}
                                                    defaultChecked={checked}
                                                    onChange={(e) => onSwitchChange(e)}
                                                    size='large'
                                                    loading={isSwitchLoading || isToggleLoading}
                                                />
                                            </p>}
                                        </p>
                                        <Button
                                            htmlType="submit"
                                            type="primary"
                                            shape="round"
                                            danger
                                            onClick={() => {
                                                setVisible(true)
                                            }}
                                            className='ml2'
                                            size={"medium"}
                                        >
                                            {t('common.delete')}
                                        </Button>
                                    </div>

                                </div>
                            </Card>
                        }

                        <Form
                            className='sign-form'
                            name='basic'
                            onFinish={onFinish}
                            layout="vertical"
                            form={form}
                            onFinishFailed={onFinishFailed}>

                            <Row gutter={70}>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                    <Form.Item
                                        label={t("common.name")}
                                        name='name'
                                        className='form-field'
                                        rules={[
                                            {
                                                required: true,
                                                message: t("organization.error_name"),
                                            },
                                            {
                                                max: 250,
                                                message: t("organization.error_name_max"),
                                            }
                                        ]}
                                    >
                                        <Input placeholder={t("organization.place_name")}/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                    <Form.Item
                                        label={t("organization.id")}
                                        name="organization_identifier"
                                        className='form-field'
                                        rules={[{required: false, message: ''}]}>
                                        <Input placeholder={t('organization.place_id')} readOnly disabled/>
                                    </Form.Item>
                                </Col>
                                {
                                    isEnable && (
                                        <>
                                            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                                <Form.Item
                                                    label={t("organization.stream")}
                                                    name='streams'
                                                    className='form-field'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t("organization.error_stream")
                                                        }
                                                    ]}
                                                >
                                                    <Select
                                                        showSearch
                                                        placeholder={t("organization.select_stream")}
                                                        optionFilterProp="children"
                                                        onChange={onStreamChange}
                                                        loading={isStreamLoading}
                                                        autoComplete="none"
                                                        size="large"
                                                        mode="multiple"
                                                    >

                                                        {!isStreamLoading && streamList.length > 0 && streamList.map((item) =>
                                                            <Option key={item.id} value={item.id}>{item.name_en}</Option>
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                                <Form.Item
                                                    label={t("organization.survey")}
                                                    name='surveys'
                                                    className='form-field'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t("organization.error_survey")
                                                        }
                                                    ]}
                                                >
                                                    <Select
                                                        placeholder={t("organization.select_survey")}
                                                        optionFilterProp="children"
                                                        autoComplete="none"
                                                        size="large"
                                                        mode="multiple"
                                                    >
                                                        {filteredSurveys.map((item) =>
                                                            <Option key={item.id} value={item.id}>{item.name}</Option>
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </>
                                )}
                            </Row>
                            <Row gutter={70} className={"mt10"}>
                                <Col xs={{span: 24, offset: 3}} md={{span: 24, offset: 6}} lg={{span: 12, offset: 9}}>
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        shape="round"
                                        loading={isAddLoading}
                                        disabled={isAddLoading}
                                        size={"large"}>
                                        {orgId ? t('common.update') : t('common.confirm')}
                                    </Button>
                                    <Button type="secondary" onClick={() => {
                                        dispatch({type: "CLEAR_STATUS"});
                                        navigate('/organization')
                                    }} className={"ml5"} shape="round" size={"large"}>
                                        {t('common.cancel')}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </>
                }

                {
                    orgId &&
                    (settingsData.embed_code_url || settingsData.embed_code_url_fr) &&
                    (userRole === ROLE_NAME.CRG_USER || userRole === ROLE_NAME.CLIENT_ADMIN || userRole === ROLE_NAME.ORG_ADMIN) &&
                    <ViewOrgEmbedCode orgId={organizationData.id}/>
                }


                <DeleteModal
                    visible={visible}
                    deleteId={deleteId}
                    subTitle={t("organization.delete_confirm")}
                    setVisible={() => {
                        setVisible(false)
                    }}
                    onDelete={(e) => onDelete(e)}
                    isDeleteLoading={isDeleteLoading}
                />
            </div>
        </Wrapper>
    )
};

export default AddOrganization;
