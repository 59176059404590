import React, { useState, useEffect } from 'react';
import { Table, Button, Upload, Input, Space, Popconfirm, Select } from 'antd';
import { PlusCircleOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const { Column } = Table;
const { Option } = Select;

const SurveySupportGuide = ({ surveyId, surveyFiles: initialSurveyFiles, onFileListChange }) => {
    const { t, i18n } = useTranslation();
    const [fileList, setFileList] = useState({ [surveyId]: initialSurveyFiles });
    const [uploadedFileUid, setUploadedFileUid] = useState(null);
    const dispatch = useDispatch();
    const  { langList, langListMsg } = useSelector(state => state.stream);

    const { isFileUploading, uploadFileURL, uploadFileName, fileMsg } = useSelector(state => state.stream);

    const handleUpload = (info) => {
        let files = [...info.fileList];
        files = files.map(file => ({
            ...file,
            uid: Math.random().toString(36).substring(7),
            surveyId: surveyId,
            id: null,
            name: file.name,
            status: 'uploading',
            url: null,
            editing: true,
        }));
        setFileList(prevFileList => ({ ...prevFileList, [surveyId]: [...prevFileList[surveyId], ...files] }));
    };

    const handleSaveName = (index) => {
        const file = fileList[surveyId][index];
        setUploadedFileUid(file.uid);
        let formData = new FormData();
        formData.append('name', file.name);
        formData.append('lang', file.lang);
        formData.append('document', file.originFileObj);
        dispatch({ type: 'UPLOAD_DOCUMENT_REQUEST', formData: formData });
    };

    useEffect(() => {
        if (!isFileUploading && uploadFileURL !== "" && fileMsg === "success") {
            dispatch({type: 'CLEAR_STREAM_IMAGE'});
            const uploadedFileIndex = fileList[surveyId].findIndex(file => file.uid === uploadedFileUid);
            if (uploadedFileIndex !== -1) {
                const updatedFileList = [...fileList[surveyId]];
                updatedFileList[uploadedFileIndex] = {
                    ...updatedFileList[uploadedFileIndex],
                    status: 'saved',
                    url: uploadFileURL,
                    name: uploadFileName,
                    editing: false

                };
                setFileList(prevFileList => ({
                    ...prevFileList,
                    [surveyId]: updatedFileList
                }));
                onFileListChange(surveyId, updatedFileList);
            }
            setUploadedFileUid(null);
        }
    }, [isFileUploading]);

    const handleCancelEdit = (index) => {
        setFileList((prevFileList) => {
            const updatedFileList = { ...prevFileList };
            if (updatedFileList[surveyId]) {
                updatedFileList[surveyId] = updatedFileList[surveyId].filter((item, i) => i !== index);
            }
            return updatedFileList;
        });
    };

    const handleDelete = (upload) => {
        let uploadedFileIndex;
        if (upload.id) {
            uploadedFileIndex = fileList[surveyId].findIndex(file => file.id === upload.id);
            dispatch({ type: 'DELETE_DOCUMENT_REQUEST', surveyStreamId: upload.stream_survey_id, streamSurveyUploadId: upload.id });
        } else {
            uploadedFileIndex = fileList[surveyId].findIndex(file => file.uid === upload.uid);
        }


        if (uploadedFileIndex !== -1) {
            const updatedFileList = [...fileList[surveyId]];
            updatedFileList.splice(uploadedFileIndex, 1);
            setFileList(prevFileList => ({
                ...prevFileList,
                [surveyId]: updatedFileList
            }));
            onFileListChange(surveyId, updatedFileList);
        }
    };

    const handleViewFileURL = (url) => {
        window.open(url, '_blank');
    };


    const columns = [
        {
            title: 'File Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record, index) => {
                return (
                    <div>
                        {record.editing ? (
                            <Input
                                value={text}
                                onChange={(e) => {
                                    const updatedFileList = [...fileList[surveyId]];
                                    updatedFileList[index].name = e.target.value;
                                    setFileList(prevFileList => ({ ...prevFileList, [surveyId]: updatedFileList }));
                                }}
                                style={{ width: 400 }}
                            />
                        ) : (
                            text
                        )}
                    </div>
                );
            },
        },
        {
            title: 'Language',
            dataIndex: 'lang',
            key: 'lang',
            render: (text, record, index) => {
                return (
                    <div>
                        {record.editing ? (
                            <Select
                                onChange={(e) => {
                                    const updatedFileList = [...fileList[surveyId]];
                                    updatedFileList[index].lang = e;
                                    setFileList(prevFileList => ({ ...prevFileList, [surveyId]: updatedFileList }));
                                }}
                                style={{
                                    width: 200,
                                }}
                            >
                                {langList.length > 0 && langList.map((item) =>
                                    <Option key={item.value} value={item.value}>{t(`languages.${item.value}`)}</Option>
                                )}
                            </Select>
                        ) : (
                            t(`languages.${langList.find(item => item.value === text)?.value}`)
                        )}
                    </div>
                );
            },
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record, index) => (
                <Space>
                    {record.status === 'uploading' ? (
                        <>
                            <Button
                                onClick={() => handleSaveName(index)}
                                loading={isFileUploading}
                                type="primary"
                            >
                                Save
                            </Button>
                            <Button
                                onClick={() => handleCancelEdit(index)}
                            >
                                Cancel
                            </Button>
                        </>
                    ) : (
                        <>
                            {
                                record.status === 'uploaded' &&
                                <EyeOutlined onClick={() => handleViewFileURL(record.url)} />
                            }

                            <Popconfirm
                                title="Are you sure to delete this file?"
                                onConfirm={() => handleDelete(record)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <DeleteOutlined />
                            </Popconfirm>
                        </>
                    )}
                </Space>
            ),
        },
    ];

    return (
        <>
            <div style={{ float: 'right' }}>
                <Upload
                    onChange={handleUpload}
                    accept={'.pdf'}
                    fileList={[]}
                    showUploadList={false}
                    multiple
                >
                    <Button icon={<PlusCircleOutlined style={{ color: '#1890ff' }} />}
                            type="text">Upload Files
                    </Button>
                </Upload>
            </div>

            <Table
                dataSource={fileList[surveyId]}
                columns={columns}
                rowKey={(record, index) => index}
                pagination={false}
            />
        </>
    );
};

export default SurveySupportGuide;
