/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { DownloadOutlined } from '@ant-design/icons';
import { CSVLink } from "react-csv";
import { useParams } from "react-router-dom";

const ExportOrg = ({ orgData }) => {

    const dispatch = useDispatch();
    const { orgId } = useParams();
    const { isOrgExportLoading, orgExportData } = useSelector(state => state.organization);
    const [groupExportLoading, setIsGroupExportLoading] = useState(isOrgExportLoading);
    const [tmpOrgExportData, setTmpOrgExportData] = useState("");

    const exportOrgDetail = () => {
        setIsGroupExportLoading(true);
        dispatch({ type: 'ORGANIZATION_EXPORT_REQUEST', orgId: orgId });
    }

    useEffect(() => {
        if (!isOrgExportLoading && orgExportData !== "") {
            setTmpOrgExportData(orgExportData);
            setTimeout(() => {
                dispatch({ type: "ORG_STEP_CLEAR" });
                setIsGroupExportLoading(isOrgExportLoading);
                document.getElementById('exportToCSVLink').click();
            }, 1000);
        }
    }, [isOrgExportLoading, orgExportData]);



    return (
        <div className='export-org'>
            <Button id="export-group"
                className='export-btn'
                type="primary"
                shape="circle"
                loading={groupExportLoading}
                disabled={groupExportLoading}
                onClick={() => exportOrgDetail()}
                icon={<DownloadOutlined />} size={"medium"} />
            <label htmlFor="export-group" className="nav-add-label cursor">&nbsp;&nbsp;Export</label>
            <CSVLink id="exportToCSVLink" filename="Organization detail.csv" data={tmpOrgExportData} />
        </div>
    );
};


export default ExportOrg;                     