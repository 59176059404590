import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Row, Col, message, Select, Switch, Spin, Table, Card, Space, Popover, Checkbox } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AppstoreOutlined, BarsOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useTitle from '../comnon/title';
import Wrapper from '../comnon/wrapper';
import { ROLE_NAME } from '../../../utils/constant';
import { getUserData, role, getOrganizationId } from '../../../utils/functions';
import DeleteModal from '../comnon/modal';

const { Column } = Table;
const { Option } = Select;
let selectedIds = [];

const AddSurveys = () => {
    const { surveyId } = useParams();
    const { t, i18n } = useTranslation();
    useTitle(!surveyId ? t("survey.add_title") : t("survey.edit_title"));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { isLoading: isStreamLoading, streamList } = useSelector(state => state.stream);
    const { isAddLoading, addStatus, isGetLoading, surveyData, isToggleLoading, isDeleteLoading, deleteStatus, streamStatus} = useSelector(state => state.survey);
    const { isLoading: isOrgLoading, organizationList } = useSelector(state => state.organization);
    const [refresh, setRefresh] = useState(false);
    const [loadHtml, setLoadHtml] = useState("");
    const [view, setView] = useState('grid');
    const [tableData, setTableData] = useState([]);

    const [isSwitchLoading, setIsSwitchLoading] = useState(false);
    const [checked, setChecked] = useState(false);
    const [visible, setVisible] = useState(false);
    const [deleteId, setDeleteId] = useState("");
    const [selectedFiles, setSelectedFiles] = useState([]);

    useEffect(() => {
        loadStream();
        return () => {
            dispatch({ type: "CLEAR_SURVEY_DATA" });
            dispatch({ type: "CLEAR_STREAM_DATA" });
        }
    }, []);

    const loadStream = (params = {}) => {
        dispatch({ type: 'STREAM_LIST_REQUEST', formData: params });
    }

    useEffect(() => {
        if (surveyId) {
            dispatch({ type: 'SURVEY_DETAIL_REQUEST', id: surveyId });
        }
    }, []);

    useEffect(() => {
        dispatch({ type: "ORGANIZATION_LIST_REQUEST", formData: { status: "active" } });
    }, []);

    useEffect(() => {
        if (!surveyId && organizationList.length > 0) {
            if ([ROLE_NAME.ORG_ADMIN, ROLE_NAME.ORG_USER].includes(role())) {
                handleChangeOrg(getOrganizationId());
            }
        }
    }, [organizationList]);


    const onDelete = (e) => {
        dispatch({ type: 'SURVEY_DELETE_REQUEST', id: e });
    }

    useEffect(() => {
        if (!isDeleteLoading && deleteStatus === "success") {
            message.success({ content: t('survey.delete_success_msg'), className: 'custom-success-class' });
            dispatch({ type: 'CLEAR_SURVEY' });
            dispatch({ type: 'CLEAR_STATUS' });
            setVisible(false);
            setDeleteId("");
            navigate('/surveys');
        }
    }, [isDeleteLoading, deleteStatus]);

    const onSwitchChange = (e) => {
        dispatch({ type: "SURVEY_TOGGLE_REQUEST", surveyId: surveyId, status: e })
    }

    const handleCheckboxChange = (e, file) => {
        const { checked } = e.target;
        if (checked) {
            setSelectedFiles([...selectedFiles, file]);
        } else {
            setSelectedFiles(selectedFiles.filter(item => item !== file));
        }
    };

    const handleDownload = async () => {
        for (const file of selectedFiles) {
            const newTab = window.open(file.url, '_blank');
            if (newTab) {
                newTab.focus();
            } else {
                alert('Please allow pop-ups to open the file.');
            }
        }
    };

    const fetchSurveyName =  (item) => {
        if (i18n.language === 'fr' && item.name_fr && item.name_fr !== '') {
            return item.name_fr;
        } else if (i18n.language === 'en' && item.name && item.name !== '') {
            return item.name;
        } else if (item.name_fr && item.name_fr !== '') {
            return item.name_fr;
        }
        return item.name;
    }

    const downloadFilesPopoverContent = (record) => {
        return (
            <div style={{ width: '200px' }}>
                {record.uploads.map((file, index) => (
                    <div key={index}>
                        <Checkbox onChange={(e) => handleCheckboxChange(e, file)}>{file.name}</Checkbox>
                    </div>
                ))}
                {
                    record.uploads.length > 0 ?
                    <Button
                        type="primary" shape="round" size={"default"}
                        disabled={selectedFiles.length === 0}
                        onClick={handleDownload}
                        style={{ marginTop: '10px' }}
                    >
                        {t('common.download')}
                    </Button> :
                    <p>{t('common.no_downloaded_file')}</p>
                }
            </div>
        );

    };

    const loadSurvey = (tempReports, groupIndex, groupId) => {
        let tmpHtml = [];
        if (tempReports.length > 0) {
            tempReports.map((item, index) => {
                let shouldRender = false;
                if (surveyData && surveyData.programSurveys && surveyData.programSurveys.length > 0) {
                    surveyData.programSurveys.map((data) => {
                        if (data.survey && data.survey.id === item.id) {
                            shouldRender = true;
                        }
                    });
                } else if (!surveyId && organizationList.length > 0 && [ROLE_NAME.ORG_ADMIN, ROLE_NAME.ORG_USER].includes(role())) {
                    const organization = organizationList.find(org => org.id === getOrganizationId());
                    const streamSurveyIds = organization.stream_surveys.map(streamSurvey => streamSurvey.id);
                    if (streamSurveyIds.includes(item.id)) {
                        shouldRender = true;
                    }
                } else {
                    shouldRender = item.status !== 0;
                }
                if (shouldRender) {
                    let desc = "";
                    if (i18n.language === "fr" && item.description_fr !== null && item.description_fr !== "") {
                        desc = item.description_fr;
                    } else {
                        if (item.description_en !== null && item.description_en !== "") {
                            desc = item.description_en;
                        }
                    }

                    let custId = `stream_${groupIndex}_${index}`;
                    return tmpHtml.push(
                        <Popover content={() => downloadFilesPopoverContent(item)} trigger="click" placement="bottom">
                            <div
                                className='report-list'
                                key={custId}
                                onMouseEnter={() => {
                                    if (item?.uploads.length > 0) {
                                        handleMouseEnter(custId)
                                    }
                                }}
                                onMouseLeave={() => handleMouseLeave(custId)}
                                id={custId}
                            >
                                <p className={`overlay`} ><span>{t('common.download_pdf')}</span></p>
                                <p className='report-title'>{fetchSurveyName(item)}</p>
                                <p className={`report-description ${desc !== "" ? 'mt1' : ''}`} >
                                    {desc}
                                </p>
                            </div>
                        </Popover>
                    )
                }
            })
        } else {
            tmpHtml = <p>{t('survey.not_found')}</p>
        }
        return tmpHtml;
    }

    useEffect(() => {

        if (view === "table") {
            if (!isStreamLoading && streamList.length > 0 && !isGetLoading && Object.keys(surveyData).length > 0) {
                let orgStreamIdArray = [];
                let orgStreamSurveyIdArray = [];
                surveyData?.programSurveys.map((data) => {
                    if (data.stream) {
                        if (data.stream.status === 1) {
                            orgStreamIdArray.push(data.stream.id);
                        }
                    }
                    if (data.survey) {
                        if (data.survey.status === 1) {
                            orgStreamSurveyIdArray.push(data.survey.id);
                        }
                    }
                });

                const filteredStreamList = streamList.filter(stream => orgStreamIdArray.includes(stream.id));

                let tmpArray = [];
                filteredStreamList.map((item) => {
                    if (item.status === 1) {
                        if (item.surveys.length > 0) {
                            item.surveys.map((data) => {
                                if (orgStreamSurveyIdArray.includes(data.id)) {
                                    let Obj = {};
                                    Obj.stream_name = item.name_en;
                                    if (data.status === 1) {
                                        Obj = { ...Obj, ...data };
                                        tmpArray.push(Obj);
                                    }
                                }
                            });
                        }
                    }
                });
                setTableData(tmpArray);

            }
            else if (!isStreamLoading && streamList.length > 0 && organizationList.length > 0) {
                const organization = organizationList.find(org => org.id === getOrganizationId());
                const streamSurveyIds = organization.stream_surveys.map(streamSurvey => streamSurvey.id);
                let tmpArray = [];
                streamList.map((item) => {
                    if (item.status === 1) {
                        if (item.surveys.length > 0) {
                            item.surveys.map((data) => {
                                let Obj = {};
                                Obj.stream_name = item.name_en;
                                if (!surveyId && [ROLE_NAME.ORG_ADMIN, ROLE_NAME.ORG_USER].includes(role())) {
                                    if (streamSurveyIds.includes(data.id)) {
                                        Obj = { ...Obj, ...data }
                                        tmpArray.push(Obj);
                                    }
                                }
                                else if (data.status === 1) {
                                    Obj = { ...Obj, ...data }
                                    tmpArray.push(Obj);
                                }
                            })
                        }
                    }

                });
                setTableData(tmpArray);
            }
        } else {
            if (!isStreamLoading && streamList.length > 0 && !isGetLoading && Object.keys(surveyData).length > 0) {
                let orgStreamIdArray = [];
                let orgStreamSurveyIdArray = [];
                setRefresh(true);
                if (Object.keys(surveyData.organization).length > 0) {
                    form.setFieldsValue({ organization_id: surveyData.organization.id });
                    surveyData.organization.streams.map(stream =>  {
                        orgStreamIdArray.push(stream.id);
                    });
                    surveyData.organization.stream_surveys.map(streamSurvey =>  {
                        orgStreamSurveyIdArray.push(streamSurvey.id);
                    });
                }
                form.setFieldsValue({
                    name_en: surveyData.name_en,
                    name_fr: surveyData.name_fr
                });
                setIsSwitchLoading(true);
                setChecked(surveyData.status === 1 ? true : false);

                surveyData?.programSurveys.map((data) => {
                    if (data.stream) {
                        if (data.stream.status === 1 && !orgStreamIdArray.includes(data.stream.id)) {
                            orgStreamIdArray.push(data.stream.id);
                        }
                    }
                    if (data.survey) {
                        if (data.survey.status === 1 && !orgStreamSurveyIdArray.includes(data.survey.id)) {
                            orgStreamSurveyIdArray.push(data.survey.id);
                        }
                    }
                });
                selectedIds = orgStreamSurveyIdArray;
                const filteredStreamList = streamList.filter(stream => orgStreamIdArray.includes(stream.id));
                loadStreamData(filteredStreamList, orgStreamSurveyIdArray);
                setTimeout(() => {
                    setIsSwitchLoading(false);
                }, 100);
            }
        }

    }, [isStreamLoading, streamList, isGetLoading, surveyData, organizationList , i18n.language, view]);

    const onSurveySwitchChange = (e, id) => {
        let tmpIds = [...selectedIds];
        if (e) {
            if (!tmpIds.includes(id)) {
                tmpIds.push(id);
            }
        } else {
            if (tmpIds.includes(id)) {
                tmpIds = tmpIds.filter(e => e !== id);
            }
        }
        selectedIds = tmpIds;
    }

    const loadStreamData = (streamData, tmpSelectedIds) => {
        setLoadHtml("");
        let tmpHtml = streamData.length > 0 && streamData.map((item, index) => {
            if (item.status !== 0) {
                return (
                    <div className="role-switch survey mb4">
                        <div className='gird-box-survey'>
                            <div className='gird-box-inner'>
                                <p className='group-name'>{i18n.language === "fr" ? item.name_fr : item.name_en}</p>
                                <div className='grid-repor-right'>
                                    {loadSurveyToggle(item.surveys, index, tmpSelectedIds)}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        })
        setRefresh(false);
        setLoadHtml(tmpHtml);
    }

    const loadSurveyToggle = (streamListData, groupIndex, tmpSelectedIds = []) => {
        let tmpHtml = [];
        if (streamListData.length > 0) {
            streamListData.map((item, index) => {
                if (item.status === 1 && tmpSelectedIds.length && tmpSelectedIds.includes(item.id)) {
                    let isChecked = false;

                    if (Object.keys(surveyData).length > 0) {
                        surveyData?.programSurveys.map((data) => {
                            if (data.survey && data.survey.id === item.id) {
                                isChecked = true;
                            }
                        });
                    } else {
                        isChecked = item.status === 1;
                    }

                    let custId = `stream_${groupIndex}_${index}`;
                    return tmpHtml.push(
                        <div
                            className='section'
                            test={index}
                            key={custId}
                            id={custId}
                        >
                            <p className='report-title mb0'>{fetchSurveyName(item)}</p>
                            <div className="report-user-select">
                                <Switch
                                    checkedChildren={<span>{t('common.yes')}</span>}
                                    unCheckedChildren={<span>{t('common.no')}</span>}
                                    defaultChecked={isChecked}
                                    onChange={(e) => onSurveySwitchChange(e, item.id)}
                                    size='large'
                                />
                            </div>
                        </div>
                    )
                }

            })
        } else {
            tmpHtml = <p>{t('survey.not_found')}</p>
        }
        return tmpHtml;
    }

    const onFinish = (values) => {
        let Obj = {};
        Obj.name_en = values.name_en;
        Obj.name_fr = values.name_fr;

        if ([ROLE_NAME.ORG_ADMIN, ROLE_NAME.ORG_USER].includes(role())) {
            Obj.organization_id = getUserData().organization.id;
        } else {
            Obj.organization_id = values.organization_id;
        }


        if (values.name_en === undefined && values.name_fr === undefined) {
            message.error({ content: t('survey.name_error'), className: 'error-class', duration: 4 });
            return;
        }
        if ((values.name_en === undefined || values.name_en === "") && (values.name_fr === undefined || values.name_fr === "")) {
            message.error({ content: t('survey.name_error'), className: 'error-class', duration: 4 });
            return;
        }
        if (selectedIds.length === 0) {
            message.error({ content: t('survey.survey_error'), className: 'error-class', duration: 4 });
            return;
        }
        Obj.surveys = selectedIds;
        if (surveyId) {
            dispatch({ type: 'SURVEY_UPDATE_REQUEST', formData: Obj, id: surveyId })
        } else {
            dispatch({ type: 'SURVEY_ADD_REQUEST', formData: Obj })
        }
    }

    useEffect(() => {
        if (!isAddLoading && addStatus === "success") {
            dispatch({ type: 'CLEAR_SURVEY' });
            if (surveyId) {
                message.success({ content: t('survey.update_success_msg'), className: 'custom-success-class' });
            } else {
                message.success({ content: t('survey.add_success_msg'), className: 'custom-success-class' });
            }
            navigate(`/surveys`);
        }
    }, [isAddLoading, addStatus]);

    const handleMouseEnter = (custId) => {
        document.getElementById(custId).classList.add('active')
    }
    const handleMouseLeave = (custId) => {
        document.getElementById(custId).classList.remove('active')
    }

    const handleChangeOrg = (orgId) => {
        const organization = organizationList.find(org => org.id === orgId);
        const streamIds = organization.streams.map(stream => stream.id);
        const streamSurveyIds = organization.stream_surveys.map(streamSurvey => streamSurvey.id);

        let tmpArray = [];
        const filteredStreamList = streamList
            .filter(stream => streamIds.includes(stream.id))
            .filter(stream => {
                if (stream.surveys.length > 0) {
                    stream.surveys.forEach(data => {
                        if (data.status === 1 && streamSurveyIds.includes(data.id)) {
                            tmpArray.push(data.id);
                        }
                    });
                    return true;
                }
                return false;
            });
        selectedIds = tmpArray;
        loadStreamData(filteredStreamList , tmpArray);
    };

    return (
        <Wrapper>
            <div className='padding-around survey-add'>
                <Row>
                    <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                        <div className="change-view">
                            <p className="title">{t('survey.which_survey')}</p>
                            <div className='switch-view'>
                                <Button className="filter-report" type="primary" shape="circle"
                                        onClick={() => setView(view === 'grid' ? 'table' : 'grid')}
                                        icon={view === 'grid' ? <AppstoreOutlined /> : <BarsOutlined />}
                                        size={"medium"}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
                {isStreamLoading && <Spin style={{ width: "100%" }} />}
                {!isStreamLoading && organizationList.length > 0 &&
                    <Row>
                        {view === "grid" &&
                            <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                                <div className="report-view">

                                    {
                                        streamList.length > 0 && streamList.map((item, index) => {
                                        let shouldRender = false;
                                        if (surveyData && surveyData.programSurveys && surveyData.programSurveys.length > 0) {
                                            surveyData.programSurveys.map((data) => {
                                                if (data.stream && data.stream.id === item.id) {
                                                    shouldRender = true;
                                                }
                                            });
                                        } else {
                                            shouldRender = item.status !== 0;
                                        }

                                        if (shouldRender) {
                                            return (
                                                <div className="role-switch survey mb4">
                                                    <div className='gird-box-survey'>
                                                        <div className='gird-box-inner'>
                                                            <p className='group-name'>{i18n.language === "fr" ? item.name_fr : item.name_en}</p>
                                                            <p className='group-description'>{i18n.language === "fr" ? item.description_fr : item.description_en}</p>
                                                            <div className='grid-report-box'>
                                                                {loadSurvey(item.surveys, index, item.id)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            </Col>
                        }
                        {view === "table" &&
                            <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                                <div className='listing-table'>
                                    <Table loading={isStreamLoading} style={{ width: "100%" }}
                                           dataSource={tableData}
                                           pagination={{
                                              hideOnSinglePage: true,
                                              pageSize: 50
                                           }}
                                    >
                                        <Column title={t("stream.stream")} dataIndex='stream_name' key='stream_name'
                                                render={(text) => <b>{text}</b>}
                                        />
                                        <Column title={t("stream.survey_name")} dataIndex='name' key='name'
                                                render={(text) => <b>{text}</b>}
                                        />

                                        <Column title={t("stream.survey_fr_name")} dataIndex='name_fr' key='name_fr'
                                                render={(text) => <b>{text}</b>}
                                        />

                                        <Column title={t('common.download')} key='action'
                                                render={(_, record) => {
                                                return (
                                                    <>
                                                        <Space size="middle" className={`cursor`}>
                                                            {record.uploads.length > 0 &&
                                                                <Popover content={() => downloadFilesPopoverContent(record)} trigger="click" placement="bottom">
                                                                    <Button type="primary" shape="round" size={"default"}>
                                                                        {t('common.download')}
                                                                    </Button>
                                                                </Popover>
                                                            }
                                                        </Space>
                                                    </>

                                                )
                                            }}
                                        />

                                    </Table>
                                </div>
                            </Col>
                        }
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <div className="program input-class">
                                {surveyId &&
                                    <Card className='archive-card survey'>
                                        <div className='content'>
                                            <p className="update-title">{t('survey.status_msg')}</p>
                                            <div className='switch-delete'>
                                                <p>
                                                    {isSwitchLoading && <Switch
                                                        checkedChildren={<span>{t('common.active')}</span>}
                                                        unCheckedChildren={<span>{t('common.archived')}</span>}
                                                        defaultChecked={checked}
                                                        size='large'
                                                        loading={isSwitchLoading}
                                                    />}
                                                    {!isSwitchLoading && <p>
                                                        <Switch
                                                            checkedChildren={<span>{t('common.active')}</span>}
                                                            unCheckedChildren={<span>{t('common.archived')}</span>}
                                                            defaultChecked={checked}
                                                            onChange={(e) => onSwitchChange(e)}
                                                            size='large'
                                                            loading={isSwitchLoading || isToggleLoading}
                                                        />
                                                    </p>}
                                                </p>
                                                <Button htmlType="submit" type="primary" shape="round" danger
                                                    onClick={() => { setVisible(true) }}
                                                    className='ml2'
                                                    size={"medium"}
                                                >
                                                    {t('common.delete')}
                                                </Button>
                                            </div>

                                        </div>
                                    </Card>
                                }

                                <Form
                                    className='sign-form'
                                    name='basic'
                                    layout="vertical"
                                    onFinish={onFinish}
                                    form={form}
                                >
                                    {![ROLE_NAME.ORG_ADMIN, ROLE_NAME.ORG_USER].includes(role()) &&
                                        <Form.Item
                                            label={t("survey.org")}
                                            name='organization_id'
                                            className='form-field'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t("survey.org_error")
                                                }
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                placeholder={t("survey.org_select")}
                                                optionFilterProp="children"
                                                loading={isOrgLoading}
                                                onChange={handleChangeOrg}
                                                autoComplete="none"
                                                size="large"
                                            >

                                                {!isOrgLoading && organizationList.length > 0 && organizationList.map((item) =>
                                                    <Option key={item.id} value={item.id}>{item.name}</Option>
                                                )}
                                            </Select>
                                        </Form.Item>
                                    }
                                    <Form.Item
                                        name='name_en'
                                        className='form-field'
                                        label={t('survey.program_name_english')}
                                    >
                                        <Input placeholder={t("survey.program_name_english")} />
                                    </Form.Item>
                                    <Form.Item
                                        name='name_fr'
                                        className='form-field'
                                        label={t('survey.program_name_french')}
                                    >
                                        <Input placeholder={t("survey.program_name_french")} />
                                    </Form.Item>
                                    <div className="pt3"></div>
                                    {!refresh && loadHtml}

                                    <Row gutter={70} className={"mt10"}>
                                        <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} className="text-center" >
                                            <Button
                                                htmlType="submit"
                                                type="primary"
                                                shape="round"
                                                loading={isAddLoading}
                                                disabled={isAddLoading}
                                                size={"large"}>
                                                {!surveyId ? t('common.confirm') : t('common.update')}
                                            </Button>
                                            <Button type="secondary" onClick={() => {
                                                dispatch({ type: "CLEAR_STREAM" });
                                                navigate('/surveys')
                                            }} className={"ml5"} shape="round" size={"large"}>
                                                {t('common.cancel')}
                                            </Button>
                                        </Col>
                                    </Row>

                                </Form>
                            </div>
                        </Col>
                    </Row>
                }
                <DeleteModal
                    visible={visible}
                    deleteId={surveyId}
                    subTitle={t("survey.delete_confirm")}
                    setVisible={() => { setVisible(false) }}
                    onDelete={(e) => onDelete(e)}
                    isDeleteLoading={isDeleteLoading}
                />
            </div>
        </Wrapper >
    );
}

export default AddSurveys;