import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Wrapper from '../comnon/wrapper';
import Spinner from 'react-spinkit';


const ViewOrgEmbedCode = ({orgId}) => {
    const { t, i18n } = useTranslation();
    const { settingsData } = useSelector(state => state.user);
    const [loading, setLoading] = useState(true);
    const [dots, setDots] = useState('.');


    useEffect(() => {

        const interval = setInterval(() => {
            setDots(prevDots => prevDots === '...' ? '.' : prevDots + '.');
        }, 1000);

        return () => clearInterval(interval);

    }, []);

    const hideSpinner = () => {
        setTimeout(() => {
            setLoading(false);
        }, 1500);
    }


    return (
        <Wrapper>
            <div className='padding-around report-mngmnt-view'
                 style={{
                     height: '100%',
                     position: 'relative',
                 }}
            >
                {
                    loading ?
                        <>
                            <Spinner name='ball-clip-rotate' color='#006ac3'
                                     style={{
                                         position: 'absolute',
                                         top: '50%',
                                         left: '50%',
                                         transform: 'translate(-50%, -50%)',
                                         zIndex: 9999
                                     }}
                            />

                            <p
                                style={{
                                    position: 'absolute',
                                    top: '55%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    color: '#006ac3',
                                    fontWeight: 'bold',
                                    fontSize: '18px'
                                }}
                            >
                                Loading{dots}
                            </p>

                        </>

                        :
                       null
                }

                <iframe width="100%" height="1600px"
                        allow="clipboard-write;camera;geolocation;fullscreen"
                        onLoad={hideSpinner}
                        frameBorder="0"
                        src={i18n.language === 'fr' && settingsData?.embed_code_url_fr ? `${settingsData.embed_code_url_fr}/${orgId}` : `${settingsData?.embed_code_url}/${orgId}`}>
                </iframe>

            </div>
        </Wrapper>

    )
};

export default ViewOrgEmbedCode;