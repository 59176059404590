/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Form, Input, Button, Row, Col, Upload, Space, message } from 'antd';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CSVFile from '../../../assets/OrganizationDetailsTemplate.csv';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ImportOrg = (props) => {
    const { visible, setVisible, onReferesh } = props;
    const { isImporting, importSuccessMsg, importErrors } = useSelector(state => state.organization);
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [fileName, setfileName] = useState("");
    const [fileObject, setFileObject] = useState({});
    const [errors, setErrors] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        if (!isImporting && importSuccessMsg === "success") {
            onReferesh();
            dispatch({ type: 'ORG_STEP_CLEAR' });
            setVisible(false);
        } else if (!isImporting && importSuccessMsg === "error") {
            if (Array.isArray(importErrors)) {
                setErrors(importErrors);
            }
        }
    }, [isImporting, importSuccessMsg, importErrors])

    useEffect(() => {
        setErrors([]);
    }, []);
    
    const handleFileChange = (info) => {
        setErrors([]);
        if (info.file.status === "uploading") {
            return;
        }
        if (info.file.status === "done") {
            setFileObject(info.file.originFileObj);
            setfileName(info.file.name);
        }
    };

    const importFile = () => {
        setErrors([]);
        if (fileName !== "") {
            const formData = new FormData();
            formData.append("organization_file", fileObject);
            dispatch({ type: 'ORGANIZATION_IMPORT_REQUEST', formData: formData });
        } else {
            message.error('Please select the file for import');
        }

    }

    return (
        <Modal
            className={``}
            title={`Import Organization Details`}
            open={visible}
            footer={false}
            onCancel={() => {
                setErrors([]);
                setVisible(false);
            }}
            centered
            width={650}
            maskClosable={false}

        >

            <div className="mt4">

                <Row className='mt5 mb5'>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className='mb5'>
                        <Upload
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            name="avatar"
                            className="avatar-uploader"
                            onChange={handleFileChange}
                            showUploadList={true}
                            maxCount={1}
                            listType="picture"
                            customRequest={({ file, onSuccess }) =>
                                setTimeout(() => onSuccess("ok"), 0)
                            }
                        >
                            <Button>Choose File</Button>
                        </Upload>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <p>Don't have the import template?&nbsp;
                            <Link to={CSVFile} download="Organization" target="blank">
                                Download now
                            </Link>
                        </p>
                        <div className='error-msg'>
                            {errors.length > 0 && errors.map((e) => {
                                return (<p>{e}</p>)
                            })}
                        </div>
                    </Col>

                </Row>
                <Row>
                    <Space className='center mt5'>
                        <Button
                            type="secondary"
                            onClick={() => {
                                dispatch({ type: "CLEAR_COMMON" });
                                setVisible(false);
                                form.resetFields();
                            }}
                            className={""}
                            shape="round"
                            size={"large"}
                        >
                            {t('common.cancel')}
                        </Button>
                        <Button
                            htmlType="submit"
                            type="primary"
                            shape="round"
                            disabled={isImporting}
                            loading={isImporting}
                            onClick={() => importFile()}
                            size={"large"}>
                            {t('common.import')}
                        </Button>
                    </Space>
                </Row>
            </div>
        </Modal>
    );
};

export default ImportOrg;