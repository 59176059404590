import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AppAnimateGroup from '../../auth/AppAnimateGroup';
import { Button } from 'antd';

const Error403 = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const onGoBackToHome = () => {
        navigate('/');
    };

    return (
        <AppAnimateGroup type='bottom' style={{ height: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div className='error-container' key='a'>
                <div className='error-content mt10'
                     style={{
                         width: '400px',
                         display: 'flex',
                         flexDirection: 'column',
                         alignItems: 'center'
                }}>
                    <h2>
                        {t('page_not_found.title')}
                    </h2>
                    <div className='error-para'>
                        <p className='mb-0'>
                            {t('page_not_found.content')}
                        </p>
                    </div>
                    <div className='error-img' style={{ marginBottom: '20px' }}>
                        <img style={{ width: '140px' }} src='/page-not-found.png' />
                    </div>
                    <Button type='primary' size='large' style={{width: '200px'}} shape='round' className='error-btn' onClick={onGoBackToHome}>
                        {t('page_not_found.button')}
                    </Button>
                </div>
            </div>
        </AppAnimateGroup>
    );
};

export default Error403;
