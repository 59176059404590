import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Input, Tooltip, Space, Table, Tag, message } from 'antd';
import { PlusOutlined, DownloadOutlined, EditFilled } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ShareSurveyModal from './shareSurvey';
import Wrapper from '../comnon/wrapper';
import DeleteModal from '../comnon/modal.js';
import useTitle from '../comnon/title';
import { API_URL, ROLE, ROLE_NAME } from '../../../utils/constant';
import { accessToken } from '../../../utils/functions';
const { Search } = Input;
const { Column } = Table;


const Surveys = () => {

    const { t, i18n } = useTranslation();
    useTitle(t("survey.view_title"));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoading, surveyList, isDeleteLoading, deleteStatus } = useSelector(state => state.survey);
    const  { langList } = useSelector(state => state.stream);
    const [data, setData] = useState([]);

    const [survey, setSurvey] = useState(null);
    const [visible, setVisible] = useState(false);
    const [isShareModalVisible, setIsShareModalVisible] = useState(false);
    const [deleteId, setDeleteId] = useState("");


    useEffect(() => {
        loadStream();
        dispatch({type: 'FETCH_LANG_LIST'});
    }, []);

    useEffect(() => {
        if (!isLoading && surveyList.length > 0) {
            let tmpOrgList = [];
            surveyList.map((itemMain) => {
                if (itemMain.programSurveys !== undefined && itemMain.programSurveys.length > 0) {
                    itemMain.programSurveys.map((item) => {
                        let Obj = {};
                        Obj.name = fetchProgramName(itemMain);
                        Obj.id = itemMain.id;
                        Obj.program_id = itemMain.id;
                        Obj.key = itemMain.id + "main" + item.id;
                        Obj.program_status = itemMain.status === 1 ? "Active" : "Archived";
                        Obj.survey_type = fetchSurveyName(item?.survey);
                        Obj.program_survey_id = item?.survey?.id;
                        Obj.survey_id = item?.survey?.survey_id || "";
                        Obj.survey_version = item?.survey?.survey_version || 1;
                        Obj.organization_id = itemMain?.organization?.organization_identifier || "";
                        Obj.organization_name = itemMain?.organization?.name || "";
                        Obj.survey_status = item?.survey?.status === 1 ? 'Active' : "Archived";
                        Obj.survey_record_id = item?.id;
                        Obj.stream_languages = item?.stream?.languages;
                        const uploads = item?.survey?.uploads || [];
                        // Create an array to hold all uploads for this programSurvey
                        const allUploads = [];

                        uploads.forEach(upload => {
                            allUploads.push(upload);
                        });

                        Obj.uploads = allUploads;

                        tmpOrgList.push(Obj);
                    });
                } else {
                    if (itemMain.programSurveys !== undefined && itemMain.programSurveys === 0) {
                        let Obj = {};
                        Obj.name = fetchProgramName(itemMain)
                        Obj.id = itemMain.id;
                        Obj.key = itemMain.id + "main5555";
                        Obj.program_status = itemMain.status === 1 ? "Active" : "Archived";
                        Obj.survey_type = "";
                        Obj.survey_id = "";
                        Obj.survey_status = "";
                        Obj.survey_record_id = "";
                        tmpOrgList.push(Obj);
                    }
                }
            });

            setData(tmpOrgList);
        } else if (!isLoading && surveyList.length === 0) {
            setData([]);
        }
    }, [isLoading, surveyList, i18n.language])

    const loadStream = (params = {}) => {
        dispatch({ type: 'SURVEY_LIST_REQUEST', formData: params });
    }

    const fetchProgramName =  (item) => {
        if (i18n.language === 'fr' && item.name_fr && item.name_fr !== '') {
            return item.name_fr;
        } else if (i18n.language === 'en' && item.name_en && item.name_en !== '') {
            return item.name_en;
        } else if (item.name_fr && item.name_fr !== '') {
            return item.name_fr;
        }
        return item.name_en;
    }

    const fetchSurveyName =  (item) => {
        if (!item) {
            return '';
        }
        if (i18n.language === 'fr' && item.name_fr && item.name_fr !== '') {
            return item.name_fr;
        } else if (i18n.language === 'en' && item.name && item.name !== '') {
            return item.name;
        } else if (item.name_fr && item.name_fr !== '') {
            return item.name_fr;
        }
        return item.name;
    }

    const showShareModal = (survey) => {
        setSurvey(survey);
        setIsShareModalVisible(true);
    };

    const handleShareModalClose = () => {
        setSurvey(null);
        setIsShareModalVisible(false);
    };

    const onSearch = (e) => {
        loadStream({ search: e });
    }

    const onDelete = (e) => {
        dispatch({ type: 'SURVEY_DELETE_REQUEST', id: deleteId });
    }

    useEffect(() => {
        if (!isDeleteLoading && deleteStatus === "success") {
            setVisible(false);
            message.success({ content: t('survey.delete_success_msg'), className: 'custom-success-class' });
            dispatch({ type: 'CLEAR_STATUS' });
            dispatch({ type: 'CLEAR_SURVEY' });
            loadStream();
        }
    }, [isDeleteLoading, deleteStatus]);

    const exportSurvey = () => {
        window.open(`${API_URL}programs/export?token=${accessToken()}`)
    }
    return (
        <Wrapper title="survey_id View" >
            <div className='padding-around'>
                <Row className='nav-sub-header'>
                    <Col xs={24} sm={24} md={16} lg={16} xl={16} className="red">
                        <div className='nav-add-btn'>
                            <div>
                                <Button id="add-survey" type="primary" shape="circle" onClick={() => navigate('/survey/add')} icon={<PlusOutlined />} size={"medium"} />
                                <label htmlFor="add-survey" className="nav-add-label">{t('survey.add')}</label>
                            </div>
                            <div className='ml15'>
                                <Button id="export-survey"
                                        className='export-btn'
                                        type="primary"
                                        shape="circle"
                                        onClick={() => exportSurvey()}
                                        icon={<DownloadOutlined />}
                                        size={"medium"} />
                                <label htmlFor="export-survey" className="nav-add-label">{t('common.export')}</label>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="green">
                        <Search allowClear placeholder={t("common.search_text")} onSearch={onSearch} enterButton />
                    </Col>
                </Row>

                <Row className='listing-table mt10'>
                    <Table
                        loading={isLoading}
                        style={{ width: "100%" }}
                        dataSource={data}
                        locale={{
                            triggerDesc: t("common.desc_sorting"),
                            triggerAsc: t("common.asc_sorting"),
                            cancelSort: t("common.cancel_sorting")
                        }}
                    >
                        {(ROLE !== ROLE_NAME.ORG_ADMIN && ROLE !== ROLE_NAME.ORG_USER) &&
                            <Column
                                title={t("survey.organization")}
                                dataIndex='organization_name'
                                key='organization_name'
                            />
                        }
                        <Column
                            title={t("survey.program_name")}
                            dataIndex='name'
                            key='name'
                        />
                        <Column
                            title={t("survey.survey_type")}
                            dataIndex='survey_type'
                            key='survey_type'
                        />
                        <Column
                            title={t("survey.survey_status")}
                            key='survey_status'
                            dataIndex='survey_status'
                            filters={[
                                {
                                    text: t("common.active"),
                                    value: 'Active',
                                },
                                {
                                    text: t("common.archive"),
                                    value: 'Archived',
                                }
                            ]}
                            filterMode='tree'
                            onFilter={(value, record) => record.survey_status === value}
                            render={(_, { survey_status }) => {
                                let color = (survey_status !== 'Active') ? "default" : "success";
                                return (
                                    <Tag color={color} key={survey_status}>
                                        {
                                            survey_status.toLowerCase() === 'active' ? t('common.active') : t('common.archive')
                                        }
                                    </Tag>)
                            }}
                        />
                        <Column
                            title={t("common.status")}
                            key='program_status'
                            dataIndex='program_status'
                            filters={[
                                {
                                    text: t("common.active"),
                                    value: 'Active',
                                },
                                {
                                    text: t("common.archive"),
                                    value: 'Archived',
                                }
                            ]}
                            filterMode='tree'
                            onFilter={(value, record) => record.program_status === value}
                            render={(_, { program_status }) => {
                                let color = (program_status !== 'Active') ? "default" : "success";
                                return (
                                    <Tag color={color} key={program_status}>
                                        {
                                            program_status.toLowerCase() === 'active' ? t('common.active') : t('common.archive')
                                        }
                                    </Tag>)
                            }}
                        />
                        <Column
                            title={t("common.action")}
                            key='action'
                            render={(_, record) => (
                                <Space size="middle">
                                    <Button type="primary" onClick={() => showShareModal(record)} shape="round" size={"default"}>
                                        {t("common.share")}
                                    </Button>
                                    <Tooltip placement="top" title={t("survey.edit_tooltip")}>
                                        <EditFilled className='cursor' onClick={() => navigate(`/survey/edit/${record.id}`)} style={{ fontSize: "18px" }} />
                                    </Tooltip>
                                </Space>
                            )}
                        />
                    </Table>
                    <DeleteModal
                        visible={visible}
                        subTitle={t("survey.delete_confirm")}
                        setVisible={() => { setDeleteId(""); setVisible(false) }}
                        onDelete={() => onDelete()}
                        isDeleteLoading={isDeleteLoading}
                    />
                </Row>
            </div>

            {
                survey &&
                <ShareSurveyModal
                    surveyRecord={survey}
                    langList={langList}
                    visible={isShareModalVisible}
                    onClose={handleShareModalClose}
                />
            }


        </Wrapper>
    )
};

export default Surveys;
