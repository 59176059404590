import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Mfa from './components/auth/login/Mfa';
import { useIdleTimer } from 'react-idle-timer';
import { useTranslation } from 'react-i18next';
import { ConfigProvider, Layout } from 'antd';
import frFR from 'antd/es/locale/fr_FR';
//Public routes without login access
import Login from './components/auth/login';
import ForgetPassword from './components/auth/password/forgetPassword';
import ResetPassword from './components/auth/password/resetPassword';

//Layouts
import Header from './components/pages/layout/header';
import Sidebar from './components/pages/layout/sidebar';
import Breadcrumb from './components/pages/layout/breadcrumb';

//Components
import Dashboard from './components/pages/dashboard';
import Users from './components/pages/user';
import UserAdd from "./components/pages/user/addUser";
import Reports from './components/pages/reports';
import ViewReport from './components/pages/reports/viewReport';
import ReportsManagement from './components/pages/reportManagement';
import ReportAdd from "./components/pages/reportManagement/addReport";
import ReportGroupAdd from "./components/pages/reportManagement/addGroupReport";
import Organization from './components/pages/organization';
import OrganizationAdd from './components/pages/organization/addOrganization';
import Streams from './components/pages/streams';
import StreamAdd from './components/pages/streams/addStream';
import SurveyAdd from "./components/pages/surveys/addSurveys";
import Settings from "./components/pages/settings";
import Surveys from "./components/pages/surveys";
import NotFound from "./components/pages/Error/404";
import Forbidden from "./components/pages/Error/403";
import Version from "./components/pages/comnon/version";

import {isLogin, isAuthorizedByRole, roleList } from './utils/functions';
import "./App.scss";
import SessionExpiryModal from "./components/pages/user/sessionExpiryModal";


const {Content} = Layout;


function App() {
    const {t, i18n} = useTranslation();
    const searchParams = new URLSearchParams(window.location.search);
    useEffect(() => {
        const lang = searchParams.get('lang');
        if (lang) {
            i18n.changeLanguage(lang);
            localStorage.setItem('selectedLanguage', lang);
        }
    }, []);


    const [isModalVisible, setIsModalVisible] = useState(false);

    const onIdle = () => {
        if (isLogin()) {
            setIsModalVisible(true);
        }
        console.log('User is idle');
    }

    const onAction = (event) => {
        console.log(event.type);
    }

    const { getRemainingTime } = useIdleTimer({
        onIdle,
        onAction,
        timeout: 3_300000,
        throttle: 1000,
        events: [
            'mousedown',
            'keydown',
        ]
    });

    const handleContinue = () => {
        setIsModalVisible(false);
    };

    const ProtectedRoute = (props) => {
        if (!isLogin()) {
            return <Navigate to="/login" replace/>;
        } else {
            if (isAuthorizedByRole(props.allowed)) {
                return (
                    <Layout className="main-layout">
                      <Header/>
                      <Layout>
                        <Sidebar/>
                        <Layout
                            style={{
                              padding: '0 24px 24px',
                            }}
                            className="content-layout"
                        >
                          <Breadcrumb/>
                          <Content
                              className="site-layout-background content"
                              style={{
                                padding: "25px 50px",
                                margin: 0,
                                minHeight: 280,
                              }}
                          >
                            {props.children}
                          </Content>
                        </Layout>
                      </Layout>
                      <Version/>
                    </Layout>
                )
            }

            return (
                <Layout>
                    <Header/>
                    <Layout>
                        <Sidebar/>
                        <Layout
                            style={{
                                padding: '0 24px 24px',
                            }}
                        >
                            <Breadcrumb/>
                            <Content
                                className="site-layout-background content"
                                style={{
                                    padding: "25px 50px",
                                    margin: 0,
                                    minHeight: 280,
                                }}
                            >
                                <Forbidden/>
                            </Content>
                        </Layout>
                    </Layout>
                </Layout>
            )

        }

    };

    const PublicRoutes = ({children}) => {
        if (!isLogin()) {
            return <Layout>{children}</Layout>;
        } else {
            return <Navigate to="/" replace/>
        }
    };


    // type 0 = Public
    // type 1 = Private
    const routesList = [
        {
            path: "/login",
            type: 0,
            element: <Login/>,
            role: [],
            name: 'login',
        },
        {
            path: "/mfa",
            type: 0,
            element: <Mfa/>,
            role: [],
            name: 'mfa',
        },
        {
            path: "/forget-password",
            type: 0,
            element: <ForgetPassword/>,
            role: [],
            name: 'forgetPassword',
        },
        {
            path: "/set-password",
            type: 0,
            element: <ResetPassword type="set"/>,
            role: [],
            name: 'setPassword'
        },
        {
            path: "/reset-password",
            type: 0,
            element: <ResetPassword type="reset"/>,
            role: [],
            name: 'resetPassword'
        },
        {
            path: "/",
            type: 1,
            element: <Dashboard/>,
            role: roleList('home'),
            name: 'dashboard'
        },
        {
            path: "/home",
            type: 1,
            element: <Dashboard/>,
            role: roleList('home'),
            name: 'dashboard',
        },
        {
            path: "/user",
            type: 1,
            element: <Users/>,
            role: roleList('user'),
            name: 'users',
        },
        {
            path: "/user/add",
            type: 1,
            element: <UserAdd/>,
            role: roleList('user'),
            name: 'addUser'
        },
        {
            path: "/user/edit/:userId",
            type: 1,
            element: <UserAdd/>,
            role: roleList('user'),
            name: 'editUser'
        },
        {
            path: "/organization",
            type: 1,
            element: <Organization/>,
            role: roleList('organization'),
            name: 'organizations'
        },
        {
            path: "/organization/add",
            type: 1,
            element: <OrganizationAdd/>,
            role: roleList('organization'),
            name: 'addOrganization'
        },
        {
            path: "/organization/edit/:orgId",
            type: 1,
            element: <OrganizationAdd/>,
            role: roleList('organization'),
            name: 'editOrganization'
        },
        {
            path: "/reports",
            type: 1,
            element: <Reports/>,
            role: roleList('reports'),
            name: 'reports'
        },
        {
            path: "/view-report",
            type: 1,
            element: <ViewReport/>,
            role: roleList('reports'),
            name: 'viewReports'
        },
        {
            path: "/reports-management",
            type: 1,
            element: <ReportsManagement/>,
            role: roleList('reports-management'),
            name: 'reportsManagement'
        },
        {
            path: "/reports-management/add",
            type: 1,
            element: <ReportAdd/>,
            role: roleList('reports-management'),
            name: 'addReportManagement'
        },
        {
            path: "/reports-management/add-group",
            type: 1,
            element: <ReportGroupAdd/>,
            role: roleList('reports-management'),
            name: 'addReportManagementGroup'
        },
        {
            path: "/reports-management/edit/:reportId",
            type: 1,
            element: <ReportAdd/>,
            role: roleList('reports-management'),
            name: 'editReportManagement'
        },
        {
            path: "/reports-management/edit-group/:reportId",
            type: 1,
            element: <ReportGroupAdd/>,
            role: roleList('reports-management'),
            name: 'editReportManagementGroup'
        },
        {
            path: "/streams",
            type: 1,
            element: <Streams/>,
            role: roleList('streams'),
            name: 'streams'
        },
        {
            path: "/stream/add",
            type: 1,
            element: <StreamAdd/>,
            role: roleList('streams'),
            name: 'addStream'
        },
        {
            path: "/stream/edit/:streamId",
            type: 1,
            element: <StreamAdd/>,
            role: roleList('streams'),
            name: 'editStream'
        },
        {
            path: "/surveys",
            type: 1,
            element: <Surveys/>,
            role: roleList('surveys'),
            name: 'surveys'
        },
        {
            path: "/survey/add",
            type: 1,
            element: <SurveyAdd/>,
            role: roleList('surveys'),
            name: 'addSurveys'
        },
        {
            path: "/survey/edit/:surveyId",
            type: 1,
            element: <SurveyAdd/>,
            role: roleList('surveys'),
            name: 'editSurvey'
        },
        {
            path: "/settings",
            type: 1,
            element: <Settings/>,
            role: roleList('settings'),
            name: 'settings'
        },
        {
            path: "/unauthorized",
            type: 1,
            element: <Forbidden/>,
            role: [],
            name: 'unAuthorized'
        },
        {
            path: "*",
            type: 1,
            element: <NotFound/>,
            role: [],
            name: 'notFound'
        },
    ]

    return (
        <ConfigProvider locale={i18n.language === 'fr' ? frFR : ''}>
            <BrowserRouter>
                <Routes>
                    {routesList.map((item) => {
                        if (item.type === 0) {
                            return (
                                <Route
                                    path={item.path}
                                    key={item.path}
                                    element={
                                        <PublicRoutes>
                                            {item.element}
                                        </PublicRoutes>
                                    }>
                                </Route>
                            )
                        } else {
                            return (
                                <Route
                                    path={item.path}
                                    key={item.path}
                                    element={
                                        <ProtectedRoute
                                            allowed={item.role}
                                            name={item.name}
                                        >
                                            {item.element}
                                        </ProtectedRoute>
                                    }>
                                </Route>
                            )
                        }
                    })}
                </Routes>
            </BrowserRouter>
            <SessionExpiryModal visible={isModalVisible}
                                onContinue={handleContinue}
            />
        </ConfigProvider>
    );
}

export default (App);
