import { put, takeLatest, call, delay } from 'redux-saga/effects'
import { message } from 'antd';
//Import API Service.
import { deleteRequestAPI, getRequestAPI, postRequestAPI, putRequestAPI, patchRequestAPI } from '../../services/API/api';

function* getSurveyList({ formData }) {
    try {
        const response = yield call(getRequestAPI, { url: `programs`, params: formData });

        if (response.status === 200) {
            yield put({ type: 'SURVEY_LIST_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'SURVEY_LIST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'SURVEY_LIST_ERROR', error: error.message || "" });
    }
}

function* addStream({ formData }) {
    try {
        const response = yield call(postRequestAPI, { url: `program`, data: formData });
        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'SURVEY_ADD_SUCCESS', data: response.data.data || "" });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'SURVEY_ADD_ERROR', error: response });
        }
    } catch (error) {
        yield put({ type: 'SURVEY_ADD_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* updateStream({ id, formData }) {
    try {
        const response = yield call(putRequestAPI, { url: `program/${id}`, data: formData });
        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'SURVEY_UPDATE_SUCCESS', data: response.data.data || "" });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'SURVEY_UPDATE_ERROR', error: response });
        }
    } catch (error) {
        yield put({ type: 'SURVEY_UPDATE_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* getSurveyDetail({ id }) {
    try {
        const response = yield call(getRequestAPI, { url: `program/${id}`, params: {} });
        if (response.status === 200) {
            yield put({ type: 'SURVEY_DETAIL_SUCCESS', data: response?.data?.data || [] });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });

            yield put({ type: 'SURVEY_DETAIL_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'SURVEY_DETAIL_ERROR', error: error.message || "" });
    }
}
function* deleteSurvey({ id }) {
    try {
        const response = yield call(deleteRequestAPI, { url: `program/${id}` });
        if (response.status === 200 || response.status === 204) {
            yield put({ type: 'SURVEY_DELETE_SUCCESS' });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'SURVEY_DELETE_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'SURVEY_DELETE_ERROR', error: error.message || "" });
    }
}
function* updateStatus({ surveyId, status }) {
    try {
        const response = yield call(putRequestAPI, { url: `program/${surveyId}/toggle-status` });
        if (response.status === 200) {
            yield put({ type: 'SURVEY_TOGGLE_SUCCESS', data: response.data.data.status ? 'activated' : 'archived' });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'SURVEY_TOGGLE_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'SURVEY_TOGGLE_ERROR', error: error.message || "" });
    }
}

function* updateSurveyStatus({ surveyId, status }) {
    try {
        const response = yield call(patchRequestAPI, { url: `stream-survey/${surveyId}/toggle-status` });
        if (response.status === 200) {
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
        }
    } catch (error) {
        yield put({ type: 'SURVEY_TOGGLE_ERROR', error: error.message || "" });
    }
}

function* generateQRCode({ formData }) {
    try {
        const response = yield call(postRequestAPI, { url: `generate-qr-code`, data: formData});
        if (response.status === 200) {
            yield put({ type: 'GENERATE_QR_CODE_SUCCESS', data: response?.data?.url || null });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'GENERATE_QR_CODE_ERROR', error: "Something went wrong!" });
        }
    } catch (error) {
        yield put({ type: 'GENERATE_QR_CODE_ERROR', error: error.message || "" });
    }
}


function* watchDonorConfigSagasRequests() {

    yield takeLatest('SURVEY_LIST_REQUEST', getSurveyList);
    yield takeLatest('SURVEY_ADD_REQUEST', addStream);
    yield takeLatest('SURVEY_UPDATE_REQUEST', updateStream);
    yield takeLatest('SURVEY_DETAIL_REQUEST', getSurveyDetail);
    yield takeLatest('SURVEY_DELETE_REQUEST', deleteSurvey);
    yield takeLatest('SURVEY_TOGGLE_REQUEST', updateStatus);
    yield takeLatest('SURVEY_SURVEY_TOGGLE_REQUEST', updateSurveyStatus);
    yield takeLatest('GENERATE_QR_CODE', generateQRCode);
}

function* donorConfigSagas() {
    yield watchDonorConfigSagasRequests();
}

export default donorConfigSagas;