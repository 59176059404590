import { put, takeLatest, call, delay } from 'redux-saga/effects'

//Import API Service.
import { getRequestAPI, postRequestAPI, putRequestAPI, deleteRequestAPI, putRequestAPIV2 } from '../../services/API/api';
import { message } from 'antd';

function* getReportslist({ formData }) {
    try {
        const response = yield call(getRequestAPI, { url: `reports`, params: formData });
        if (response.status === 200) {
            yield put({ type: 'REPORT_LIST_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'REPORTS_LIST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'REPORT_LIST_ERROR', error: error.message || "" });
    }
}


function* getReportRequest({ id }) {
    try {
        const response = yield call(getRequestAPI, { url: `report/${id}`, params: {} });

        if (response.status === 200) {
            yield put({ type: 'REPORT_DETAIL_SUCCESS', data: response.data.data || [] });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'REPORT_DETAIL_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'REPORT_DETAIL_ERROR', error: error.message || "" });
    }
}

function* addReportGroup({ formData }) {
    try {
        const response = yield call(postRequestAPI, { url: `group`, data: formData });
        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'REPORT_GROUP_ADD_SUCCESS', data: response.data.data || "" });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'REPORT_GROUP_ADD_ERROR', error: response });
        }
    } catch (error) {
        yield put({ type: 'REPORT_GROUP_ADD_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* deleteReport({ id, deleteType }) {
    try {
        let response = "";
        if (deleteType === "report") {
            response = yield call(deleteRequestAPI, { url: `report/${id}` });
        } else {
            response = yield call(deleteRequestAPI, { url: `group/${id}` });
        }
        if (response.status === 200 || response.status === 204) {
            yield put({ type: 'REPORT_DELETE_SUCCESS', deleteType: deleteType });
        } else if (response.status === 400) {
            message.error({ content: "Something went wrong!", className: 'error-class' });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'REPORT_DELETE_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'REPORT_DELETE_ERROR', error: error.message || "" });
    }
}

function* addReport({ formData }) {
    try {
        const response = yield call(postRequestAPI, { url: `report`, data: formData });
        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'REPORT_ADD_SUCCESS', data: response.data.data || "" });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'REPORT_ADD_ERROR', error: response });
        }
    } catch (error) {
        yield put({ type: 'REPORT_ADD_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* getReportsGrouplist({ formData }) {
    try {
        const response = yield call(getRequestAPI, { url: `groups`, params: formData });
        if (response.status === 200) {
            yield put({ type: 'REPORT_GROUP_LIST_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'REPORT_GROUP_LIST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'REPORT_GROUP_LIST_ERROR', error: error.message || "" });
    }
}

function* getReportsSearchGrouplist({ formData }) {
    try {
        const response = yield call(getRequestAPI, { url: `groups/search`, params: formData });
        if (response.status === 200) {
            yield put({ type: 'REPORT_GROUP_LIST_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'REPORT_GROUP_LIST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'REPORT_GROUP_LIST_ERROR', error: error.message || "" });
    }
}


function* getReportGroupRequest({ id }) {
    try {
        const response = yield call(getRequestAPI, { url: `group/${id}`, params: {} });

        if (response.status === 200) {
            yield put({ type: 'REPORT_GROUP_DETAIL_SUCCESS', data: response.data.data || [] });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'REPORT_GROUP_DETAIL_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'REPORT_GROUP_DETAIL_ERROR', error: error.message || "" });
    }
}

function* updateReportGroup({ id, formData }) {
    try {
        const response = yield call(putRequestAPI, { url: `group/${id}`, data: formData });
        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'REPORT_GROUP_ADD_SUCCESS', data: response.data.data || "" });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'REPORT_GROUP_ADD_ERROR', error: response });
        }
    } catch (error) {
        yield put({ type: 'REPORT_GROUP_ADD_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* updateReport({ id, formData }) {
    try {
        const response = yield call(putRequestAPI, { url: `report/${id}`, data: formData });
        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'REPORT_ADD_SUCCESS', data: response.data.data || "" });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'REPORT_ADD_ERROR', error: response });
        }
    } catch (error) {
        yield put({ type: 'REPORT_ADD_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* updateStatus({ reportId, toggleType = 'report' }) {
    try {
        let response = "";
        if (toggleType === "report") {
            response = yield call(postRequestAPI, { url: `report/${reportId}/toggle-status` });
        } else {
            response = yield call(postRequestAPI, { url: `group/${reportId}/toggle-status` });
        }
        if (response.status === 200) {
            yield put({ type: 'REPORT_TOGGLE_SUCCESS', data: response.data.data.archive_at === null ? 'activated' : 'archived' });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'REPORT_TOGGLE_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'REPORT_TOGGLE_ERROR', error: error.message || "" });
    }
}

function* getTagsRequest(params = {}) {
    try {
        const response = yield call(getRequestAPI, { url: `tags`, params: {} });

        if (response.status === 200) {
            yield put({ type: 'TAGS_LIST_SUCCESS', data: response.data || [] });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'TAGS_LIST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'TAGS_LIST_ERROR', error: error.message || "" });
    }
}

function* groupExportData({ params }) {
    try {
        const response = yield call(getRequestAPI, { url: `groups/export`, params: params });
        if (response.status === 200) {
            let removedDoubleQuote = response.data;
            removedDoubleQuote = removedDoubleQuote.replace(/['"]+/g, '');
            yield put({ type: 'GROUP_EXPORT_SUCCESS', data: removedDoubleQuote });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'GROUP_EXPORT_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'GROUP_EXPORT_ERROR', error: error.message || "" });
    }
}

function* reportExportData({ params }) {
    try {
        const response = yield call(getRequestAPI, { url: `reports/export`, params: params });
        if (response.status === 200) {
            let removedDoubleQuote = response.data;
            removedDoubleQuote = removedDoubleQuote.replace(/['"]+/g, '');
            yield put({ type: 'REPORT_EXPORT_SUCCESS', data: removedDoubleQuote });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'REPORT_EXPORT_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'REPORT_EXPORT_ERROR', error: error.message || "" });
    }
}
function* getAPIToken() {
    try {
        const response = yield call(getRequestAPI, { url: `auth` });
        if (response.status === 200) {
            yield put({ type: 'GET_API_TOKEN_SUCCESS', data: response.data });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'GET_API_TOKEN_ERROR', error: "token invalid" });
        }
    } catch (error) {
        yield put({ type: 'GET_API_TOKEN_ERROR', error: error.message || "" });
    }
}


function* watchDonorConfigSagasRequests() {
    yield takeLatest('REPORT_GROUP_DETAIL_REQUEST', getReportGroupRequest);
    yield takeLatest('REPORT_GROUP_ADD_REQUEST', addReportGroup);
    yield takeLatest('REPORT_GROUP_UPDATE_REQUEST', updateReportGroup);
    yield takeLatest('REPORT_GROUP_LIST_REQUEST', getReportsGrouplist);
    yield takeLatest('REPORT_GROUP_SEARCH_LIST_REQUEST', getReportsSearchGrouplist);

    yield takeLatest('REPORT_DETAIL_REQUEST', getReportRequest);
    yield takeLatest('REPORT_LIST_REQUEST', getReportslist);
    yield takeLatest('REPORT_ADD_REQUEST', addReport);
    yield takeLatest('REPORT_DELETE_REQUEST', deleteReport);
    yield takeLatest('REPORT_UPDATE_REQUEST', updateReport);
    yield takeLatest('REPORT_TOGGLE_REQUEST', updateStatus);

    yield takeLatest('TAGS_LIST_REQUEST', getTagsRequest);
    yield takeLatest('GROUP_EXPORT_REQUEST', groupExportData);
    yield takeLatest('REPORT_EXPORT_REQUEST', reportExportData);

}

function* donorConfigSagas() {
    yield watchDonorConfigSagasRequests();
}

export default donorConfigSagas;