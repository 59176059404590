import { Layout, Menu, Popover, Modal, Dropdown, Avatar } from 'antd';
// import { UserOutlined } from '@ant-design/icons';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Row, Col, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { logout, role, getFullName, showAvtarName, getUserData, getSetting } from '../../../utils/functions';
const { Header } = Layout;
const { TextArea } = Input;

const Headers = (props) => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { isContactLoading, contactMsg } = useSelector(state => state.user);
    const { settingsData, isSettingLoading } = useSelector(state => state.user);
    const { supportGuideList, isSupportGuideListLoading } = useSelector(state => state.user)

    const [visible, setVisible] = useState(false);
    const [currentLanguage, setCurrentLanguage] = useState('en');
    const [contactVisible, setContactVisible] = useState(false);
    const [supportMenuItems, setSupportMenuItems] = useState([]);

    const onLogoutClick = () => {
        logout();
        setVisible(false);
        navigate('/login');
    }

    useEffect(() => {
        if (!localStorage.getItem('selectedLanguage')) {
            setCurrentLanguage('en');
            localStorage.setItem('selectedLanguage', 'en');
        } else {
            setCurrentLanguage(localStorage.getItem('selectedLanguage'));
        }

        loadSupportGuides();

    }, []);


    useEffect(() => {

        if (!isSupportGuideListLoading) {
            let menuItems = [];
            if (supportGuideList.length > 0) {
                menuItems = supportGuideList.map((guide, index) => ({
                    key: index,
                    label: (
                        <span key={index}  onClick={() => window.open(guide.url, '_blank')} >
                          {guide.name}
                        </span>
                    )
                }));
            }

            menuItems.push({
                key: '3',
                label: (
                    <span onClick={() => setContactVisible(true)}>
                      {t('contact.contact_support')}
                    </span>
                ),
            });

            // Update support menu items state
            setSupportMenuItems(menuItems);

            setSupportMenuItems(menuItems);
        }

    }, [isSupportGuideListLoading]);



    const loadSupportGuides = () => {
        dispatch({type: 'SUPPORT_GUIDE_REQUEST'});
    };

    useEffect(() => {
        if (Object.keys(settingsData).length < 0) {
            if (localStorage.getItem('settings') === null || localStorage.getItem('settings') === undefined) {
                loadSettings();
            }
        }
    }, [settingsData, isSettingLoading]);

    const loadSettings = (params = {}) => {
        dispatch({ type: 'SETTINGS_REQUEST', formData: params });
    }

    useEffect(() => {

        if (Object.keys(settingsData).length < 0) {
            console.log(localStorage.getItem('settings'))
            if (localStorage.getItem('settings') === null || localStorage.getItem('settings') === undefined) {
                loadSettings();
            }
        }
    }, [settingsData, isSettingLoading]);

    const changeLanguage = (lng) => {
        setCurrentLanguage(lng);
        localStorage.setItem('selectedLanguage', lng)
        callForChange(lng);
    }
    const callForChange = (lng) => {
        i18n.changeLanguage(lng);
    }


    const content = (
        <div>
            <p>{getFullName()}</p>
            <p>{t(`user.${role('')}`)}</p>
        </div>
    );

    const menu = (
        <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <span onClick={() => changeLanguage("en")}>
                            English (EN)
                        </span>
                    ),
                },
                {
                    key: '2',
                    label: (
                        <span onClick={() => changeLanguage("fr")}>
                            {t('common.french_lang')}
                        </span>
                    ),
                }
            ]}
        />
    );



    const supportMenu = (
        <Menu items={supportMenuItems} />
    );

    const onFinish = (values) => {
        dispatch({ type: 'CONTACT_US_REQUEST', formData: values });
    }

    const onFinishFailed = (values) => { };

    useEffect(() => {
        if (!isContactLoading && contactMsg === "success") {
            dispatch({ type: "CLEAR_STATUS" });
            setContactVisible(false);
            message.success({ content: t("contact.support_success"), className: 'custom-success-class' });
        }
    }, [isContactLoading, contactMsg])

    return (
        <>
            <Header className="header">
                {getSetting('logo') !== "" &&
                    < img src={getSetting('logo')} alt="CRG" className='ottawa-logo'></img>
                }
                {getSetting('logo') === "" &&
                    <div className="logo" ></div>
                }
                <div className='right d-flex'>
                    <Dropdown overlay={supportMenu} trigger="hover" placement="bottom">
                        <span className='header-menu'>{t("common.support")}</span>
                    </Dropdown>
                    <Dropdown overlay={menu} trigger="hover" placement="bottom">
                        <span className='header-menu'>{currentLanguage}</span>
                    </Dropdown>
                    <span className='header-menu' onClick={() => setVisible(true)}>{t("common.logout")}</span>
                    <span className='header-menu avtar-menu'>
                        <Popover placement="bottomRight" content={content} overlayClassName="action-dropdown" trigger="hover">
                            <Avatar size="large"  >{showAvtarName()}</Avatar>
                        </Popover>
                    </span>

                </div>
            </Header>
            {
                visible &&
                <Modal
                    title={t("common.logout")}
                    visible={visible}
                    onOk={() => onLogoutClick()}
                    onCancel={() => setVisible(false)}
                    okText={t("common.logout")}
                    cancelText={t("common.cancel")}
                    className="logout-modal"
                >
                    <p>{t("login.confirm_logout")}</p>
                </Modal>
            }
            {
                contactVisible &&
                <Modal
                    title={t("contact.title")}
                    visible={contactVisible}
                    onCancel={() => setContactVisible(false)}
                    footer={null}
                    className="input-class"
                >
                    <Form
                        className='sign-form'
                        name='basic'
                        onFinish={onFinish}
                        layout="vertical"
                        form={form}
                        onFinishFailed={onFinishFailed}>
                        <Row gutter={20} >
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                <Form.Item
                                    label={t('contact.name')}
                                    className='form-field'
                                >
                                    <Input readOnly disabled defaultValue={getFullName()} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                <Form.Item label={t('contact.email')} className='form-field' >
                                    <Input readOnly disabled defaultValue={getUserData().email !== undefined ? getUserData().email : ""} />
                                </Form.Item>
                            </Col>


                            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="red">
                                <Form.Item
                                    label={t("contact.message")}
                                    name='message'
                                    className='form-field'
                                    rules={[
                                        {
                                            required: true,
                                            message: t("contact.error_message"),
                                        },
                                        {
                                            max: 1000,
                                            message: t("contact.error_message_max"),
                                        }
                                    ]}
                                >
                                    <TextArea
                                        autoSize={{ minRows: 5 }}
                                        placeholder={t("contact.placeholder_message")} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20} className={"mt10"}>
                            <Col xs={{ span: 24, offset: 3 }} md={{ span: 24, offset: 6 }} lg={{ span: 24, offset: 6 }} >
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    shape="round"
                                    loading={isContactLoading}
                                    disabled={isContactLoading}
                                    size={"large"}>
                                    {t('common.submit')}
                                </Button>
                                <Button type="secondary" onClick={() => {
                                    dispatch({ type: "CLEAR_STATUS" });
                                    setContactVisible(false)
                                }} className={"ml5"} shape="round" size={"large"}>
                                    {t('common.cancel')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            }
        </>
    );
};

export default Headers;
