import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Row, Col, message, Select, Card, Switch, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Wrapper from '../comnon/wrapper';
import useTitle from '../comnon/title';
import DeleteModal from '../comnon/modal';
import { ROLE_NAME } from '../../../utils/constant';
import { getOrganizationName, role } from '../../../utils/functions';
const { Option } = Select;
const AddUser = () => {

    const { userId } = useParams();
    const { t } = useTranslation();
    useTitle(`${!userId ? t('user.add_title') : t('user.edit_title')}`);

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const { isRoleLoading, roleList, isAddLoading, isGetLoading, userData, addStatus, isToggleLoading, deleteStatus, userStatus, isDeleteLoading } = useSelector(state => state.user);
    const [isSwitchLoading, setIsSwitchLoading] = useState(false);
    const [checked, setChecked] = useState(false);
    const [visible, setVisible] = useState(false);
    const [deleteId, setDeleteId] = useState("");
    const [currentRole, setCurrentRole] = useState("");
    const [redirect, setRedirect] = useState("");
    const { isLoading, organizationList } = useSelector(state => state.organization);

    useEffect(() => {
        if (searchParams.get('tab') !== null) {
            setRedirect(`?tab=${searchParams.get('tab')}`)
        }
    }, [searchParams])

    const onFinish = (values) => {
        if (userId) {
            delete values["email"];
            if (values["phone"] === "") {
                values.phone = null;
            }
            dispatch({ type: 'USER_UPDATE_REQUEST', id: userId, formData: values });
        } else {
            if (values["phone"] === "") {
                delete values["phone"];
            }
            dispatch({ type: 'USER_ADD_REQUEST', formData: values });
        }
    }

    useEffect(() => {
        loadRoles();
    }, []);

    useEffect(() => {
        loadOrgnization();
    }, []);

    useEffect(() => {
        if (!isAddLoading && addStatus === "success") {
            dispatch({ type: 'CLEAR_USER_STATUS' });
            if (userId) {
                message.success({ content: t('user.update_success_msg'), className: 'custom-success-class' });
            } else {
                message.success({ content: t('user.add_success_msg'), className: 'custom-success-class' });
            }
            navigate(`/user${redirect}`);
        }
    }, [isAddLoading, addStatus]);

    useEffect(() => {
        if (userId) {
            dispatch({ type: 'USER_DETAIL_REQUEST', id: userId });
        }
    }, []);

    useEffect(() => {
        if (userId && !isGetLoading && Object.keys(userData).length > 0) {
            setIsSwitchLoading(true);
            setChecked(userData.archived_at === null ? true : false);
            form.setFieldsValue({
                first_name: userData.first_name,
                last_name: userData.last_name,
                email: userData.email,
                phone: userData.phone

            });
            setDeleteId(userId);
            setTimeout(() => {
                setIsSwitchLoading(false);
            }, 200);
        }
    }, [isGetLoading, userData]);

    useEffect(() => {
        if (userId && !isLoading && organizationList.length > 0 && Object.keys(userData).length > 0 && userData.organization !== null) {
            let tempOrg = organizationList.filter(item => item.id === userData.organization.id);
            if (tempOrg.length > 0) {
                form.setFieldsValue({
                    organization_id: tempOrg[0].id
                });
            }
        }
    }, [isLoading, organizationList, userData]);

    useEffect(() => {
        if (userId && !isRoleLoading && roleList.length > 0 && Object.keys(userData).length > 0) {
            let tempRole = roleList.filter(item => item.id === userData.role.id);
            if (tempRole.length > 0) {
                setCurrentRole(tempRole[0].name)
                form.setFieldsValue({
                    role_id: tempRole[0].id
                });
            }
        }
    }, [isRoleLoading, roleList, userData])

    useEffect(() => {
        if (!isToggleLoading && userStatus !== "") {
            dispatch({ type: 'CLEAR_STATUS' });
            if (userStatus === "activated") {
                // message.success({ content: `User ${userStatus} successfully.`, className: 'custom-success-class' });
                message.success({ content: t("user.active_success_msg"), className: 'custom-success-class' });
            } else {
                message.warn({ content: t("user.archived_success_msg"), className: 'custom-success-class' });
            }
        }
    }, [isToggleLoading, userStatus]);

    const loadRoles = (params = {}) => {
        dispatch({ type: 'USER_ROLE_LIST_REQUEST', formData: params });
    }

    const loadOrgnization = () => {
        let params = { status: "active" };
        if ([ROLE_NAME.ORG_ADMIN, ROLE_NAME.ORG_USER].includes(role())) {
            params.q = getOrganizationName();
        }
        dispatch({ type: 'ORGANIZATION_LIST_REQUEST', formData: params });
    }


    const onFinishFailed = () => { };
    const onChange = (value) => { };
    const onChangeRole = (e) => {
        setCurrentRole(getRoleName(e, roleList))
    };

    const getRoleName = (id, tmpRoleList = []) => {
        let currentroleName = tmpRoleList.filter(item => item.id === id);
        return currentroleName[0].name;
    }

    const onSearch = (value) => { };

    const onSwitchChange = (e) => {
        dispatch({ type: "USER_TOGGLE_REQUEST", userId: userId, status: e })
    }

    useEffect(() => {
        if (!isDeleteLoading && deleteStatus === "success") {
            setVisible(false);
            setDeleteId('');
            navigate(`/user${redirect}`)
        }
    }, [isDeleteLoading, deleteStatus]);

    const onDelete = (e) => {
        dispatch({ type: 'USER_DELETE_REQUEST', id: e });
    }

    return (
        <Wrapper title={`User ${userId ? "Update" : "Add"}`} >
            <div className='padding-around input-class'>
                {isGetLoading && <Spin style={{ width: "100%" }} />}
                {!isGetLoading &&
                    <>
                        {userId &&
                            <Card className='archive-card'>
                                <div className='content'>
                                    <p>{t("user.status_msg")}</p>
                                    <div className='switch-delete'>
                                        <p>
                                            {isSwitchLoading && <Switch
                                                checkedChildren={<span>{t('common.active')}</span>}
                                                unCheckedChildren={<span>{t('common.archived')}</span>}
                                                defaultChecked={checked}
                                                size='large'
                                                loading={isSwitchLoading}
                                            />}
                                            {!isSwitchLoading && <p>
                                                <Switch
                                                    checkedChildren={<span>{t('common.active')}</span>}
                                                    unCheckedChildren={<span>{t('common.archived')}</span>}
                                                    defaultChecked={checked}
                                                    onChange={(e) => onSwitchChange(e)}
                                                    size='large'
                                                    loading={isSwitchLoading}
                                                />
                                            </p>}
                                        </p>
                                        <Button
                                            htmlType="submit"
                                            type="primary"
                                            shape="round"
                                            danger
                                            onClick={() => { setVisible(true) }}
                                            className='ml2'
                                            size={"medium"}
                                        >
                                            {t('common.delete')}
                                        </Button>
                                    </div>
                                </div>
                            </Card>
                        }
                        <Form
                            className='sign-form'
                            name='basic'
                            onFinish={onFinish}
                            layout="vertical"
                            form={form}
                            autoComplete="off"
                            onFinishFailed={onFinishFailed}>

                            <Row gutter={70} >
                                <input type="hidden" value="something" />
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                    <Form.Item
                                        label={t('common.first_name')}
                                        name='first_name'
                                        className='form-field'
                                        rules={[
                                            {
                                                required: true,
                                                message: t('user.error_first_name'),
                                            }
                                        ]}
                                    >
                                        <Input placeholder={t('common.first_name')} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                    <Form.Item
                                        label={t('common.last_name')}
                                        name='last_name'
                                        className='form-field'
                                        rules={[
                                            {
                                                required: true,
                                                message: t('user.error_last_name'),
                                            }
                                        ]}
                                    >
                                        <Input placeholder={t('common.last_name')} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={70} >
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                    <Form.Item
                                        label={t('common.email')}
                                        name='email'
                                        className='form-field'
                                        rules={[

                                            {
                                                type: 'email',
                                                message: t('user.error_email_valid'),
                                            },
                                            {
                                                required: true,
                                                message: t('user.error_email'),
                                            }
                                        ]}
                                    >
                                        <Input
                                            readOnly={userId ? true : false}
                                            disabled={userId ? true : false}
                                            placeholder={t('common.email')} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                    <Form.Item
                                        label={t('common.phone')}
                                        name='phone'
                                        className='form-field'
                                        rules={[
                                            {
                                                required: false,
                                                message: t('user.error_phone')
                                            }
                                        ]}
                                    >
                                        <Input type={"number"} placeholder={t('common.phone')} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={70} >
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                    <Form.Item
                                        label={t("common.role")}
                                        name='role_id'
                                        className='form-field'
                                        rules={[
                                            {
                                                required: true,
                                                message: t("user.error_role")
                                            }
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder={t("user.select_role")}
                                            onChange={onChangeRole}
                                            onSearch={onSearch}
                                            loading={isRoleLoading}
                                            autoComplete="none"
                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        >
                                            {!isRoleLoading && roleList.length > 0 && roleList.map((item) =>
                                                <Option key={item.id} value={item.id} id={item.name}>{t(`user.${item.name}`)}</Option>
                                            )}
                                        </Select>

                                    </Form.Item>
                                </Col>
                                {(currentRole === ROLE_NAME.ORG_ADMIN || currentRole === ROLE_NAME.ORG_USER) &&
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                        <Form.Item
                                            label={t('organization.title')}
                                            name='organization_id'
                                            className='form-field'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('user.error_organization'),
                                                }
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                placeholder={t('user.select_organization')}
                                                optionFilterProp="children"
                                                onChange={onChange}
                                                onSearch={onSearch}
                                                loading={isLoading}
                                                // defaultValue={orgName}
                                                autoComplete="none"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            >
                                                {!isLoading && organizationList.length > 0 && organizationList.map((item) =>
                                                    <Option key={item.id} value={item.id}>{item.name}</Option>
                                                )}
                                            </Select>

                                        </Form.Item>
                                    </Col>
                                }

                            </Row>
                            {userId &&
                                <Row gutter={70} >
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                        <Form.Item
                                            label={t("common.password")}
                                            name="password"
                                            className='form-field field-password'
                                            rules={[
                                                {
                                                    required: false,
                                                    message: t("user.error_password"),
                                                },
                                                {
                                                    min: 8,
                                                    message: t("user.error_password_max"),
                                                },
                                            ]}
                                            hasFeedback
                                            autocomplete="off"
                                        >
                                            <Input.Password autoComplete="new-password" placeholder={t("common.password")} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                        <Form.Item
                                            label={t("common.confirm_password")}
                                            name="password_confirmation"
                                            className='form-field field-password'
                                            dependencies={['password']}
                                            hasFeedback
                                            rules={[
                                                {
                                                    required: false,
                                                    message: t("user.error_password_confirm")
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        const isPasswordRequired = getFieldValue('password') !== undefined
                                                            && getFieldValue('password') !== null
                                                            && getFieldValue('password') !== '';

                                                        if (isPasswordRequired) {
                                                            return value ? Promise.resolve() : Promise.reject(new Error(t("user.error_password_confirm")));
                                                        }

                                                        return Promise.resolve();
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input.Password placeholder={t("common.confirm_password")} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            }
                            <Row gutter={70} className={"mt10"}>
                                <Col xs={{ span: 24, offset: 3 }} md={{ span: 24, offset: 6 }} lg={{ span: 12, offset: 9 }} >
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        shape="round"
                                        loading={isAddLoading}
                                        disabled={isAddLoading}
                                        size={"large"}>
                                        {userId ? t('common.update') : t('common.confirm')}
                                    </Button>
                                    <Button type="secondary" onClick={() => {
                                        dispatch({ type: "CLEAR_STATUS" });
                                        navigate(`/user${redirect}`)
                                    }} className={"ml5"} shape="round" size={"large"}>
                                        {t('common.cancel')}
                                    </Button>
                                </Col>
                            </Row>

                        </Form>
                        <DeleteModal
                            visible={visible}
                            deleteId={deleteId}
                            subTitle={t("user.delete_confirm")}
                            setVisible={() => { setVisible(false) }}
                            onDelete={(e) => onDelete(e)}
                            isDeleteLoading={isDeleteLoading}
                        />
                    </>}
            </div>
        </Wrapper>
    )
};

export default AddUser;                    