const initialState = {
    isLoading: false,
    organizationList: [],

    isAddLoading: false,
    isAddedMsg: "",

    isGetLoading: false,
    organizationData: {},

    isDeleteLoading: false,
    deleteStatus: "",

    isToggleLoading: false,
    orgStatus: "",

    isStepLoading: false,
    stepData: {},
    step2Data: [],
    step4Data: [],
    step5Data: {},

    isStepUpdating: false,
    updateStepMsg: "",

    isDropLoading: false,
    dropData: {},
    redirectTo: "",
    isOrgExportLoading: false,
    orgExportData: "",

    isImporting: false,
    importSuccessMsg: '',
    importErrors: [],

    error: ""

};

const organizationReducer = (state = initialState, action = {}) => {

    switch (action.type) {
        case "ORGANIZATION_LIST_REQUEST":
            state = {
                ...state,
                isLoading: true,
                organizationList: []

            };
            break;

        case "ORGANIZATION_LIST_SUCCESS":
            state = {
                ...state,
                isLoading: false,
                organizationList: action.data
            };
            break;

        case "ORGANIZATION_LIST_ERROR":
            state = {
                ...state,
                isLoading: false,
                organizationList: [],
                error: action.error,
            };
            break;

        /******************************/
        case "ORGANIZATION_ADD_REQUEST":
            state = {
                ...state,
                isAddLoading: true,
                isAddedMsg: ''

            };
            break;

        case "ORGANIZATION_ADD_SUCCESS":
            state = {
                ...state,
                isAddLoading: false,
                isAddedMsg: "success"
            };
            break;

        case "ORGANIZATION_ADD_ERROR":
            state = {
                ...state,
                isAddLoading: false,
                error: action.error,
                isAddedMsg: ""
            };
            break;

        /******************************/
        case "ORGANIZATION_UPDATE_REQUEST":
            state = {
                ...state,
                isAddLoading: true,
                isAddedMsg: ''

            };
            break;

        case "ORGANIZATION_UPDATE_SUCCESS":
            state = {
                ...state,
                isAddLoading: false,
                isAddedMsg: "success"
            };
            break;

        case "ORGANIZATION_UPDATE_ERROR":
            state = {
                ...state,
                isAddLoading: false,
                error: action.error,
                isAddedMsg: ""
            };
            break;

        /******************************/
        case "ORGANIZATION_DETAIL_REQUEST":
            state = {
                ...state,
                isGetLoading: true,
                organizationData: {}

            };
            break;

        case "ORGANIZATION_DETAIL_SUCCESS":
            state = {
                ...state,
                isGetLoading: false,
                organizationData: action.data
            };
            break;

        case "ORGANIZATION_DETAIL_ERROR":
            state = {
                ...state,
                isGetLoading: false,
                organizationData: {},
                error: action.error,
            };
            break;

        /******************************/
        case "ORGANIZATION_DELETE_REQUEST":
            state = {
                ...state,
                isDeleteLoading: true,
                deleteStatus: ""

            };
            break;

        case "ORGANIZATION_DELETE_SUCCESS":
            state = {
                ...state,
                isDeleteLoading: false,
                deleteStatus: "success"
            };
            break;

        case "ORGANIZATION_DELETE_ERROR":
            state = {
                ...state,
                isDeleteLoading: false,
                deleteStatus: ""
            };
            break;

        /******************************/
        case "ORGANIZATION_TOGGLE_REQUEST":
            state = {
                ...state,
                isToggleLoading: true,
                orgStatus: ""

            };
            break;

        case "ORGANIZATION_TOGGLE_SUCCESS":
            state = {
                ...state,
                isToggleLoading: false,
                orgStatus: action.data
            };
            break;

        case "ORGANIZATION_TOGGLE_ERROR":
            state = {
                ...state,
                isToggleLoading: false,
                orgStatus: ""
            };
            break;

        /******************************/
        case "ORGANIZATION_STEP_REQUEST":
            state = {
                ...state,
                isStepLoading: true,
                stepData: {},
                step2Data: [],
                step4Data: [],
                step5Data: {},

            };
            break;

        case "ORGANIZATION_STEP_SUCCESS":
            state = {
                ...state,
                isStepLoading: false,
                stepData: action?.data || {},
                step2Data: action?.step2,
                step4Data: action?.step4,
                step5Data: action?.step5,
            };
            break;

        case "ORGANIZATION_STEP_ERROR":
            state = {
                ...state,
                isStepLoading: false,
                stepData: {},
                step2Data: [],
                step4Data: [],
                step5Data: {}
            };
            break;

        /******************************/
        case "ORGANIZATION_STEP_POST_REQUEST":
            state = {
                ...state,
                isStepUpdating: true,
                updateStepMsg: "",
                redirectTo: ""

            };
            break;

        case "ORGANIZATION_STEP_POST_SUCCESS":
            state = {
                ...state,
                isStepUpdating: false,
                updateStepMsg: "success",
                redirectTo: action.data
            };
            break;

        case "ORGANIZATION_STEP_POST_ERROR":
            state = {
                ...state,
                isStepUpdating: false,
                updateStepMsg: action.error,
                redirectTo: ""
            };
            break;

        /******************************/
        case "ORGANIZATION_FORM_SPECIFIC_REQUEST":
            state = {
                ...state,
                isDropLoading: true,
                dropData: {}

            };
            break;

        case "ORGANIZATION_FORM_SPECIFIC_SUCCESS":
            state = {
                ...state,
                isDropLoading: false,
                dropData: action.data
            };
            break;

        case "ORGANIZATION_FORM_SPECIFIC_ERROR":
            state = {
                ...state,
                isDropLoading: false,
                dropData: {}
            };
            break;

        case "ORGANIZATION_EXPORT_REQUEST":
            state = {
                ...state,
                isOrgExportLoading: true,
                orgExportData: ""

            };
            break;

        case "ORGANIZATION_EXPORT_SUCCESS":
            state = {
                ...state,
                isOrgExportLoading: false,
                orgExportData: action.data
            };
            break;

        case "ORGANIZATION_EXPORT_ERROR":
            state = {
                ...state,
                isOrgExportLoading: false,
                orgExportData: ""
            };
            break;

        case "ORGANIZATION_IMPORT_REQUEST":
            state = {
                ...state,
                isImporting: true,
                importSuccessMsg: ""

            };
            break;

        case "ORGANIZATION_IMPORT_SUCCESS":
            state = {
                ...state,
                isImporting: false,
                importSuccessMsg: action.data
            };
            break;

        case "ORGANIZATION_IMPORT_ERROR":
            state = {
                ...state,
                isImporting: false,
                importSuccessMsg: 'error',
                importErrors: action.errorData
            };
            break;

        case "ORG_STEP_CLEAR":
            state = {
                ...state,
                isStepUpdating: false,
                dropData: "",
                updateStepMsg: "",
                redirectTo: "",
                orgExportData: "",
                isImporting: false,
                importSuccessMsg: '',
                importErrors: []
            };
            break;


        case "CLEAR_STATUS":
            state = {
                ...state,
                isAddedMsg: "",
                deleteStatus: "",
                organizationData: {},
                orgStatus: "",
            };
            break;

        default:

    }
    return state;
};

export default organizationReducer;
