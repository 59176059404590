import { put, takeLatest, call } from 'redux-saga/effects'
import { message } from 'antd';

import { postRequestAPI } from '../../services/API/api';

function* setPassword({ formData, pageType }) {
    try {
        let response = "";
        if (pageType === "set") {
            response = yield call(postRequestAPI, { url: `set-password`, data: formData });
        } else {
            response = yield call(postRequestAPI, { url: `reset-password`, data: formData });
        }
        if (response.status === 200) {
            yield put({ type: 'SET_PASSWORD_SUCCESS', data: response.data.data || "" });
        } else {
            yield put({ type: 'SET_PASSWORD_ERROR', error: response });
        }
    } catch (error) {
        console.log(formData, pageType);
        yield put({ type: 'SET_PASSWORD_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* resendRequest({ formData }) {
    try {
        const response = yield call(postRequestAPI, { url: `resend`, data: formData });
        if (response.status === 200) {
            yield put({ type: 'RESEND_CODE_SUCCESS', data: response.data.data || "" });
        } else {
            yield put({ type: 'RESEND_CODE_ERROR', error: response });
        }
    } catch (error) {
        yield put({ type: 'RESEND_CODE_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* loginRequest({ formData }) {
    try {
        const response = yield call(postRequestAPI, { url: `login`, data: formData });
        if (response.status === 200) {
            yield put({ type: 'LOGIN_SUCCESS'});
        } else {
            yield put({ type: 'LOGIN_ERROR', error: 'error', statusCode: response.status });
        }
    } catch (error) {
        message.error('Email address or Password is invalid');
        yield put({ type: 'LOGIN_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* mfaVerifyRequest({ formData }) {
    try {
        const response = yield call(postRequestAPI, { url: `mfa/verify`, data: formData });
        if (response.status === 200) {
            localStorage.setItem('loggedInUser', JSON.stringify(response.data.user))
            localStorage.setItem('jwt', response.data.token)
            yield put({ type: 'MFA_SUCCESS', data: response.data.user || ""});
        } else {
            message.error(response?.message);
            yield put({ type: 'MFA_ERROR', error: 'error' });
        }
    } catch (error) {
        message.error('Something went wrong');
        yield put({ type: 'MFA_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* mfaResendRequest({ formData }) {
    try {
        const response = yield call(postRequestAPI, { url: `mfa/resend`, data: formData });
        if (response.status === 200) {
            yield put({ type: 'MFA_REQUEST_RESEND_SUCCESS'});
        } else {
            message.error(response?.message);
            yield put({ type: 'MFA_REQUEST_RESEND_ERROR', error: 'error' });
        }
    } catch (error) {
        message.error('Something went wrong');
        yield put({ type: 'MFA_REQUEST_RESEND_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* forgetPassword({ formData }) {
    try {
        const response = yield call(postRequestAPI, { url: `forgot-password`, data: formData });
        if (response.status === 200) {
            yield put({ type: 'FORGET_PASSWORD_SUCCESS', data: response.data.data || "" });
        } else {
            yield put({ type: 'FORGET_PASSWORD_ERROR', error: response });
        }
    } catch (error) {
        yield put({ type: 'FORGET_PASSWORD_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* refreshToken() {
    try {
        const response = yield call(postRequestAPI, { url: `refresh-token` });
        if (response.status === 200) {
            yield put({ type: 'REFRESH_SESSION_SUCCESS', data: response.data.data || "" });
        } else {
            yield put({ type: 'REFRESH_SESSION_ERROR', error: response });
        }
    } catch (error) {
        yield put({ type: 'REFRESH_SESSION_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* watchDonorConfigSagasRequests() {
    yield takeLatest('LOGIN_REQUEST', loginRequest);
    yield takeLatest('MFA_REQUEST', mfaVerifyRequest);
    yield takeLatest('MFA_REQUEST_RESEND', mfaResendRequest);
    yield takeLatest('FORGET_PASSWORD_REQUEST', forgetPassword);


    yield takeLatest('SET_PASSWORD_REQUEST', setPassword);
    yield takeLatest('RESEND_CODE_REQUEST', resendRequest);
    yield takeLatest('REFRESH_SESSION_REQUEST', refreshToken);
}

function* donorConfigSagas() {
    yield watchDonorConfigSagasRequests();
}

export default donorConfigSagas;