import React, { useState, useEffect } from 'react';
import { Table, Button, Upload, Input, Space, Popconfirm, message } from 'antd';
import { PlusCircleOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';

const { Column } = Table;

const UserSupportGuide = () => {
    const [fileList, setFileList] = useState([]);

    const dispatch = useDispatch();

    const {
        supportGuideList,
        supportGuideUploadLoading,
        supportGuideUploadStatus,
        supportGuideDeleteLoading,
        supportGuideDeleteStatus
    } = useSelector(state => state.user);


    const fetchSupportGuides = () => {
        dispatch({ type: 'SUPPORT_GUIDE_REQUEST'});
    };

    const handleUpload = (info) => {
        let files = [...info.fileList];
        files = files.map(file => ({
            ...file,
            id: null,
            name: file.name,
            status: 'uploading',
            url: null,
            editing: true,
        }));
        setFileList([...fileList, ...files]);
    };

    const handleSaveName = (index) => {
        let formData = new FormData();
        const file = fileList[index];

        formData.append('file', file.originFileObj);
        formData.append('name', file.name); // Append the new file name

        dispatch({ type: "UPDATE_SETTINGS_SUPPORT_GUIDE_REQUEST", formData: formData });
    };

    const handleCancelEdit = (index) => {
        const updatedFileList = fileList.filter((item, i) => i !== index);
        setFileList(updatedFileList);
    };

    const handleViewFileURL = (url) => {
        window.open(url, '_blank');
    };

    const handleDelete = (supportGuideId) => {
        dispatch({ type: 'DELETE_SETTINGS_SUPPORT_GUIDE_REQUEST', id: supportGuideId});
    };

    useEffect(() => {
        fetchSupportGuides();
    }, []);

    useEffect(() => {
        if (!supportGuideUploadLoading && supportGuideUploadStatus === 'success') {
            message.success('Support guide uploaded successfully');
            setFileList([]);
            fetchSupportGuides();
        }
    }, [supportGuideUploadLoading, supportGuideUploadStatus]);

    useEffect(() => {
        if (!supportGuideDeleteLoading && supportGuideDeleteStatus === 'success') {
            message.success('Support guide deleted successfully');
            setFileList([]);
            fetchSupportGuides();
        }
    }, [supportGuideDeleteLoading, supportGuideDeleteStatus]);


    // Merge uploaded files with support guide list
    const mergedList = [...fileList, ...supportGuideList];

    const columns = [
        {
            title: 'File Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record, index) => {
                return (
                    <div>
                        {record.editing ? (
                            <Input
                                value={text}
                                onChange={(e) => {
                                    const updatedFileList = [...fileList];
                                    updatedFileList[index].name = e.target.value;
                                    setFileList(updatedFileList);
                                }}
                                style={{ width: 400 }}
                            />
                        ) : (
                            text
                        )}
                    </div>
                );
            },
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record, index) => (
                <Space>
                    {record.status === 'uploading' ? (
                        <>
                            <Button
                                onClick={() => handleSaveName(index)}
                                loading={supportGuideUploadLoading}
                                type="primary"
                            >
                                Save
                            </Button>
                            <Button
                                onClick={() => handleCancelEdit(index)}
                            >
                                Cancel
                            </Button>
                        </>
                    ) : (
                        <>
                            <EyeOutlined onClick={() => handleViewFileURL(record.url)} />
                            <Popconfirm
                                title="Are you sure to delete this file?"
                                onConfirm={() => handleDelete(record.id)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <DeleteOutlined />
                            </Popconfirm>
                        </>
                    )}
                </Space>
            ),
        },
    ];

    return (
        <>
            <div style={{ float: 'right' }}>
                <Upload
                    onChange={handleUpload}
                    accept={'.pdf'}
                    fileList={[]}
                    showUploadList={false}
                    multiple
                >
                    <Button icon={<PlusCircleOutlined style={{ color: '#1890ff' }} />}
                            type="text">Upload Files
                    </Button>
                </Upload>
            </div>

            <Table
                dataSource={mergedList}
                columns={columns}
                rowKey={(record, index) => index}
                pagination={false}
            />
        </>
    );
};

export default UserSupportGuide;
