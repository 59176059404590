import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Row, Card, Switch, Col, InputNumber, Spin, message, Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SurveySupportGuide from './surveySupportGuide';
import Wrapper from '../comnon/wrapper';
import DeleteModal from '../comnon/modal';
import useTitle from '../comnon/title';
const { TextArea } = Input;
const { Option } = Select;

let tmpSurveysList = [];
let tmpSingleObject = { 'id': 500000, 'survey_id': '', 'name': '', 'description_en': '', 'description_fr': '', 'status': 1, 'uploads': []};
let surveyFiles = {};
const AddStream = () => {
    const { streamId } = useParams();
    const { t, i18n } = useTranslation();
    useTitle(!streamId ? t("stream.add_title") : t("stream.edit_title"));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        isGetLoading, isToggleLoading,
        streamData, isDeleteLoading, deleteStatus,
        streamStatus, isAddLoading,
        addStatus, isSurveyToggle, surveyToggleStatus
    } = useSelector(state => state.stream);

    const  { langList } = useSelector(state => state.stream);

    const [form] = Form.useForm();


    const [noOfSurvey, setNoOfSurvey] = useState(0);
    const [surveyHtml, setSurveyHtml] = useState("");
    const [isSwitchLoading, setIsSwitchLoading] = useState(false);
    const [checked, setChecked] = useState(false);
    const [visible, setVisible] = useState(false);
    const [deleteId, setDeleteId] = useState("");
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        if (streamId) {
            dispatch({ type: 'STREAM_DETAIL_REQUEST', id: streamId });
        } else {
            setNoOfSurvey(1);
            form.setFieldsValue({ 'no_of_survay': 1 });
        }

        dispatch({type: 'FETCH_LANG_LIST'});

    }, []);


    useEffect(() => {
        if (streamId && !isGetLoading && Object.keys(streamData).length > 0) {
            setDeleteId(streamId);
            form.setFieldsValue({
                name_en: streamData.name_en,
                name_fr: streamData.name_fr,
                description_en: streamData.description_en,
                description_fr: streamData.description_fr,
                languages: streamData.languages
            });
            setIsSwitchLoading(true);
            setChecked(streamData.status === 1 ? true : false);

            form.setFieldsValue({ no_of_survay: streamData.surveys.length });
            tmpSurveysList = streamData.surveys;
            setNoOfSurvey(streamData.surveys.length);

            setTimeout(() => {
                setIsSwitchLoading(false);
            }, 100);
        }
    }, [isGetLoading, streamData]);

    useEffect(() => {
        if (tmpSurveysList.length !== 0 || streamId) {
            setTimeout(() => {
                loadSurveyHtml(tmpSurveysList);
            }, 200);
        } else {
            loadSurveyHtml([tmpSingleObject]);
        }

    }, [noOfSurvey, i18n.language]);


    useEffect(() => {
        if (!isSurveyToggle && surveyToggleStatus === "success") {
            dispatch({ type: "CLEAR_STREAM" });
            dispatch({ type: 'STREAM_DETAIL_REQUEST', id: streamId });
            dispatch({ type: "CLEAR_STREAM_IMAGE" });
            loadSurveyHtml(tmpSurveysList);
        }
    }, [isSurveyToggle, surveyToggleStatus])

    const onFinish = (values) => {
        let Obj = {
            "name_en": values.name_en || "",
            "name_fr": values.name_fr || "",
            "description_en": values.description_en || "",
            "description_fr": values.description_fr || "",
            "languages": values.languages
        }
        let tmpSurveys = []
        if (values?.surveys && values.surveys.length > 0) {
            values.surveys.map((item, index) => {
                let tmpObj = { ...item };
                tmpObj.surveyFiles = surveyFiles[item.id];
                if (item.id >= 500000) {
                    delete tmpObj.id;
                }
                tmpSurveys.push(tmpObj);
            })
        }
        Obj.surveys = tmpSurveys;
        if (streamId) {
            dispatch({ type: 'STREAM_UPDATE_REQUEST', id: streamId, formData: Obj });
        } else {
            dispatch({ type: 'STREAM_ADD_REQUEST', formData: Obj });
        }
    }


    useEffect(() => {
        if (!isAddLoading && addStatus === "success") {
            dispatch({ type: 'CLEAR_STREAM' });
            if (streamId) {
                message.success({ content: t('stream.update_success_msg'), className: 'custom-success-class' });
            } else {
                message.success({ content: t('stream.add_success_msg'), className: 'custom-success-class' });
            }
            navigate(`/streams`);
        }
    }, [isAddLoading, addStatus]);


    const onFinishFailed = () => { };
    const onSwitchChange = (e) => {
        dispatch({ type: "STREAM_TOGGLE_REQUEST", streamId: streamId, status: e })
    }

    const onNoOfSurveyChange = (e) => {
        if (e !== "") {
            let tmpCount = e;
            let tmpSurvey = [];
            for (let index = 0; index < tmpCount; index++) {
                let obj = {};
                if (index === 0) {
                   obj = { ...tmpSingleObject, uploads: surveyFiles[500000] ??[] };
                } else if (tmpSurveysList[index] === undefined) {
                    obj = {
                        'id': index + 500000,
                        'survey_id': '',
                        'name': '',
                        'name_fr': '',
                        'description_en': '',
                        'description_fr': '',
                        'survey_version': 1,
                        'status': 1,
                        'added': true,
                        'uploads': []
                    }
                } else {
                    obj = tmpSurveysList[index];
                }

                tmpSurvey.push(obj);

            }
            form.setFieldsValue({ no_of_survay: parseInt(e) });
            setNoOfSurvey(parseInt(e));
            tmpSurveysList = tmpSurvey;
        } else {
            setNoOfSurvey(1);
        }
    }


    const onDelete = (e) => {
        dispatch({ type: 'STREAM_DELETE_REQUEST', id: e });
    }

    useEffect(() => {
        if (!isDeleteLoading && deleteStatus === "success") {
            setVisible(false);
            setDeleteId("");
            navigate('/streams');
        }
    }, [isDeleteLoading, deleteStatus]);


    useEffect(() => {
        if (!isToggleLoading && streamStatus !== "") {
            dispatch({ type: 'CLEAR_STREAM' });
            if (streamStatus === "activated") {
                message.success({ content: t('stream.active_success_msg'), className: 'custom-success-class' });
            } else {
                message.warn({ content: t('stream.archived_success_msg'), className: 'custom-success-class' });
            }
        }
    }, [isToggleLoading, streamStatus]);

    const deleteRecord = (deleteIndex) => {
        let tmpList = tmpSurveysList;

        if (tmpList.length > 0) {
            setRefresh(true);
            let tmpList2 = tmpList.filter((e, index) => index !== deleteIndex);
            form.setFieldsValue({ no_of_survay: parseInt(tmpList2.length) });
            setNoOfSurvey(tmpList2.length);
            tmpSurveysList = tmpList2;
        }
    }


    useEffect(() => {
        return (() => {
            form.resetFields();
            tmpSurveysList = [];
            setSurveyHtml('');
        });
    }, [])

    const onSurveySwitchChange = (e, id) => {
        let tmp2 = [...tmpSurveysList];
        tmp2.map((item) => {
            if (item.id === id) {
                item.status = e ? 1 : 0;
            }
            return item;
        });
        tmpSurveysList = tmp2;
        if (id <= 500000) {
            dispatch({ type: "STREAM_SURVEY_TOGGLE_REQUEST", surveyId: id, status: e })
        }
    };


    const handleSurveyFiles = (surveyId, files) => {
        surveyFiles[surveyId] = files;
    };

    const loadSurveyHtml = (tmpNoSurvay, isEdit) => {
        setSurveyHtml("");
        let tmpHtml = tmpNoSurvay.length > 0 && tmpNoSurvay.map((item, i) => {
            let surveyName = ['surveys', i, 'name'];
            let surveyFrName = ['surveys', i, 'name_fr'];
            let surveyIdName = ['surveys', i, 'survey_id'];
            let descriptionEnName = ['surveys', i, 'description_en'];
            let descriptionFrName = ['surveys', i, 'description_fr'];
            let surveyVersionName = ['surveys', i, 'survey_version'];
            let hiddenId = ['surveys', i, 'id'];
            let status = ['surveys', i, 'status'];

            let isChecked = false;
            if (item.status !== undefined && item.status !== null) {
                isChecked = item.status === 1 ? true : false;
            }

            form.setFieldsValue({
                [surveyName]: item.name,
                [surveyFrName]: item.name_fr,
                [surveyIdName]: item.survey_id,
                [descriptionEnName]: item.description_en,
                [descriptionFrName]: item.description_fr,
                [surveyVersionName]: item.survey_version !== undefined && item.survey_version !== null ? parseInt(item.survey_version) : 1,
                [hiddenId]: item.id,
                [status]: isChecked,
            })


            return (
                <div className="role-switch stream mb5"
                     id={`${item.id}-${item.survey_id}`}
                     key={`${item.id}-${item.survey_id}`}>
                    <Row gutter={70} >
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <p className="count-title">{t('stream.survey')} {i + 1}</p>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "right" }}>
                            <div className="delete-stream">
                                {streamId &&
                                    <div>
                                        <Switch
                                            checkedChildren={<span>{t('common.active')}</span>}
                                            unCheckedChildren={<span>{t('common.archived')}</span>}
                                            defaultChecked={isChecked}
                                            onChange={(e) => onSurveySwitchChange(e, item.id)}
                                            size='large'
                                        />
                                    </div>
                                }

                                {streamId && tmpNoSurvay.length !== 1 && item.added === undefined &&

                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        shape="round"
                                        danger
                                        onClick={() => deleteRecord(i)}
                                        className='ml2 mb3'
                                        size={"medium"}
                                    >
                                        {t('common.delete')}
                                    </Button>
                                }

                            </div>

                        </Col>
                        <Col xs={24} sm={12} md={6} lg={6} xl={6} style={{ paddingRight: "15px" }}>
                            <Form.Item
                                label={t('stream.survey_name')}
                                name={surveyName}
                                className='form-field'
                                initialValue={item.name}
                                rules={[
                                    {
                                        required: true,
                                        message: t('stream.error_survey_name'),
                                    }
                                ]}
                            >
                                <Input initialValue={"Test"} placeholder={t("stream.survey_name")} />
                            </Form.Item>

                            <Form.Item
                                style={{ display: 'none' }}
                                label={t('stream.survey_name')}
                                name={hiddenId}
                                className='form-field'
                                initialValue={item.id}
                            >
                                <Input defaultValue={item.id} />
                            </Form.Item>
                            <Form.Item
                                style={{ display: 'none' }}
                                name={status}
                                className='form-field'
                                initialValue={isChecked ? 1 : 0}
                            >
                                <Input defaultValue={isChecked ? 1 : 0} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={6} xl={6} style={{ paddingLeft: "15px" }}>
                            <Form.Item
                                label={t('stream.survey_fr_name')}
                                name={surveyFrName}
                                className='form-field'
                                initialValue={item.name_fr}
                                rules={[
                                    {
                                        required: false,
                                        message: t('stream.error_survey_name'),
                                    }
                                ]}
                            >
                                <Input initialValue={"Test"} placeholder={t("stream.survey_fr_name")} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={6} xl={6} style={{ paddingRight: "15px" }}>
                            <Form.Item
                                label={t('stream.survey_id')}
                                name={surveyIdName}
                                className='form-field'
                                initialValue={item.survey_id}
                                rules={[
                                    {
                                        required: true,
                                        message: t('stream.error_survey_id'),
                                    }
                                ]}
                            >
                                <Input defaultValue={item.survey_id} placeholder={t("stream.survey_id")} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={6} xl={6} style={{ paddingLeft: "15px" }}>
                            <Form.Item
                                label={t('stream.survey_version')}
                                name={surveyVersionName}
                                className='form-field'
                                initialValue={item.survey_version !== undefined && item.survey_version !== null ? parseInt(item.survey_version) : 1}
                                rules={[
                                    {
                                        required: true,
                                        message: t('stream.error_survey_version'),
                                    }
                                ]}
                            >
                                <Input min={1} type="number" defaultValue={item.survey_version !== undefined && item.survey_version !== null ? parseInt(item.survey_version) : 1} placeholder={t("stream.survey_version")} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item
                                label={t('stream.english_description')}
                                name={descriptionEnName}
                                className='form-field'
                                initialValue={item.description_en}
                                rules={[
                                    {
                                        required: false,
                                    },
                                    {
                                        max: 500,
                                        message: t('common.max_error'),
                                    }
                                ]}
                            >
                                <TextArea
                                    defaultValue={item.description_en}
                                    autoSize={{ minRows: 2 }}
                                    placeholder={t("stream.english_description")} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item
                                label={t('stream.french_description')}
                                name={descriptionFrName}
                                className='form-field'
                                initialValue={item.description_fr}
                                rules={[
                                    {
                                        required: false,
                                        message: t('stream.error_french_description'),
                                    },
                                    {
                                        max: 500,
                                        message: t('common.max_error'),
                                    }
                                ]}
                            >
                                <TextArea
                                    defaultValue={item.description_fr}
                                    autoSize={{ minRows: 2 }}
                                    placeholder={t("stream.french_description")} />
                            </Form.Item>
                        </Col>

                        <Col xs={24}
                             style={{
                                 marginBottom: '25px'
                             }}
                        >
                            <SurveySupportGuide
                                key={`survey-support-guide-${item.id}`}
                                surveyId={item.id}
                                surveyFiles={item.uploads}
                                onFileListChange={(surveyId, files) => handleSurveyFiles(surveyId, files)}
                            />

                        </Col>

                    </Row>
                </div>
            )
        });


        setTimeout(() => {
            setSurveyHtml(tmpHtml);
        }, 50);
        setTimeout(() => {
            setRefresh(false);
        }, 100);
    }

    return (
        <Wrapper>
            <div className='padding-around stream-add input-class'>
                {isGetLoading && <Spin style={{ width: "100%" }} />}
                {!isGetLoading &&
                    <>
                        {streamId &&
                            <Card className='archive-card'>
                                <div className='content'>
                                    <p>{t('stream.status_msg')}</p>
                                    <div className='switch-delete'>
                                        <p>
                                            {isSwitchLoading && <Switch
                                                checkedChildren={<span>{t('common.active')}</span>}
                                                unCheckedChildren={<span>{t('common.archived')}</span>}
                                                defaultChecked={checked}
                                                size='large'
                                                loading={isSwitchLoading}
                                            />}
                                            {!isSwitchLoading && <p>
                                                <Switch
                                                    checkedChildren={<span>{t('common.active')}</span>}
                                                    unCheckedChildren={<span>{t('common.archived')}</span>}
                                                    defaultChecked={checked}
                                                    onChange={(e) => onSwitchChange(e)}
                                                    size='large'
                                                    loading={isSwitchLoading || isToggleLoading}
                                                />
                                            </p>}
                                        </p>
                                        <Button
                                            htmlType="submit"
                                            type="primary"
                                            shape="round"
                                            danger
                                            onClick={() => { setVisible(true) }}
                                            className='ml2'
                                            size={"medium"}
                                        >
                                            {t('common.delete')}
                                        </Button>
                                    </div>

                                </div>
                            </Card>
                        }
                        <Form
                            className='sign-form'
                            name='basic'
                            onFinish={onFinish}
                            layout="vertical"
                            form={form}
                            autoComplete="off"
                            onFinishFailed={onFinishFailed}>

                            <Row gutter={70} >
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                    <Form.Item
                                        label={t('stream.english_name')}
                                        name='name_en'
                                        className='form-field'
                                        rules={[
                                            {
                                                required: true,
                                                message: t('stream.error_english_name'),
                                            }
                                        ]}
                                    >
                                        <Input placeholder={t("stream.english_name")} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                    <Form.Item
                                        label={t('stream.french_name')}
                                        name='name_fr'
                                        className='form-field'
                                        rules={[
                                            {
                                                required: true,
                                                message: t('stream.error_french_name'),
                                            },
                                        ]}
                                    >
                                        <Input placeholder={t("stream.french_name")} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={70} >
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                    <Form.Item
                                        label={t('stream.english_description')}
                                        name='description_en'
                                        className='form-field'
                                        rules={[
                                            {
                                                required: false,
                                            },
                                            {
                                                max: 500,
                                                message: t('common.max_error'),
                                            }
                                        ]}
                                    >
                                        <TextArea
                                            autoSize={{ minRows: 3 }}
                                            placeholder={t("stream.english_description")} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                    <Form.Item
                                        label={t('stream.french_description')}
                                        name='description_fr'
                                        className='form-field'
                                        rules={[
                                            {
                                                required: false,
                                                message: t('stream.error_french_description'),
                                            },
                                            {
                                                max: 500,
                                                message: t('common.max_error'),
                                            }
                                        ]}
                                    >
                                        <TextArea
                                            autoSize={{ minRows: 3 }}
                                            placeholder={t("stream.french_description")} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={70} >
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                    <Form.Item
                                        label={t('stream.no_of_survay')}
                                        name='no_of_survay'
                                        className='form-field'
                                        initialValue={noOfSurvey}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('stream.error_no_of_survay'),
                                            },
                                            {
                                                pattern: new RegExp(/^[0-9]*$/),
                                                message: t("stream.error_numeric"),
                                            }
                                        ]}
                                    >
                                        <InputNumber
                                            min={1}
                                            className="no-of-text"
                                            value={noOfSurvey}
                                            onChange={(e) => onNoOfSurveyChange(e)}
                                            type="number"
                                            placeholder={t("stream.french_name")} />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <Form.Item
                                        label={t('stream.select_stream_language')}
                                        name='languages'
                                        className='form-field'
                                        style={{
                                            width: '100%',
                                            height: '40px'
                                        }}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('stream.select_stream_language'),
                                            }
                                        ]}
                                    >
                                        <Select
                                            mode="multiple"
                                            allowClear
                                        >
                                            {langList.length > 0 && langList.map((item) =>
                                                <Option key={item.value} value={item.value}>{t(`languages.${item.value}`)}</Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>


                            </Row>

                            <Row gutter={{ xs: 15, sm: 20, md: 35, lg: 70 }} >
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="red report-user-select connection">
                                    <Form.Item label={t('stream.survey_connection')}>
                                        {refresh && <p>Loading...</p>}
                                        {!refresh && surveyHtml}
                                    </Form.Item>

                                </Col>
                            </Row>

                            <Row gutter={70} className={"mt10"}>
                                <Col xs={{ span: 24, offset: 3 }} md={{ span: 24, offset: 6 }} lg={{ span: 12, offset: 9 }} >
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        shape="round"
                                        loading={isAddLoading}
                                        disabled={isAddLoading}
                                        size={"large"}>
                                        {!streamId ? t('common.confirm') : t('common.update')}
                                    </Button>
                                    <Button type="secondary" onClick={() => {
                                        dispatch({ type: "CLEAR_STREAM" });
                                        navigate('/streams')
                                    }} className={"ml5"} shape="round" size={"large"}>
                                        {t('common.cancel')}
                                    </Button>
                                </Col>
                            </Row>

                        </Form>
                    </>
                }
                <DeleteModal
                    visible={visible}
                    deleteId={deleteId}
                    subTitle={t("stream.delete_confirm")}
                    setVisible={() => { setVisible(false) }}
                    onDelete={(e) => onDelete(e)}
                    isDeleteLoading={isDeleteLoading}
                />
            </div>
        </Wrapper >
    );
}

export default AddStream;
