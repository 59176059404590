import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/fr-ca';
import frCA from 'antd/es/date-picker/locale/fr_CA';
import enUS from 'antd/es/date-picker/locale/en_US';
import { Col, Row, Button, Input, Select, DatePicker, Form, Table, Space, Badge, Spin, Pagination } from 'antd';
import { FilterOutlined, AppstoreOutlined, BarsOutlined } from '@ant-design/icons';
import useTitle from '../comnon/title';
import Wrapper from '../comnon/wrapper';
import { CLIENT_NAME, ROLE, ROLE_NAME } from '../../../utils/constant';
import { getSetting, isHomePage } from '../../../utils/functions';
const { Search } = Input;
const { Option } = Select;
const { Column } = Table;
let filterParams = { organizations: [], tags: [], tags2: [], start: "", end: "", q: "" };
const Reports = () => {
    const { pathname } = useLocation();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useTitle(t(isHomePage(pathname) ? "home.view_title" : "report.view_title"));
    const { isReportLoading, reportGroupList, isTagsLoading, tagsList } = useSelector(state => state.report);
    const { isLoading, organizationList } = useSelector(state => state.organization);

    const [view, setView] = useState('grid');
    const [isFilterActive, setIsFilterActive] = useState(true);
    const [printReportHtml, setPrintReportHtml] = useState("");
    const [filterCount, setFilterCount] = useState(0);
    const [perPage, setPerPage] = useState(50);
    const [totalRecords, settotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [tableData, setTableData] = useState([]);

    const loadReportGroups = (params = {}) => {
        let tmpParams = { ...params, status: "active" };
        if (pathname === '/' || pathname === '/home') {
            tmpParams.show_on_homepage = 1;
        }
        dispatch({ type: 'REPORT_GROUP_SEARCH_LIST_REQUEST', formData: tmpParams });
    }
    const loadOrganizations = (params = {}) => {

        dispatch({ type: 'ORGANIZATION_LIST_REQUEST', formData: { status: "active" } });
        dispatch({ type: 'TAGS_LIST_REQUEST', formData: {} });
    }

    useEffect(() => {
        filterParams = { organizations: [], tags: [], tags2: [], start: "", end: "", q: "" };
        loadReportGroups();
        loadOrganizations();
    }, []);


    useEffect(() => {
        setPrintReportHtml("");
        if (!isReportLoading && reportGroupList.length > 0) {
            settotalRecords(reportGroupList.length);
            loadReportHtml(reportGroupList.slice(0, perPage))
        } else if (!isReportLoading && reportGroupList.length === 0) {
            setPrintReportHtml("");
        }
    }, [isReportLoading, reportGroupList, i18n.language]);

    useEffect(() => {
        if (view === 'table') {
            if (!isReportLoading && reportGroupList.length > 0) {
                let tmpRepList = [];
                reportGroupList.map((item, index) => {
                    if (item.reports.length > 0) {
                        item.reports.map((report_item, index) => {
                            let Obj = {};
                            Obj.report_name = i18n.language === "fr" ? report_item.name_fr : report_item.name_en;
                            Obj.key = item.id + "_" + report_item.id;
                            Obj.report_id = report_item.id;
                            Obj.group_name = i18n.language === "fr" ? item.name_fr : item.name_en;
                            Obj.group_id = item.id
                            tmpRepList.push(Obj);
                        })
                    }
                    // Obj.report_group = item.reports;
                    // Obj.organization = item.organizations;
                });
                setTableData(tmpRepList);
            } else if (!isReportLoading && reportGroupList.length === 0) {
                setTableData([]);
            }
        }
    }, [isReportLoading, reportGroupList, view, i18n.language]);

    const loadReportHtml = (tmpReportData) => {
        let tmpHtml = "";
        if (tmpReportData.length > 0) {
            tmpHtml = tmpReportData.map((item, index) => {
                    return (
                        <div className='gird-box'>
                            <div className='gird-box-inner'>
                                <p className='group-name'>{i18n.language === "fr" ? item.name_fr : item.name_en}</p>
                                <p className='group-description'>{i18n.language === "fr" ? item.description_fr : item.description_en}</p>
                                <div className='grid-report-box'>
                                    {loadReport(item.reports, index, item.id)}
                                </div>
                            </div>
                        </div>

                    )
                }
            )
        }
        setPrintReportHtml(tmpHtml);
    }

    const loadReport = (tempReports, groupIndex, groupId) => {
        let tmpHtml = [];
        if (tempReports.length > 0) {
            tempReports.map((item, index) => {
                let custId = `report_${groupIndex}_${index}`;
                return tmpHtml.push(
                    <div className='report-list' key={custId}
                         onMouseEnter={() => handleMouseEnter(custId)}
                         onMouseLeave={() => handleMouseLeave(custId)}
                         onClick={() => navigate(`/view-report?group_id=${groupId}&report_id=${item.id}`)}
                         id={custId}>
                        <p className={`overlay`} ><span>{t('common.open_report')}</span></p>
                        <p className='report-title'>{i18n.language === "fr" ? item.name_fr : item.name_en}</p>
                        <p className={`report-description ${item.description_en !== null ? 'mt1' : ''}`} >{i18n.language === "fr" ? item.description_fr : item.description_en}</p>
                    </div>
                )
            })
        } else {
            tmpHtml = <p>{t('report.not_found')}</p>
        }
        return tmpHtml;
    }

    const handleMouseEnter = (custId) => {
        document.getElementById(custId).classList.add('active')
    }
    const handleMouseLeave = (custId) => {
        document.getElementById(custId).classList.remove('active')
    }


    const onChange = (e, type) => {
        if (e !== "" && e !== null) {
            if (type === "start" || type === "end") {
                let tmpDate = moment(e).format(`YYYY-MM-DD`);
                filterParams[type] = `${tmpDate} 00:00:00`;
            } else {
                filterParams[type] = e;
            }
        } else {
            if (type === "start") {
                filterParams[type] = "";
            } else if (type === "end") {
                filterParams[type] = "";
            } else {
                filterParams[type] = [];
            }
        }

        if (filterParams.start === "") {
            delete filterParams.start;
        }
        if (filterParams.end === "") {
            delete filterParams.end;
        }
        let count = 0;
        for (const key in filterParams) {
            if (key === "start") {
                if (filterParams[key] !== "") {
                    count++;
                }
            } else if (key === "end") {
                if (filterParams[key] !== "") {
                    count++;
                }
            } else {
                if (filterParams[key].length > 0) {
                    count++;
                }
            }

        }
        setFilterCount(count);
        loadReportGroups(filterParams);

    }

    const onPageChange = (pageNumber) => {
        setCurrentPage(pageNumber)
        let newData = reportGroupList.slice((pageNumber - 1) * perPage, pageNumber * perPage);
        loadReportHtml(newData)
    }

    const loadButton = (record, reports) => {
        let tmpHtml = [];
        reports.map((item) => {
            return tmpHtml.push(<Button type="primary" shape="round" size={"default"}
                                        onClick={() => navigate(`/view-report?group_id=${record.key}&report_id=${item.id}`)}>
                {t('common.open_report')}
            </Button>)
        });
        return tmpHtml;
    }

    const onSearch = (e) => { }
    return (
        //   <div>Report View</div>
        <Wrapper title={`Reports`} >
            <div className='padding-around report-view'>
                {!isHomePage(pathname) && <>
                    <Row className='nav-sub-header'>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="green">
                            <div className='nav-add-btn'>
                                <Search
                                    style={{ width: "30%" }}
                                    allowClear
                                    placeholder={t("common.search_text")}
                                    onSearch={(e) => onChange(e, 'q')}
                                    enterButton />
                                <Badge count={filterCount}>
                                    <Button
                                        id="filter-report"
                                        className={`filter-report ${isFilterActive ? 'active' : ''}`}
                                        type="primary"
                                        shape="circle"
                                        onClick={() => setIsFilterActive(!isFilterActive)} icon={<FilterOutlined />} size={"medium"} />
                                </Badge>
                            </div>
                        </Col>
                    </Row>
                    {isFilterActive &&
                        <Form
                            className='sign-form'
                            name='basic'
                            layout="vertical"
                            autoComplete="off"
                        >
                            <Row gutter={35} className='filter-section'>
                                {(ROLE !== ROLE_NAME.ORG_ADMIN && ROLE !== ROLE_NAME.ORG_USER) &&
                                    <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                                        <Form.Item label={t('report_mngmt.org')}>
                                            <Select
                                                style={{ width: "100%" }}
                                                showSearch
                                                placeholder={t("common.select")}
                                                loading={isLoading}
                                                autoComplete="none"
                                                mode="multiple"
                                                size="large"
                                                value={filterParams['organizations']}
                                                onChange={(e) => onChange(e, 'organizations')}
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            >
                                                {!isLoading && organizationList.length > 0 && organizationList.map((item) =>
                                                    <Option key={item.id} value={item.value}>{item.name}</Option>
                                                )}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                }
                                {CLIENT_NAME !== "OTTAWA" &&
                                    <>
                                        <Col xs={24} sm={24} md={12} lg={12}
                                            xl={(ROLE !== ROLE_NAME.ORG_ADMIN && ROLE !== ROLE_NAME.ORG_USER) ? 5 : 8}>
                                            <Form.Item label={getSetting('filter1') !== "" ? getSetting('filter1') : t("report_mngmt.filter_field")}>
                                                <Select
                                                    style={{ width: "100%" }}
                                                    showSearch
                                                    isLoading={isTagsLoading}
                                                    placeholder={t("common.select")}
                                                    loading={false}
                                                    autoComplete="none"
                                                    mode="multiple"
                                                    size="large"
                                                    value={filterParams['tags']}
                                                    onChange={(e) => onChange(e, 'tags')}
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                >
                                                    {!isTagsLoading && Object.keys(tagsList).length > 0 && tagsList.field_no1.map((item) => {
                                                        // if (item.field_no === 1) {
                                                        return <Option key={item.name + item.id} value={item.name}>{item.name}</Option>
                                                        // }
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12}
                                            xl={(ROLE !== ROLE_NAME.ORG_ADMIN && ROLE !== ROLE_NAME.ORG_USER) ? 5 : 8}>
                                            <Form.Item label={getSetting('filter2') !== "" ? getSetting('filter2') : t("report_mngmt.filter_field2")}>
                                                <Select
                                                    style={{ width: "100%" }}
                                                    showSearch
                                                    isLoading={isTagsLoading}
                                                    placeholder={t("common.select")}
                                                    loading={false}
                                                    autoComplete="none"
                                                    mode="multiple"
                                                    size="large"
                                                    value={filterParams['tags2']}
                                                    onChange={(e) => onChange(e, 'tags2')}
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                >
                                                    {!isTagsLoading && Object.keys(tagsList).length > 0 && tagsList.field_no2.map((item) => {
                                                        return <Option key={item.name + item.id} value={item.name}>{item.name}</Option>
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </>
                                }
                                <Col xs={24} sm={24} md={12} lg={12} xl={CLIENT_NAME !== "OTTAWA" ? 4 : 6} className="date-picker">
                                    <Form.Item label={t('report_mngmt.date_range_start')}>
                                        <DatePicker
                                            locale={i18n.language === 'fr' ? frCA : enUS}
                                            value={(filterParams['start'] !== undefined && filterParams['start'] !== "" && filterParams['start'] !== null) ? moment(filterParams['start'], 'YYYY-MM-DD') : null}
                                            placeholder={t("common.select")}
                                            style={{ width: "100%" }}
                                            onChange={(e) => onChange(e, 'start')}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={CLIENT_NAME !== "OTTAWA" ? 4 : 6} className="date-picker">
                                    <Form.Item label={t('report_mngmt.date_range_end')}>
                                        <DatePicker
                                            locale={i18n.language === 'fr' ? frCA : enUS}
                                            value={(filterParams['end'] !== undefined && filterParams['end'] !== "" && filterParams['end'] !== null) ? moment(filterParams['end'], 'YYYY-MM-DD') : null}
                                            placeholder={t("common.select")}
                                            style={{ width: "100%" }}
                                            onChange={(e) => onChange(e, 'end')}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    }

                    <div className='switch-view'>
                        <Button
                            id="filter-report"
                            className="filter-report"
                            type="primary"
                            shape="circle"
                            onClick={() => setView(view === 'grid' ? 'table' : 'grid')}
                            icon={view === 'grid' ? <AppstoreOutlined /> : <BarsOutlined />} size={"medium"} />
                    </div>
                </>
                }

                <div className={`${isHomePage(pathname) ? "" : "mt10"}`}>
                    {view === "grid" &&
                        <>
                            {isReportLoading && <Row><Spin style={{ width: "100%" }} /> </Row>}

                            <div className="report-container">
                                {!isReportLoading && printReportHtml !== "" && printReportHtml}
                                <div style={{ clear: 'both' }}></div>
                                {!isReportLoading && printReportHtml === "" && <Col xs={24} sm={24} md={12} lg={12} xl={12} className="gird-item gutter-row"><p>{t('common.no_result')}</p></Col>}
                            </div>
                            {!isReportLoading && totalRecords > perPage && printReportHtml !== "" &&
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className='right'>
                                        <Pagination
                                            current={currentPage}
                                            total={totalRecords}
                                            pageSize={perPage}
                                            onChange={onPageChange}
                                        />
                                    </Col>
                                </Row>
                            }
                        </>
                    }
                    {view === "table" &&
                        <Row gutter={70}>
                            <Table
                                loading={isReportLoading}
                                style={{ width: "100%" }}
                                dataSource={tableData}
                                scroll={{ x: "max-content" }}
                                pagination={{
                                    showTotal: (total, range) => `${range[0]}-${range[1]} ${t("common.of")} ${total} ${t("common.records")}`,
                                }}
                                locale={{
                                    triggerDesc: t("common.desc_sorting"),
                                    triggerAsc: t("common.asc_sorting"),
                                    cancelSort: t("common.cancel_sorting")
                                }}
                            >
                                <Column

                                    title={t('report_mngmt.report_name')}
                                    dataIndex='report_name'
                                    key='report_name'
                                    sortDirections={['descend', 'ascend']}
                                    sorter={(a, b) => a.report_name ? a.report_name.localeCompare(b.report_name) : a.report_name}
                                    render={(text) => <span>{text}</span>} />
                                <Column

                                    title={t('report_mngmt.report_groups')}
                                    dataIndex='group_name'
                                    key='group_name'
                                    sortDirections={['descend', 'ascend']}
                                    sorter={(a, b) => a.group_name ? a.group_name.localeCompare(b.group_name) : a.group_name}
                                    render={(text) => <span>{text}</span>} />
                                {/* <Column
                                    title={t('report_mngmt.report_name')}
                                    dataIndex='report_group'
                                    key='report_group'
                                    render={(_, record) => {
                                        if (record.report_group !== undefined && record.report_group !== null && record.report_group.length > 0) {
                                            return record.report_group.map((item, index) => {
                                                if (index < 3) {
                                                    return (
                                                        <Tag key={index}>
                                                            {item.name_en}
                                                        </Tag>
                                                    )
                                                } else if (index === 3) {
                                                    return (<Tag key={index} color={"gray"}>
                                                        More...
                                                    </Tag>)
                                                }
                                            });
                                        }
                                    }}
                                /> */}

                                <Column
                                    title={t('common.view')}
                                    key='action'
                                    render={(_, record) => {
                                        return (
                                            <Space size="middle" className={`cursor ${record.isDisabled ? 'disabled-edit' : 'not-disabled'}`}>
                                                <Button type="primary" shape="round" size={"default"}
                                                        onClick={() => navigate(`/view-report?group_id=${record.group_id}&report_id=${record.report_id}`)}>
                                                    {t('common.open_report')}
                                                </Button>
                                            </Space>
                                        )
                                    }}
                                />
                            </Table>
                        </Row>

                    }
                </div>
            </div>
        </Wrapper>
    )
};

export default Reports;
