import React, { useEffect, useState } from 'react';
import { Col, Row, Button, Input, Popover } from 'antd';
import useTitle from '../comnon/title';
import Wrapper from '../comnon/wrapper';
import { PlusOutlined, EditFilled, DeleteFilled, DownloadOutlined } from '@ant-design/icons';
import { Space, Table, Tag, message, Tooltip } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';
import DeleteModal from '../comnon/modal.js';

const { Column } = Table;

const { Search } = Input;
const ReportManagement = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    useTitle(t("report_mngmt.view_title"));
    const [searchParams] = useSearchParams();

    const { isReportLoading, reportList, isDeleteReportLoading, deleteReportStatus, deleteType, isExportLoading, exportData, isReportELoading, reportExportData } = useSelector(state => state.report);
    const [data, setData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [deleteId, setDeleteId] = useState("");
    const [currentTab, setCurrentTab] = useState('report');
    const [groupExportLoading, setIsGroupExportLoading] = useState(isExportLoading);
    const [reportExportLoading, setIsReportExportLoading] = useState(isReportELoading);
    const [tmpExportData, setTmpExportData] = useState("");
    const [tmpReportExportData, setTmpReportExportData] = useState("");


    useEffect(() => {
        if (searchParams.get('tab') !== null) {
            onChangeTab(searchParams.get('tab'))
        } else {
            loadReports();
        }
    }, [searchParams]);

    const loadReports = (params = {}) => {
        dispatch({ type: "REPORT_LIST_REQUEST", formData: params })
    }
    const loadReportsGroups = (params = {}) => {
        dispatch({ type: "REPORT_GROUP_LIST_REQUEST", formData: params })
    }

    useEffect(() => {
        if (!isReportLoading && reportList.length > 0) {
            let tmpRepList = [];
            reportList.map((item) => {
                let Obj = {};
                Obj.key = item.id;
                Obj.name = i18n.language === "fr" ? item.name_fr : item.name_en;
                Obj.status = item.archive_at === null ? "Active" : "Archived";
                Obj.workbook = item.workbook;
                if (currentTab === "report-group") {
                    Obj.report_group = item.reports;
                    Obj.organization = item.organizations;
                } else {
                    Obj.report_group = item.groups;
                }
                tmpRepList.push(Obj);
                return true;
            });
            setData(tmpRepList);
        } else if (!isReportLoading && reportList.length === 0) {
            setData([]);
        }
    }, [isReportLoading, reportList, currentTab, i18n.language])

    const onSearch = (e) => {
        if (currentTab === "report") {
            loadReports({ q: e });
        } else if (currentTab === "report-group") {
            loadReportsGroups({ q: e })
        }
    }

    const onChangeTab = (tabName) => {
        setCurrentTab(tabName);
        if (tabName === "report") {
            loadReports({})
        } else if (tabName === "report-group") {
            loadReportsGroups({})
        }

    }

    const onDelete = (e) => {
        dispatch({ type: 'REPORT_DELETE_REQUEST', id: deleteId, deleteType: currentTab === 'report' ? 'report' : 'group' });
    }

    useEffect(() => {
        if (!isDeleteReportLoading && deleteReportStatus === "success") {
            setVisible(false);
            if (deleteType === "report") {
                message.success({ content: t("report_mngmt.delete_success_msg"), className: 'custom-success-class' });
            } else {
                message.success({ content: t("report_mngmt.delete_group_success_msg"), className: 'custom-success-class' });
            }
            dispatch({ type: 'CLEAR_REPORT_STATUS' });
            if (currentTab === "report") {
                loadReports();
            } else {
                loadReportsGroups();
            }
        }
    }, [isDeleteReportLoading, deleteReportStatus, deleteType]);

    const exportGroup = () => {
        setIsGroupExportLoading(true);
        dispatch({ type: 'GROUP_EXPORT_REQUEST', params: { 'active': true } });
    }

    const exportReport = () => {
        setIsReportExportLoading(true);
        dispatch({ type: 'REPORT_EXPORT_REQUEST', params: { 'active': true } });
    }


    useEffect(() => {
        if (!isExportLoading && exportData !== "") {
            setTmpExportData(exportData);
            setTimeout(() => {
                dispatch({ type: "CLEAR_REPORT_STATUS" });
                setIsGroupExportLoading(isExportLoading);
                document.getElementById('exportToCSVLink').click();
            }, 1000);
        }
    }, [isExportLoading, exportData]);

    useEffect(() => {
        if (!isReportELoading && reportExportData !== "") {
            setTmpReportExportData(reportExportData);
            setTimeout(() => {
                dispatch({ type: "CLEAR_REPORT_STATUS" });
                setIsReportExportLoading(isReportELoading);
                document.getElementById('reportExportToCSVLink').click();
            }, 1000);
        }
    }, [isReportELoading, reportExportData])

    const addContent = (
        <div className="export-popup-main">
            <div>
                <Button id="add-report" type="primary" shape="circle" onClick={() => navigate('/reports-management/add')} icon={<PlusOutlined />} size={"medium"} />
                <label htmlFor="add-report" className="nav-add-label"> {t('report_mngmt.add_report')}</label>
            </div>
            <div className=''>
                <Button id="add-group-report" type="primary" shape="circle" onClick={() => navigate('/reports-management/add-group')} icon={<PlusOutlined />} size={"medium"} />
                <label htmlFor="add-group-report" className="nav-add-label"> {t('report_mngmt.add_report_group')}</label>
            </div>
        </div >
    );
    const exportContent = (
        <div className="export-popup-main">
            <div className=''>
                <Button id="export-group"
                    className='export-btn'
                    type="primary"
                    shape="circle"
                    loading={groupExportLoading}
                    disabled={groupExportLoading}
                    onClick={() => exportGroup()}
                    icon={<DownloadOutlined />} size={"medium"} />
                <label htmlFor="export-group" className="nav-add-label">&nbsp;{t('common.export_group')}</label>
            </div>
            <div className=''>
                <Button id="export-report"
                    className='export-btn'
                    type="primary"
                    shape="circle"
                    loading={reportExportLoading}
                    disabled={reportExportLoading}
                    onClick={() => exportReport()}
                    icon={<DownloadOutlined />} size={"medium"} />
                <label htmlFor="export-report" className="nav-add-label">&nbsp;{t('common.export_report')}</label>
            </div>
        </div >
    );

    return (
        <Wrapper>
            {/* <p>Report Management</p> */}
            <div className='padding-around report-mngmnt-view'>
                <Row className='nav-sub-header'>
                    <Col xs={24} sm={24} md={16} lg={16} xl={16} className="red">
                        <div className='nav-add-btn'>
                            <div>
                                <Popover placement="bottomLeft" overlayClassName="password-tooltip" content={addContent} trigger={'click'} >
                                    <Button
                                        id="add-user"
                                        type="primary"
                                        shape="circle"
                                        icon={<PlusOutlined />}
                                        size={"medium"}
                                    />
                                    <label htmlFor="add-user" className="nav-add-label">{t('common.add')}</label>
                                </Popover>
                            </div>
                            <div className='ml15'>
                                <Popover placement="bottomRight" overlayClassName="password-tooltip" content={exportContent} trigger={'click'} >

                                    <Button id="export-user"
                                        className='export-btn'
                                        type="primary"
                                        shape="circle"
                                        icon={<DownloadOutlined />} size={"medium"} />
                                    <label htmlFor="export-user" className="nav-add-label">{t('common.export')}</label>
                                </Popover>
                            </div>

                        </div>

                        {/* <div className='nav-add-btn custom'>
                            <div>
                                <Button id="add-report" type="primary" shape="circle" onClick={() => navigate('/reports-management/add')} icon={<PlusOutlined />} size={"medium"} />
                                <label htmlFor="add-report" className="nav-add-label"> {t('report_mngmt.add_report')}</label>
                            </div>
                            <div className=''>
                                <Button id="add-group-report" type="primary" shape="circle" onClick={() => navigate('/reports-management/add-group')} icon={<PlusOutlined />} size={"medium"} />
                                <label htmlFor="add-group-report" className="nav-add-label"> {t('report_mngmt.add_report_group')}</label>
                            </div>
                            <div className=''>
                                <Button id="export-group"
                                    className='export-btn'
                                    type="primary"
                                    shape="circle"
                                    loading={groupExportLoading}
                                    disabled={groupExportLoading}
                                    onClick={() => exportGroup()}
                                    icon={<DownloadOutlined />} size={"medium"} /><label htmlFor="export-group" className="nav-add-label">{t('common.export_group')}</label>
                            </div>
                            <div className=''>
                                <Button id="export-report"
                                    className='export-btn'
                                    type="primary"
                                    shape="circle"
                                    loading={reportExportLoading}
                                    disabled={reportExportLoading}
                                    onClick={() => exportReport()}
                                    icon={<DownloadOutlined />} size={"medium"} /><label htmlFor="export-report" className="nav-add-label">{t('common.export_report')}</label>
                            </div>

                        </div> */}

                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="green">
                        <Search allowClear placeholder={t("common.search_text")} onSearch={onSearch} enterButton />
                    </Col>
                </Row>
                <CSVLink id="exportToCSVLink" filename="Report-Groups.csv" data={tmpExportData} />
                <CSVLink id="reportExportToCSVLink" filename="Report.csv" data={tmpReportExportData} />
                <Row className='listing-table mt10'>
                    <div className='tab'>
                        <p className={`b-right ${currentTab === 'report' ? 'active' : ''}`} onClick={() => onChangeTab('report')}><span>{t('tab.reports')}</span></p>
                        <p className={`${currentTab === 'report-group' ? 'active' : ''}`} onClick={() => onChangeTab('report-group')}><span>{t('tab.report_groups')}</span></p>
                    </div>

                    <Table
                        style={{ width: "100%" }}
                        dataSource={data}
                        loading={isReportLoading}
                        scroll={{ x: "max-content" }}
                        pagination={{
                            showTotal: (total, range) => `${range[0]}-${range[1]} ${t("common.of")} ${total} ${t("common.records")}`,
                        }}
                        locale={{
                            triggerDesc: t("common.desc_sorting"),
                            triggerAsc: t("common.asc_sorting"),
                            cancelSort: t("common.cancel_sorting")
                        }}
                    >
                        {/* <Column title="Age" dataIndex="age" key="age" /> */}
                        <Column

                            title={currentTab === "report" ? t('report_mngmt.report_name') : t('report_mngmt.group_name')}
                            dataIndex='name'
                            key='name'
                            sortDirections={['descend', 'ascend']}
                            sorter={(a, b) => a.name ? a.name.localeCompare(b.name) : a.name}
                            render={(text) => <span>{text}</span>} />
                        <Column
                            width={currentTab !== "report" ? "350px" : "auto"}
                            title={currentTab === "report" ? t('report_mngmt.report_groups') : t('report_mngmt.reports')}
                            dataIndex='report_group'
                            key='report_group'
                            render={(_, record) => {
                                if (record.report_group !== undefined && record.report_group !== null && record.report_group.length > 0) {
                                    return record.report_group.map((item, index) => {
                                        if (index < 3) {
                                            return (
                                                <Tag key={index}>
                                                    {item.name_en}
                                                </Tag>
                                            )
                                        } else if (index === 3) {
                                            return (<Tag key={index} color={"gray"}>
                                                More...
                                            </Tag>)
                                        }
                                    });
                                }
                            }}
                        />
                        {currentTab !== "report" &&
                            <Column
                                width={"350px"}
                                title={t('report_mngmt.organization')}
                                dataIndex='organization'
                                key='organization'
                                render={(_, record) => {
                                    if (record.organization !== undefined && record.organization !== null && record.organization.length > 0) {
                                        return record.organization.map((item, index) => {
                                            if (index < 3) {
                                                return (
                                                    <Tag key={index}>
                                                        {item.name}
                                                    </Tag>
                                                )
                                            } else if (index === 3) {
                                                return (<Tag key={index} color={"gray"} onClick={() => {
                                                    if (currentTab === "report") {
                                                        navigate(`/reports-management/edit/${record.key}    `)
                                                    } else {
                                                        navigate(`/reports-management/edit-group/${record.key}?tab=${currentTab}`)
                                                    }
                                                }}>
                                                    More...
                                                </Tag>)
                                            }
                                        });
                                    }
                                }}
                            />
                        }
                        <Column

                            title={t('common.status')}
                            key='status'
                            dataIndex='status'
                            filters={[
                                {
                                    text: t("common.active"),
                                    value: 'Active',
                                },
                                {
                                    text: t("common.archive"),
                                    value: 'Archived',
                                }
                            ]}
                            filterMode='tree'
                            onFilter={(value, record) => record.status.startsWith(value)}
                            render={(_, { status }) => {
                                let color = (status !== 'Active') ? "default" : "success";
                                return (
                                    <Tag color={color} key={status}>
                                        {status.toUpperCase()}
                                    </Tag>)
                            }}
                        />
                        <Column

                            title={t('common.action')}
                            key='action'
                            render={(_, record) => {
                                return (
                                    <Space size="middle" className={`cursor ${record.isDisabled ? 'disabled-edit' : 'not-disabled'}`}>
                                        <Tooltip
                                            placement="top"
                                            title={currentTab === "report" ? t('report_mngmt.edit_tooltip') : t('report_mngmt.edit_group_tooltip')}>
                                            <EditFilled
                                                disabled={record.isDisabled}
                                                className={`cursor ${record.isDisabled ? 'disabled' : 'not-disabled'}`}
                                                onClick={() => {
                                                    if (currentTab === "report") {
                                                        navigate(`/reports-management/edit/${record.key}    `)
                                                    } else {
                                                        navigate(`/reports-management/edit-group/${record.key}?tab=${currentTab}`)
                                                    }
                                                }} style={{ fontSize: "18px" }} />
                                        </Tooltip>
                                        <Tooltip placement="top" color={"red"} title={currentTab === "report" ? t('report_mngmt.delete_tooltip') : t('report_mngmt.delete_group_tooltip')}>
                                            <DeleteFilled
                                                disabled={record.isDisabled}
                                                onClick={() => {
                                                    setDeleteId(record.key);
                                                    setVisible(true)
                                                }}
                                                className={`cursor ${record.isDisabled ? 'disabled' : 'not-disabled'}`}
                                                style={{ fontSize: "18px" }} />
                                        </Tooltip>
                                    </Space>
                                )
                            }}
                        />
                    </Table>
                    <DeleteModal
                        visible={visible}
                        setVisible={() => { setDeleteId(""); setVisible(false) }}
                        onDelete={() => onDelete()}
                        subTitle={currentTab === "report" ? t("report_mngmt.delete_confirm") : t("report_mngmt.delete_confirm_group")}
                        deleteId={deleteId}
                        isDeleteLoading={isDeleteReportLoading}
                    />

                </Row>
            </div>
        </Wrapper>
    )
};

export default ReportManagement;