import { put, takeLatest, call, delay } from 'redux-saga/effects'
import { message } from 'antd';
//Import API Service.
import { deleteRequestAPI, getRequestAPI, postRequestAPI, putRequestAPI } from '../../services/API/api';

function* getOrganizationList({ formData }) {
    try {
        const response = yield call(getRequestAPI, { url: `organizations`, params: formData });

        if (response.status === 200) {
            yield put({ type: 'ORGANIZATION_LIST_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'ORGANIZATION_LIST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'ORGANIZATION_LIST_ERROR', error: error.message || "" });
    }
}

function* addOrganization({ formData }) {
    try {
        const response = yield call(postRequestAPI, { url: `organization`, data: formData });
        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'ORGANIZATION_ADD_SUCCESS', data: response.data.data || "" });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'ORGANIZATION_ADD_ERROR', error: response });
        }
    } catch (error) {
        yield put({ type: 'ORGANIZATION_ADD_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* updateOrganization({ id, formData }) {
    try {
        const response = yield call(putRequestAPI, { url: `organization/${id}`, data: formData });
        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'ORGANIZATION_UPDATE_SUCCESS', data: response.data.data || "" });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'ORGANIZATION_UPDATE_ERROR', error: response });
        }
    } catch (error) {
        yield put({ type: 'ORGANIZATION_UPDATE_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* getOrganizationDetail({ id }) {
    try {
        const response = yield call(getRequestAPI, { url: `organization/${id}`, params: {} });
        if (response.status === 200) {
            yield put({ type: 'ORGANIZATION_DETAIL_SUCCESS', data: response.data.data || [] });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });

            yield put({ type: 'ORGANIZATION_DETAIL_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'ORGANIZATION_DETAIL_ERROR', error: error.message || "" });
    }
}
function* deleteOrganization({ id }) {
    try {
        const response = yield call(deleteRequestAPI, { url: `organization/${id}` });
        if (response.status === 200 || response.status === 204) {
            yield put({ type: 'ORGANIZATION_DELETE_SUCCESS' });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'ORGANIZATION_DELETE_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'ORGANIZATION_DELETE_ERROR', error: error.message || "" });
    }
}
function* updateStatus({ orgId, status }) {
    try {
        const response = yield call(postRequestAPI, { url: `organization/${orgId}/toggle-status` });
        if (response.status === 200) {
            yield put({ type: 'ORGANIZATION_TOGGLE_SUCCESS', data: response.data.data.archived_at === null ? 'activated' : 'archived' });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'ORGANIZATION_TOGGLE_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'ORGANIZATION_TOGGLE_ERROR', error: error.message || "" });
    }
}

function* getOrganizationStep({ step, orgId }) {
    try {
        let URL = "";
        let response = "";
        if (step === 1 || step === "general-info") {
            URL = `organization/${orgId}/general-info`;
        } else if (step === 2 || step === "primary-info") {
            URL = `organization/${orgId}/contacts`;
        } else if (step === 3 || step === "specific-info") {
            URL = `organization/${orgId}/specific-info`;
        } else if (step === 4) {
            URL = `organization/${orgId}/capacity-questions`;
        } else if (step === 5) {
            URL = `organization/${orgId}/funding-info`;
        }

        response = yield call(getRequestAPI, { url: URL, params: {} });

        if (response.status === 200 || response.status === 201) {
            if (step === 2) {
                yield put({ type: 'ORGANIZATION_STEP_SUCCESS', step2: response?.data?.data || [] });
            } else if (step === 4) {
                yield put({ type: 'ORGANIZATION_STEP_SUCCESS', step4: response?.data?.data || [] });
            } else if (step === 5) {
                yield put({ type: 'ORGANIZATION_STEP_SUCCESS', step5: response?.data?.data || [] });
            } else {
                yield put({ type: 'ORGANIZATION_STEP_SUCCESS', data: response?.data?.data || {} });
            }
        } else {
            yield put({ type: 'ORGANIZATION_STEP_ERROR', error: "No record found" });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: 'ORGANIZATION_STEP_ERROR', error: error.message || "" });
    }
}

function* getFormSpecificData({ step, orgId }) {
    try {
        // const response = {
        //     data: {
        //         "message": "Operation completed successfully.",
        //         "data": {
        //             "catchment_areas": [
        //                 {
        //                     "id": 1,
        //                     "name": "Ward 1 - Orléans"
        //                 },
        //                 {
        //                     "id": 2,
        //                     "name": "Ward 2 - Innes"
        //                 },
        //                 {
        //                     "id": 3,
        //                     "name": "Ward 3 - Barrhaven"
        //                 },
        //                 {
        //                     "id": 4,
        //                     "name": "Ward 4 - Kanata North"
        //                 },
        //                 {
        //                     "id": 5,
        //                     "name": "Ward 5 - West Carleton-March"
        //                 },
        //                 {
        //                     "id": 6,
        //                     "name": "Ward 6 - Stittsville"
        //                 },
        //                 {
        //                     "id": 7,
        //                     "name": "Ward 7 - Bay"
        //                 },
        //                 {
        //                     "id": 8,
        //                     "name": "Ward 8 - College"
        //                 },
        //                 {
        //                     "id": 9,
        //                     "name": "Ward 9 - Knoxdale-Marivale"
        //                 },
        //                 {
        //                     "id": 10,
        //                     "name": "Ward 10 - Gloucester-Southgate"
        //                 },
        //                 {
        //                     "id": 11,
        //                     "name": "Ward 11 - Rideau-Rockcliffe"
        //                 },
        //                 {
        //                     "id": 12,
        //                     "name": "Ward 12 - Somerset"
        //                 },
        //                 {
        //                     "id": 13,
        //                     "name": "Ward 13 - Kitchissippi"
        //                 },
        //                 {
        //                     "id": 14,
        //                     "name": "Ward 14 - River"
        //                 },
        //                 {
        //                     "id": 15,
        //                     "name": "Ward 15 - Capital"
        //                 },
        //                 {
        //                     "id": 16,
        //                     "name": "Ward 16 - Alta Vista"
        //                 },
        //                 {
        //                     "id": 17,
        //                     "name": "Ward 17 - Osgoode"
        //                 },
        //                 {
        //                     "id": 18,
        //                     "name": "Ward 18 - Rideau-Goulbourn"
        //                 },
        //                 {
        //                     "id": 19,
        //                     "name": "Ward 19 - Gloucester-South Nepean"
        //                 },
        //                 {
        //                     "id": 20,
        //                     "name": "Ward 20 - Kanata South"
        //                 }
        //             ],
        //             "target_demographics": [
        //                 {
        //                     "id": 1,
        //                     "name": "Indigenous peoples"
        //                 },
        //                 {
        //                     "id": 2,
        //                     "name": "Francophones"
        //                 },
        //                 {
        //                     "id": 3,
        //                     "name": "Immigrants"
        //                 },
        //                 {
        //                     "id": 4,
        //                     "name": "2SLGBTQ+"
        //                 },
        //                 {
        //                     "id": 5,
        //                     "name": "Older Adults"
        //                 },
        //                 {
        //                     "id": 6,
        //                     "name": "People with Disabilities"
        //                 },
        //                 {
        //                     "id": 7,
        //                     "name": "Racialized People"
        //                 },
        //                 {
        //                     "id": 8,
        //                     "name": "Rural Residents"
        //                 },
        //                 {
        //                     "id": 9,
        //                     "name": "Women"
        //                 },
        //                 {
        //                     "id": 10,
        //                     "name": "Youth"
        //                 },
        //                 {
        //                     "id": 11,
        //                     "name": "People living in Poverty"
        //                 }
        //             ],
        //         }
        //     },
        //     status: 200

        // }

        // yield delay(1000)
        let response = "";
        if (step === 3) {
            response = yield call(getRequestAPI, { url: `organization/${orgId}/specific-info-form-data`, params: {} });
        } else if (step === 5) {
            response = yield call(getRequestAPI, { url: `organization/${orgId}/funding-info-form-data`, params: {} });
        }

        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'ORGANIZATION_FORM_SPECIFIC_SUCCESS', data: response?.data?.data || {} });
        } else {
            yield put({ type: 'ORGANIZATION_FORM_SPECIFIC_ERROR', error: "No record found" });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: 'ORGANIZATION_FORM_SPECIFIC_ERROR', error: error.message || "" });
    }
}

function* postStepRequest({ step, orgId, formData, redirectTo }) {
    try {
        let URL = "";
        let response = "";
        if (step === 1 || step === "general-info") {
            URL = `organization/${orgId}/general-info`;
        } else if (step === 2 || step === "primary-info") {
            URL = `organization/${orgId}/contacts`;
        } else if (step === 3 || step === "specific-info") {
            URL = `organization/${orgId}/specific-info`;
        } else if (step === 4) {
            URL = `organization/${orgId}/capacity-questions`;
        } else if (step === 5) {
            URL = `organization/${orgId}/funding-info`;
        }
        response = yield call(postRequestAPI, { url: URL, data: formData });

        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'ORGANIZATION_STEP_POST_SUCCESS', data: redirectTo || "" });
        } else {
            yield put({ type: 'ORGANIZATION_STEP_POST_ERROR', error: response });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: 'ORGANIZATION_STEP_POST_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* organizationExport({ orgId }) {
    try {
        const response = yield call(getRequestAPI, { url: `organizations/${orgId}/export`, params: {} });
        if (response.status === 200) {
            let removedDoubleQuote = response.data;
            removedDoubleQuote = removedDoubleQuote.replace(/['"]+/g, '');
            yield put({ type: 'ORGANIZATION_EXPORT_SUCCESS', data: removedDoubleQuote });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'ORGANIZATION_EXPORT_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'ORGANIZATION_EXPORT_ERROR', error: error.message || "" });
    }
}
function* organizationImport({ formData }) {
    try {
        const response = yield call(postRequestAPI, { url: `organizations/import`, data: formData });
        if (response.status === 200) {
            yield put({ type: 'ORGANIZATION_IMPORT_SUCCESS', data: 'success' });
        } else {
            yield put({ type: 'ORGANIZATION_IMPORT_ERROR', data:'error', errorData: response?.errors || response?.message || "Something went wrong" });
        }
    } catch (error) {
        yield put({ type: 'ORGANIZATION_IMPORT_ERROR', error: error.message || "" });
    }
}

function* watchDonorConfigSagasRequests() {

    yield takeLatest('ORGANIZATION_LIST_REQUEST', getOrganizationList);
    yield takeLatest('ORGANIZATION_ADD_REQUEST', addOrganization);
    yield takeLatest('ORGANIZATION_UPDATE_REQUEST', updateOrganization);
    yield takeLatest('ORGANIZATION_DETAIL_REQUEST', getOrganizationDetail);
    yield takeLatest('ORGANIZATION_DELETE_REQUEST', deleteOrganization);
    yield takeLatest('ORGANIZATION_TOGGLE_REQUEST', updateStatus);

    yield takeLatest('ORGANIZATION_STEP_REQUEST', getOrganizationStep);
    yield takeLatest('ORGANIZATION_STEP_POST_REQUEST', postStepRequest);

    yield takeLatest('ORGANIZATION_FORM_SPECIFIC_REQUEST', getFormSpecificData);
    yield takeLatest('ORGANIZATION_EXPORT_REQUEST', organizationExport);
    yield takeLatest('ORGANIZATION_IMPORT_REQUEST', organizationImport);

}

function* donorConfigSagas() {
    yield watchDonorConfigSagasRequests();
}

export default donorConfigSagas;
