/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/fr-ca';
import frCA from 'antd/es/date-picker/locale/fr_CA';
import enUS from 'antd/es/date-picker/locale/en_US';
import {Button, Form, Input, Row, Col, message, Select, Card, Switch, Spin, DatePicker, ConfigProvider} from 'antd';
import DeleteModal from '../comnon/modal';
import useTitle from '../comnon/title';
import { ROLE_NAME } from '../../../utils/constant';
import useSettings from '../comnon/useSettings';
import { getSetting } from '../../../utils/functions';
import Wrapper from '../comnon/wrapper';
const { TextArea } = Input;

const AddGroupReport = () => {
    const { reportId } = useParams();
    const { t,i18n } = useTranslation();
    const isEnable = useSettings();
    useTitle(!reportId ? t("report_mngmt.add_report_group_title") : t("report_mngmt.edit_report_group_title"));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isReportLoading, reportList, isAddLoading, addStatus, isDeleteReportLoading, deleteReportStatus, isGetGroupLoading, groupReportData, isToggleLoading, reportStatus, isTagsLoading, tagsList } = useSelector(state => state.report);
    const { isRoleLoading, roleList } = useSelector(state => state.user);
    const { isLoading, organizationList } = useSelector(state => state.organization);
    const { isLoading: isStreamLoading, streamList, } = useSelector(state => state.stream);
    const [isSwitchLoading, setIsSwitchLoading] = useState(false);
    const [checked, setChecked] = useState(false);
    const [visible, setVisible] = useState(false);
    const [endDateDisabled, setEndDateDisabled] = useState(moment().endOf('day'));
    const [tmpUserGroup, setTmpUserGroup] = useState([]);
    const [isShowOnHomePage, setShowOnHomepage] = useState(false);
    const [defaultEndDate, setDefaultEndDate] = useState(null);
    const { Option } = Select;
    const [form] = Form.useForm();


    useEffect(() => {
        loadReports();
        loadRoles();

        if (reportId) {
            dispatch({ type: 'REPORT_GROUP_DETAIL_REQUEST', id: reportId });
        }

    }, []);

    useEffect(() => {
        dispatch({ type: "ORGANIZATION_LIST_REQUEST", formData: { status: "active" } })
        dispatch({ type: 'TAGS_LIST_REQUEST', formData: {} });
    }, []);

    const onSwitchChange = (e) => {
        dispatch({ type: "REPORT_TOGGLE_REQUEST", reportId: reportId, toggleType: 'reportGroup' })
    }

    useEffect(() => {
        dispatch({ type: 'STREAM_LIST_REQUEST', formData: { active: 1 } });
    }, []);

    useEffect(() => {
        form.resetFields();
    }, [])

    useEffect(() => {
        if (reportId && !isGetGroupLoading && Object.keys(groupReportData).length > 0) {
            setIsSwitchLoading(true);
            setChecked(groupReportData.archive_at === null ? true : false);
            form.setFieldsValue({
                organizations: [],
                reports: [],
                name_en: groupReportData.name_en,
                name_fr: groupReportData.name_fr,
                description_en: groupReportData.description_en,
                description_fr: groupReportData.description_fr,
                height: groupReportData.height,
                start: moment(groupReportData.start),
                end: moment(groupReportData.end),
                show_on_homepage: groupReportData.show_on_homepage,
            });
            setEndDateDisabled(moment(groupReportData.start).endOf('day'))
            setShowOnHomepage(groupReportData.show_on_homepage);
            if (groupReportData.organizations.length > 0) {
                let tmpOrganizations = groupReportData.organizations.map(e => e.id);
                form.setFieldsValue({ organizations: tmpOrganizations });
            }
            if (groupReportData.reports.length > 0) {
                let tmpreports = groupReportData.reports.map(e => e.id);
                form.setFieldsValue({ reports: tmpreports });
            }
            if (groupReportData.tags.length > 0) {
                let tmptags = groupReportData.tags.map(e => e.name);
                form.setFieldsValue({ tags: tmptags });
            }
            if (groupReportData.tags2 !== undefined && groupReportData.tags2.length > 0) {
                let tmptags = groupReportData.tags2.map(e => e.name);
                form.setFieldsValue({ tags2: tmptags });
            }

            if (groupReportData.streams.length > 0) {
                let tmpStreams = groupReportData.streams.map(e => e.id);
                form.setFieldsValue({ streams: tmpStreams });
            }
            if (groupReportData.roles.length > 0) {
                let tmproles = groupReportData.roles.map(e => e.id);
                setTmpUserGroup(tmproles)
            }
            setTimeout(() => {
                setIsSwitchLoading(false);
            }, 200);
        }
    }, [isGetGroupLoading, groupReportData]);

    const onFinish = (values) => {
        let Obj = {};
        for (const key in values) {
            if (values[key] !== undefined) {
                if (key === "start" || key === "end") {
                    let tmpDate = moment(values[key]).format(`YYYY-MM-DD`);
                    Obj[key] = `${tmpDate} 00:00:00`;
                } else {
                    Obj[key] = values[key];
                }
            }
        }
        Obj.roles = tmpUserGroup;
        Obj.client_id = 1;
        Obj.show_for_organization = true;
        Obj.show_on_homepage = isShowOnHomePage;
        if (reportId) {
            // return;
            dispatch({ type: 'REPORT_GROUP_UPDATE_REQUEST', id: reportId, formData: Obj });
        } else {
            dispatch({ type: 'REPORT_GROUP_ADD_REQUEST', formData: Obj });
        }
    }

    useEffect(() => {
        if (!isToggleLoading && reportStatus !== "") {
            dispatch({ type: 'CLEAR_REPORT_STATUS' });
            if (reportStatus === "activated") {
                message.success({ content: t("report_mngmt.active_group_success_msg"), className: 'custom-success-class' });
            } else {
                message.warn({ content: t("report_mngmt.archived_group_success_msg"), className: 'custom-success-class' });
            }
        }
    }, [isToggleLoading, reportStatus]);

    const onFinishFailed = () => { };
    const onChange = (value) => { };

    const onSearch = (value) => { };

    const onDelete = (e) => {
        dispatch({ type: 'REPORT_DELETE_REQUEST', id: e, deleteType: 'reportGroyp' });
    }

    const loadReports = (params = {}) => {
        dispatch({ type: 'REPORT_LIST_REQUEST', formData: { status: "active" } });
    }

    const loadRoles = (params = {}) => {
        dispatch({ type: 'USER_ROLE_LIST_REQUEST', formData: params });
    }

    const dateConfig = {
        rules: [
            {
                type: 'object',
                required: true,
                message: 'Please select date!',
            },
        ],
    };

    const onRoleChange = (e, item) => {
        let tmpGroup = [...tmpUserGroup];
        if (e) {
            if (!tmpGroup.includes(item.id)) {
                tmpGroup.push(item.id);
            }
        } else {
            tmpGroup = tmpGroup.filter((val) => {
                return val !== item.id
            })
        }
        setTmpUserGroup(tmpGroup);
    }

    const disabledEndDate = (current) => {
        return current <= endDateDisabled;
    };

    useEffect(() => {
        if (!isAddLoading && addStatus === "success") {
            dispatch({ type: 'CLEAR_REPORT_STATUS' });
            if (reportId) {
                message.success({ content: t('report_mngmt.group_update_success_msg'), className: 'custom-success-class' });
            } else {
                message.success({ content: t('report_mngmt.group_add_success_msg'), className: 'custom-success-class' });
            }
            navigate(`/reports-management?tab=report-group`);
        }
    }, [isAddLoading, addStatus]);

    useEffect(() => {
        if (!isDeleteReportLoading && deleteReportStatus === "success") {
            setVisible(false);
            navigate(`/reports-management?tab=report-group`)
        }
    }, [isDeleteReportLoading, deleteReportStatus]);
    return (
        <Wrapper>
            <div className='padding-around input-class'>
                {isReportLoading && <Spin style={{ width: "100%" }} />}
                {!isReportLoading &&
                    <>
                        {reportId &&
                            <Card className='archive-card'>
                                <div className='content'>
                                    <p>{t("report_mngmt.report_group_status_msg")}</p>
                                    <div className='switch-delete'>
                                        <p>
                                            {isSwitchLoading && <Switch
                                                checkedChildren={<span>{t('common.active')}</span>}
                                                unCheckedChildren={<span>{t('common.archived')}</span>}
                                                defaultChecked={checked}
                                                size='large'
                                                loading={isSwitchLoading}
                                            />}
                                            {!isSwitchLoading && <p>
                                                <Switch
                                                    checkedChildren={<span>{t('common.active')}</span>}
                                                    unCheckedChildren={<span>{t('common.archived')}</span>}
                                                    defaultChecked={checked}
                                                    onChange={(e) => onSwitchChange(e)}
                                                    size='large'
                                                    loading={isSwitchLoading}
                                                />
                                            </p>}
                                        </p>
                                        <Button
                                            htmlType="submit"
                                            type="primary"
                                            shape="round"
                                            danger
                                            onClick={() => { setVisible(true) }}
                                            className='ml2'
                                            size={"medium"}
                                        >
                                            {t('common.delete')}
                                        </Button>
                                    </div>
                                </div>
                            </Card>
                        }
                        <Form
                            className='sign-form'
                            name='basic'
                            onFinish={onFinish}
                            layout="vertical"
                            form={form}
                            autoComplete="off"
                            onFinishFailed={onFinishFailed}>

                            <Row gutter={70} >
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                    <Form.Item
                                        label={t('report_mngmt.english_name')}
                                        name='name_en'
                                        className='form-field'
                                        rules={[
                                            {
                                                required: true,
                                                message: t('report_mngmt.error_english_name'),
                                            }
                                        ]}
                                    >
                                        <Input placeholder={t("report_mngmt.english_name")} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                    <Form.Item
                                        label={t('report_mngmt.french_name')}
                                        name='name_fr'
                                        className='form-field'
                                        rules={[
                                            {
                                                required: true,
                                                message: t('report_mngmt.error_french_name'),
                                            },
                                        ]}
                                    >
                                        <Input placeholder={t("report_mngmt.french_name")} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={70} >
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                    <Form.Item
                                        label={t('report_mngmt.english_description')}
                                        name='description_en'
                                        className='form-field'
                                        rules={[
                                            {
                                                required: false,
                                                message: ''
                                            },
                                            {
                                                max: 500,
                                                message: t('common.max_error'),
                                            }
                                        ]}
                                    >
                                        <TextArea
                                            autoSize={{ minRows: 3 }}
                                            placeholder={t("report_mngmt.english_description")} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                    <Form.Item
                                        label={t('report_mngmt.french_description')}
                                        name='description_fr'
                                        className='form-field'
                                        rules={[
                                            {
                                                required: false,
                                                message: ''
                                            },
                                            {
                                                max: 500,
                                                message: t('common.max_error'),
                                            }
                                        ]}
                                    >
                                        <TextArea
                                            autoSize={{ minRows: 3 }}
                                            placeholder={t("report_mngmt.french_description")} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={70} >
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                    <Form.Item
                                        label={t("report_mngmt.specific_org")}
                                        name='organizations'
                                        className='form-field'
                                        rules={[
                                            {
                                                required: false,
                                                message: ''
                                            }
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder={t("report_mngmt.select_org")}
                                            optionFilterProp="children"
                                            onChange={onChange}
                                            onSearch={onSearch}
                                            loading={isLoading}
                                            autoComplete="none"
                                            mode="multiple"
                                            size="large"
                                        >

                                            {!isLoading && organizationList.length > 0 && organizationList.map((item) =>
                                                <Option key={item.id} value={item.id}>{item.name}</Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                    <Form.Item
                                        label={t("report_mngmt.report")}
                                        name='reports'
                                        className='form-field'
                                        rules={[
                                            {
                                                required: false,
                                                message: ''
                                            }
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder={t("report_mngmt.select_report")}
                                            optionFilterProp="children"
                                            onChange={onChange}
                                            onSearch={onSearch}
                                            loading={isReportLoading}
                                            autoComplete="none"
                                            mode="multiple"
                                            size="large"
                                        >
                                            {!isReportLoading && reportList.length > 0 && reportList.map((item) =>
                                                <Option key={item.id} value={item.id}>{item.name_en}</Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                {isEnable &&
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                        <Form.Item
                                            label={t("organization.stream")}
                                            name='streams'
                                            className='form-field'
                                            rules={[
                                                {
                                                    required: false,
                                                    message: ''
                                                }
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                placeholder={t("organization.select_stream")}
                                                optionFilterProp="children"
                                                onChange={onChange}
                                                onSearch={onSearch}
                                                loading={isStreamLoading}
                                                autoComplete="none"
                                                size="large"
                                                mode="multiple"
                                            >

                                                {!isStreamLoading && streamList.length > 0 && streamList.map((item) =>
                                                    <Option key={item.id} value={item.id}>{item.name_en}</Option>
                                                )}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                }
                            </Row>

                            <Row gutter={{ xs: 15, sm: 20, md: 35, lg: 70 }} >
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red report-user-select">
                                    <Form.Item label={t('common.user_groups')}>
                                        <div className="role-switch">
                                            {!isSwitchLoading && !isRoleLoading && roleList.length > 0 && roleList.map((item) => {
                                                if (item.name !== ROLE_NAME.CRG_USER) {
                                                    return (<Form.Item valuePropName="checked">
                                                        <span className="ant-form-text">{item.label}</span>
                                                        <Switch
                                                            defaultChecked={tmpUserGroup.includes(item.id)}
                                                            onChange={(e) => onRoleChange(e, item)}
                                                            checkedChildren={<span>{t('common.yes')}</span>}
                                                            unCheckedChildren={<span>{t('common.no')}</span>}
                                                            size='large'
                                                        />
                                                    </Form.Item>
                                                    );
                                                }
                                            }
                                            )}
                                            {(isSwitchLoading || isRoleLoading) && <Spin style={{ width: "100%" }} />}

                                        </div>

                                    </Form.Item>

                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red date-picker">
                                    <Form.Item label={t('report_mngmt.homepage')}>
                                        <div className="report-user-select role-switch-homepage" >
                                            <span className="ant-form-text">{t('report_mngmt.show_home_page')}</span>
                                            <Switch
                                                onChange={(e) => {
                                                    if (e) {
                                                        setShowOnHomepage(true);
                                                    } else {
                                                        setShowOnHomepage(false);
                                                    }
                                                }}
                                                checkedChildren={<span>{t('common.yes')}</span>}
                                                unCheckedChildren={<span>{t('common.no')}</span>}
                                                size='large'
                                                defaultChecked={isShowOnHomePage}
                                            />
                                        </div>
                                    </Form.Item>
                                    <Row gutter={35} >
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                            <Form.Item
                                                label={getSetting('filter1') !== "" ? getSetting('filter1') : t("report_mngmt.filter_field")}
                                                name='tags'
                                                className='form-field'
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: ''
                                                    }
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    placeholder={getSetting('filter1') !== "" ? `Enter ${getSetting('filter1')}` : t("report_mngmt.select_filter_field")}
                                                    optionFilterProp="children"
                                                    onChange={onChange}
                                                    onSearch={onSearch}
                                                    loading={isReportLoading || isTagsLoading}
                                                    autoComplete="none"
                                                    mode="tags"
                                                    size="large"
                                                >
                                                    {!isTagsLoading && Object.keys(tagsList).length > 0 && tagsList.field_no1.map((item) => {
                                                        return <Option key={item.name + item.id} value={item.name}>{item.name}</Option>
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                            <Form.Item
                                                label={getSetting('filter2') !== "" ? getSetting('filter2') : t("report_mngmt.filter_field2")}
                                                name='tags2'
                                                className='form-field'
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: ''
                                                    }
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    placeholder={getSetting('filter2') !== "" ? `Enter ${getSetting('filter2')}` : t("report_mngmt.select_filter_field2")}
                                                    optionFilterProp="children"
                                                    onChange={onChange}
                                                    onSearch={onSearch}
                                                    loading={isReportLoading || isTagsLoading}
                                                    autoComplete="none"
                                                    mode="tags"
                                                    size="large"
                                                >
                                                    {!isTagsLoading && Object.keys(tagsList).length > 0 && tagsList.field_no2.map((item) => {
                                                        return <Option key={item.name + item.id} value={item.name}>{item.name}</Option>
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={35} >
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                            <Form.Item name="start" {...dateConfig} label={t("report_mngmt.date_range_start")}>
                                                <DatePicker
                                                    locale={i18n.language === 'fr' ? frCA : enUS}
                                                    placeholder={t("report_mngmt.date_range_start")}
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    onChange={(e) => {
                                                        form.setFieldsValue({ end: null });
                                                        setDefaultEndDate(null);
                                                        setEndDateDisabled(e.endOf('day'))
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                            <Form.Item name="end" {...dateConfig} label={t("report_mngmt.date_range_end")}>

                                                <DatePicker
                                                    locale={i18n.language === 'fr' ? frCA : enUS}
                                                    defaultValue={defaultEndDate}
                                                    disabledDate={(e) => disabledEndDate(e)}
                                                    placeholder={t("report_mngmt.date_range_end")}
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                />


                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row gutter={70} className={"mt10"}>
                                <Col xs={{ span: 24, offset: 3 }} md={{ span: 24, offset: 6 }} lg={{ span: 12, offset: 9 }} >
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        shape="round"
                                        loading={isAddLoading}
                                        disabled={isAddLoading}
                                        size={"large"}>
                                        {!reportId ? t('common.confirm') : t('common.update')}
                                    </Button>
                                    <Button type="secondary" onClick={() => {
                                        dispatch({ type: "CLEAR_STATUS" });
                                        navigate('/reports-management?tab=report-group')
                                    }} className={"ml5"} shape="round" size={"large"}>
                                        {t('common.cancel')}
                                    </Button>
                                </Col>
                            </Row>

                        </Form>
                        <DeleteModal
                            visible={visible}
                            deleteId={reportId}
                            subTitle={t("report_mngmt.delete_confirm_group")}
                            setVisible={() => { setVisible(false) }}
                            onDelete={(e) => onDelete(e)}
                            isDeleteLoading={isDeleteReportLoading}
                        />
                    </>}
            </div>
        </Wrapper >
    );
}

export default AddGroupReport;
