import { put, takeLatest, call } from 'redux-saga/effects'
import { message } from 'antd';
//Import API Service.
import { getRequestAPI, postRequestAPI, deleteRequestAPI, putRequestAPI } from '../../services/API/api';
import { role } from '../../utils/functions';
import { ROLE_NAME } from '../../utils/constant';


function* getUsersList({ formData }) {
    try {

        const response = yield call(getRequestAPI, { url: `users`, params: formData });

        if (response.status === 200) {
            yield put({ type: 'USERS_LIST_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'USERS_LIST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'USERS_LIST_ERROR', error: error.message || "" });
    }
}

function* getRoleList({ formData }) {
    try {
        const response = yield call(getRequestAPI, { url: `roles`, params: formData });

        if (response.status === 200) {
            let filterRoles = response.data.data;
            if (role() === ROLE_NAME.CLIENT_ADMIN) {
                filterRoles = filterRoles.filter(e => e.name !== ROLE_NAME.CRG_USER);
            } else if (role() === ROLE_NAME.ORG_ADMIN) {
                filterRoles = filterRoles.filter(e => e.name === ROLE_NAME.ORG_ADMIN || e.name === ROLE_NAME.ORG_USER);
            } else if (role() === ROLE_NAME.CLIENT_USER || role() === ROLE_NAME.ORG_USER) {
                filterRoles = [];
            }
            yield put({ type: 'USER_ROLE_LIST_SUCCESS', data: filterRoles || [] });
        } else {
            yield put({ type: 'USER_ROLE_LIST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'USER_ROLE_LIST_ERROR', error: error.message || "" });
    }
}

function* addUser({ formData }) {
    try {
        const response = yield call(postRequestAPI, { url: `register`, data: formData });
        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'USER_ADD_SUCCESS', data: response.data.data || "" });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'USER_ADD_ERROR', error: response });
        }
    } catch (error) {
        yield put({ type: 'USER_ADD_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* getUserDetail({ id }) {
    try {
        const response = yield call(getRequestAPI, { url: `user/${id}`, params: {} });
        if (response.status === 200) {
            yield put({ type: 'USER_DETAIL_SUCCESS', data: response.data.data || [] });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'USER_DETAIL_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'USER_DETAIL_ERROR', error: error.message || "" });
    }
}

function* deleteUser({ id }) {
    try {
        const response = yield call(deleteRequestAPI, { url: `user/${id}` });
        if (response.status === 200 || response.status === 204) {
            yield put({ type: 'USER_DELETE_SUCCESS' });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'USER_DELETE_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'USER_DELETE_ERROR', error: error.message || "" });
    }
}

function* updateUser({ id, formData }) {
    try {
        const response = yield call(putRequestAPI, { url: `user/${id}`, data: formData });
        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'USER_UPDATE_SUCCESS', data: response.data.data || "" });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'USER_UPDATE_ERROR', error: response });
        }
    } catch (error) {
        yield put({ type: 'USER_UPDATE_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* updateStatus({ userId, status }) {
    try {
        const response = yield call(postRequestAPI, { url: `user/${userId}/toggle-status` });
        if (response.status === 200) {
            yield put({ type: 'USER_TOGGLE_SUCCESS', data: response.data.data.archived_at === null ? 'activated' : 'archived' });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'USER_TOGGLE_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'USER_TOGGLE_ERROR', error: error.message || "" });
    }
}

function* exportData({ params }) {
    try {
        const response = yield call(getRequestAPI, { url: `users/export`, params: params });
        if (response.status === 200) {
            let removedDoubleQuote = response.data;
            removedDoubleQuote = removedDoubleQuote.replace(/['"]+/g, '');
            yield put({ type: 'USER_EXPORT_SUCCESS', data: removedDoubleQuote });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'USER_EXPORT_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'USER_EXPORT_ERROR', error: error.message || "" });
    }
}

function* addContactUs({ formData }) {
    try {
        const response = yield call(postRequestAPI, { url: `contact-us`, data: formData });
        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'CONTACT_US_SUCCESS' });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'CONTACT_US_ERROR', error: response });
        }
    } catch (error) {
        yield put({ type: 'CONTACT_US_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* settingsRequest({ formData }) {
    try {
        const response = yield call(getRequestAPI, { url: `settings`, params: formData });
        if (response.status === 200 || response.status === 201) {
            localStorage.setItem('settings', JSON.stringify(response.data?.data));
            yield put({ type: 'SETTINGS_SUCCESS', data: response.data?.data });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'SETTINGS_ERROR', error: response });
        }
    } catch (error) {
        yield put({ type: 'SETTINGS_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* supportGuideRequest() {
    try {
        const response = yield call(getRequestAPI, { url: `settings/support-guides` });
        if (response.status === 200) {
            yield put({ type: 'SUPPORT_GUIDE_SUCCESS', data: response.data.support_guides });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'SUPPORT_GUIDE_ERROR', error: response });
        }
    } catch (error) {
        yield put({ type: 'SUPPORT_GUIDE_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* deleteSupportGuideRequest({id}) {
    try {
        const response = yield call(deleteRequestAPI, { url: `settings/support-guides/${id}` });
        if (response.status === 200) {
            yield put({ type: 'DELETE_SETTINGS_SUPPORT_GUIDE_SUCCESS'});
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'DELETE_SETTINGS_SUPPORT_GUIDE_ERROR', error: response });
        }
    } catch (error) {
        yield put({ type: 'DELETE_SETTINGS_SUPPORT_GUIDE_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* toggleSettingRequest({ formData }) {
    try {
        const response = yield call(putRequestAPI, { url: `toggleSurveyStream` });
        if (response.status === 200) {
            yield put({ type: 'TOGGLE_SETTINGS_SUCCESS', data: response?.data?.data?.enable_stream_survey ? 'enabled' : 'disabled' });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'TOGGLE_SETTINGS_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'TOGGLE_SETTINGS_ERROR', error: error.message || "" });
    }
}

function* updateSettingsRequest({ formData }) {
    try {
        const response = yield call(postRequestAPI, { url: `settings`, data: formData });
        if (response.status === 200) {
            yield put({ type: 'UPDATE_SETTINGS_SUCCESS', data: response?.data?.data?.enable_stream_survey ? 'enabled' : 'disabled' });
            message.success('Settings updated successfully')
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'UPDATE_SETTINGS_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'UPDATE_SETTINGS_ERROR', error: error.message || "" });
    }
}

function* uploadSettingsSupportGuideRequest({ formData }) {
    try {
        const response = yield call(postRequestAPI, { url: `settings/support-guides`, data: formData });
        if (response.status === 200) {
            yield put({ type: 'UPDATE_SETTINGS_SUPPORT_GUIDE_SUCCESS', data: response?.data});
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'UPDATE_SETTINGS_SUPPORT_GUIDE_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'UPDATE_SETTINGS_SUPPORT_GUIDE_ERROR', error: error.message || "" });
    }
}

function* watchDonorConfigSagasRequests() {
    yield takeLatest('USERS_LIST_REQUEST', getUsersList);
    yield takeLatest('USER_ROLE_LIST_REQUEST', getRoleList);
    yield takeLatest('USER_ADD_REQUEST', addUser);
    yield takeLatest('USER_UPDATE_REQUEST', updateUser);
    yield takeLatest('USER_DELETE_REQUEST', deleteUser);
    yield takeLatest('USER_DETAIL_REQUEST', getUserDetail);
    yield takeLatest('USER_TOGGLE_REQUEST', updateStatus);
    yield takeLatest('USER_EXPORT_REQUEST', exportData);

    yield takeLatest('CONTACT_US_REQUEST', addContactUs);

    yield takeLatest('SETTINGS_REQUEST', settingsRequest);
    yield takeLatest('TOGGLE_SETTINGS_REQUEST', toggleSettingRequest);

    yield takeLatest('UPDATE_SETTINGS_REQUEST', updateSettingsRequest);

    yield takeLatest('SUPPORT_GUIDE_REQUEST', supportGuideRequest);
    yield takeLatest('UPDATE_SETTINGS_SUPPORT_GUIDE_REQUEST', uploadSettingsSupportGuideRequest);
    yield takeLatest('DELETE_SETTINGS_SUPPORT_GUIDE_REQUEST', deleteSupportGuideRequest);


    // yield takeLatest('USER_DETAIL_UPDATE_REQUEST', updateRequest);

}

function* donorConfigSagas() {
    yield watchDonorConfigSagasRequests();
}

export default donorConfigSagas;
