/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Input } from 'antd';
import { PlusOutlined, EditFilled, DeleteFilled, DownloadOutlined } from '@ant-design/icons';
import { Space, Table, Tag, message, Tooltip } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Wrapper from '../comnon/wrapper';
import DeleteModal from '../comnon/modal.js';
import useTitle from '../comnon/title';
import { BASE_BACKEND_URL } from '../../../utils/constant';
import { accessToken } from '../../../utils/functions';
import { useTranslation } from "react-i18next";
const { Search } = Input;
const { Column } = Table;


const Streams = () => {

    const { t, i18n } = useTranslation();
    useTitle(t("stream.view_title"));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoading, streamList, isDeleteLoading, deleteStatus } = useSelector(state => state.stream);
    const [data, setData] = useState([]);

    const [visible, setVisible] = useState(false);
    const [deleteId, setDeleteId] = useState("");

    useEffect(() => {
        loadStream();
    }, []);

    const loadStream = (params = {}) => {
        dispatch({ type: 'STREAM_LIST_REQUEST', formData: params });
    }

    useEffect(() => {
        if (!isLoading && streamList.length > 0) {
            let tmpOrgList = [];
            streamList.map((item) => {
                let Obj = {};
                Obj.name = i18n.language === "fr" ? item.name_fr : item.name_en;
                Obj.key = item.id;
                Obj.status = item.status === 1 ? "Active" : "Archived";
                // Obj.survey_id = item.surveys.map((data) => data.survey_id);
                Obj.survey_id = item.surveys;
                // Obj.other = { ...item.client_id };
                tmpOrgList.push(Obj);
                return true;
            });

            setData(tmpOrgList);
        } else if (!isLoading && streamList.length === 0) {
            setData([]);
        }
    }, [isLoading, streamList, i18n.language])

    const onSearch = (e) => {
        loadStream({ search: e });
    }

    const onDelete = (e) => {
        dispatch({ type: 'STREAM_DELETE_REQUEST', id: deleteId });
    }

    useEffect(() => {
        if (!isDeleteLoading && deleteStatus === "success") {
            setVisible(false);
            dispatch({ type: 'CLEAR_STREAM' });
            message.success({ content: t('stream.delete_success_msg'), className: 'custom-success-class' });
            loadStream();
        }
    }, [isDeleteLoading, deleteStatus]);

    const exportStream = () => {
        window.open(`${BASE_BACKEND_URL}api/streams/export?token=${accessToken()}`)
    }
    return (
        <Wrapper title="survey_id View" >
            <div className='padding-around'>
                <Row className='nav-sub-header'>
                    <Col xs={24} sm={24} md={16} lg={16} xl={16} className="red">
                        <div className='nav-add-btn'>
                            <div>
                                <Button id="add-stream" type="primary" shape="circle" onClick={() => navigate('/stream/add')} icon={<PlusOutlined />} size={"medium"} />
                                <label htmlFor="add-stream" className="nav-add-label">{t('stream.add')}</label>
                            </div>
                            <div className='ml15'>
                                <Button id="export-stream"
                                    className='export-btn'
                                    type="primary"
                                    shape="circle"
                                    // loading={isPageLoading}
                                    // disabled={isPageLoading}
                                    onClick={() => exportStream()}
                                    icon={<DownloadOutlined />}
                                    size={"medium"} />
                                <label htmlFor="export-stream" className="nav-add-label">{t('common.export')}</label>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="green">
                        <Search allowClear placeholder={t("common.search_text")} onSearch={onSearch} enterButton />
                    </Col>
                </Row>

                <Row className='listing-table mt10'>
                    <Table
                        style={{ width: "100%" }}
                        dataSource={data}
                        loading={isLoading}
                        pagination={{
                            showTotal: (total, range) => `${range[0]}-${range[1]} ${t("common.of")} ${total} ${t("common.records")}`,
                        }}
                        locale={{
                            triggerDesc: t("common.desc_sorting"),
                            triggerAsc: t("common.asc_sorting"),
                            cancelSort: t("common.cancel_sorting")
                        }}
                    >
                        <Column
                            title={t("stream.stream")}
                            dataIndex='name'
                            key='name'
                            sorter={(a, b) => a.name.localeCompare(b.name)}
                        />

                        <Column
                            title={t("common.status")}
                            key='stream_status'
                            dataIndex='stream_status'
                            filters={[
                                {
                                    text: t("common.active"),
                                    value: 'Active',
                                },
                                {
                                    text: t("common.archive"),
                                    value: 'Archived',
                                }
                            ]}
                            filterMode='tree'
                            onFilter={(value, record) => record.status.startsWith(value)}
                            render={(_, { status }) => {
                                let color = (status !== 'Active') ? "default" : "success";
                                return (
                                    <Tag color={color} key={status}>
                                        {status.toUpperCase()}
                                    </Tag>)
                            }}
                        />
                        <Column
                            title={t("stream.survey_id")}
                            dataIndex='survey_id'
                            key='survey_id'
                            render={(_, record) => {
                                if (record.survey_id !== undefined && record.survey_id !== null && record.survey_id.length > 0) {
                                    return record.survey_id.map((item, index) => {
                                        return (
                                            // <Tag key={index} color={item.status === 1 ? 'default' : 'red'}>
                                            <Tag key={index} color={'default'}>
                                                {item.survey_id}
                                            </Tag>
                                        )
                                    });
                                }
                            }}
                        />

                        <Column
                            title={t("common.action")}
                            key='action'
                            render={(_, record) => (
                                <Space size="middle">
                                    <Tooltip placement="top" title={t("stream.edit_tooltip")}>
                                        <EditFilled className='cursor'
                                                    onClick={() => window.location.href = `/stream/edit/${record.key}`}
                                                    style={{ fontSize: "18px" }} />
                                    </Tooltip>
                                    <Tooltip placement="top" color={"red"} title={t("stream.delete_tooltip")}>
                                        <DeleteFilled
                                            onClick={() => {
                                                setDeleteId(record.key);
                                                setVisible(true)
                                            }}
                                            className='cursor' style={{ fontSize: "18px" }} />
                                    </Tooltip>
                                </Space>
                            )}
                        />
                    </Table>
                    <DeleteModal
                        visible={visible}
                        subTitle={t("stream.delete_confirm")}
                        setVisible={() => { setDeleteId(""); setVisible(false) }}
                        onDelete={() => onDelete()}
                        isDeleteLoading={isDeleteLoading}
                    />
                </Row>
            </div>
        </Wrapper>
    )
};

export default Streams;                    