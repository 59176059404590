const initialState = {
    isReportLoading: true,
    reportList: [],
    reportGroupList: [],

    isGetReportLoading: false,
    reportData: {},

    isAddLoading: false,
    addStatus: '',

    isDeleteReportLoading: false,
    deleteReportStatus: "",
    isToggleLoading: true,
    reportStatus: "",
    isGetGroupLoading: false,
    groupReportData: {},
    deleteType: "",

    isTagsLoading: false,
    tagsList: [],

    isExportLoading: false,
    exportData: "",

    isReportELoading: false,
    reportExportData: "",

    isReportViewLoading: false,
    token: ""

};

const reportReducer = (state = initialState, action = {}) => {


    switch (action.type) {

        case "REPORT_LIST_REQUEST":
            state = {
                ...state,
                isReportLoading: true,
                reportList: []

            };
            break;

        case "REPORT_LIST_SUCCESS":
            state = {
                ...state,
                isReportLoading: false,
                reportList: action.data
            };
            break;

        case "REPORT_LIST_ERROR":
            state = {
                ...state,
                isReportLoading: false,
                reportList: [],
                error: action.error,
            };
            break;


        case "REPORT_DETAIL_REQUEST":
            state = {
                ...state,
                isGetReportLoading: true,
                reportData: {}

            };
            break;

        case "REPORT_DETAIL_SUCCESS":
            state = {
                ...state,
                isGetReportLoading: false,
                reportData: action.data
            };
            break;

        case "REPORT_DETAIL_ERROR":
            state = {
                ...state,
                isGetGroupLoading: false,
                groupReportData: {},
                error: action.error,
            };
            break;

        case "REPORT_GROUP_DETAIL_REQUEST":
            state = {
                ...state,
                isGetGroupLoading: true,
                groupReportData: {}

            };
            break;

        case "REPORT_GROUP_DETAIL_SUCCESS":
            state = {
                ...state,
                isGetGroupLoading: false,
                groupReportData: action.data
            };
            break;

        case "REPORT_GROUP_DETAIL_ERROR":
            state = {
                ...state,
                isGetReportLoading: false,
                reportData: {},
                error: action.error,
            };
            break;

        case "REPORT_GROUP_ADD_REQUEST":
            state = {
                ...state,
                isAddLoading: true,
                addStatus: ''
            };
            break;

        case "REPORT_GROUP_UPDATE_REQUEST":
            state = {
                ...state,
                isAddLoading: true,
                addStatus: ''
            };
            break;

        case "REPORT_GROUP_ADD_SUCCESS":
            state = {
                ...state,
                isAddLoading: false,
                addStatus: "success"
            };
            break;

        case "REPORT_GROUP_ADD_ERROR":
            state = {
                ...state,
                isAddLoading: false,
                error: action.error,
                addStatus: ""
            };
            break;

        case "REPORT_ADD_REQUEST":
            state = {
                ...state,
                isAddLoading: true,
                addStatus: ''

            };
            break;

        case "REPORT_UPDATE_REQUEST":
            state = {
                ...state,
                isAddLoading: true,
                addStatus: ''
            };
            break;

        case "REPORT_ADD_SUCCESS":
            state = {
                ...state,
                isAddLoading: false,
                addStatus: "success"
            };
            break;

        case "REPORT_ADD_ERROR":
            state = {
                ...state,
                isAddLoading: false,
                error: action.error,
                addStatus: ""
            };
            break;



        case "REPORT_DELETE_REQUEST":
            state = {
                ...state,
                isDeleteReportLoading: true,
                deleteReportStatus: "",
                deleteType: ""
            };
            break;


        case "REPORT_DELETE_SUCCESS":
            state = {
                ...state,
                isDeleteReportLoading: false,
                deleteReportStatus: "success",
                deleteType: action.deleteType
            };
            break;

        case "REPORT_DELETE_ERROR":
            state = {
                ...state,
                isDeleteReportLoading: false,
                deleteReportStatus: "",
                deleteType: ""
            };
            break;

        case "REPORT_GROUP_LIST_REQUEST":
            state = {
                ...state,
                isReportLoading: true,
                reportList: [],
                reportGroupList: []

            };
            break;

        case "REPORT_GROUP_SEARCH_LIST_REQUEST":
            state = {
                ...state,
                isReportLoading: true,
                reportList: [],
                reportGroupList: []

            };
            break;

        case "REPORT_GROUP_LIST_SUCCESS":
            state = {
                ...state,
                isReportLoading: false,
                reportList: action.data,
                reportGroupList: action.data

            };
            break;

        case "REPORT_GROUP_LIST_ERROR":
            state = {
                ...state,
                isReportLoading: false,
                reportList: [],
                error: action.error,
            };
            break;

        case "REPORT_TOGGLE_REQUEST":
            state = {
                ...state,
                isToggleLoading: true,
                reportStatus: ""

            };
            break;

        case "REPORT_TOGGLE_SUCCESS":
            state = {
                ...state,
                isToggleLoading: false,
                reportStatus: action.data
            };
            break;

        case "REPORT_TOGGLE_ERROR":
            state = {
                ...state,
                isToggleLoading: false,
                reportStatus: ""
            };
            break;

        case "TAGS_LIST_REQUEST":
            state = {
                ...state,
                isTagsLoading: true,
                tagsList: ""

            };
            break;

        case "TAGS_LIST_SUCCESS":
            state = {
                ...state,
                isTagsLoading: false,
                tagsList: action.data
            };
            break;

        case "TAGS_LIST_ERROR":
            state = {
                ...state,
                isTagsLoading: false,
                tagsList: []
            };
            break;

        case "REPORT_EXPORT_REQUEST":
            state = {
                ...state,
                isReportELoading: true,
                reportExportData: ""
            };
            break;

        case "REPORT_EXPORT_SUCCESS":
            state = {
                ...state,
                isReportELoading: false,
                reportExportData: action.data
            };
            break;

        case "REPORT_EXPORT_ERROR":
            state = {
                ...state,
                isReportELoading: false,
                reportExportData: ""
            };
            break;

        case "GROUP_EXPORT_REQUEST":
            state = {
                ...state,
                isExportLoading: true,
                exportData: ""
            };
            break;

        case "GROUP_EXPORT_SUCCESS":
            state = {
                ...state,
                isExportLoading: false,
                exportData: action.data
            };
            break;

        case "GROUP_EXPORT_ERROR":
            state = {
                ...state,
                isExportLoading: false,
                exportData: ""
            };
            break;

        case "CLEAR_REPORT_STATUS":
            state = {
                ...state,
                addStatus: "",
                reportStatus: "",
                deleteReportStatus: "",
                deleteType: "",
                exportData: "",
                reportExportData: ""


            };
            break;


        default:

    }
    return state;
};

export default reportReducer;