const initialState = {
    isLoading: true,
    userList: [],
    isRoleLoading: false,
    roleList: [],
    isAddLoading: false,
    isGetLoading: false,
    userData: {},

    isDeleteLoading: false,
    deleteStatus: "",

    addStatus: '',
    error: "",
    isToggleLoading: false,
    userStatus: "",
    title: "HOME PAGE",
    isExportLoading: false,
    exportData: "",

    isContactLoading: false,
    contactMsg: "",

    isSettingLoading: false,
    settingsData: {},
    isSettingToggleLoading: false,
    settingToggleStatus: "",

    isSettingUpdateLoading: false,
    isSettingUpdateStatus: '',


    supportGuideList: [],
    isSupportGuideListLoading: false,

    supportGuideUploadLoading: false,
    supportGuideUploadStatus: '',

    supportGuideDeleteLoading: false,
    supportGuideDeleteStatus: '',

};

const userReducer = (state = initialState, action = {}) => {

    switch (action.type) {

        case "USERS_LIST_REQUEST":
            state = {
                ...state,
                isLoading: true,
                userList: []

            };
            break;

        case "USERS_LIST_SUCCESS":
            state = {
                ...state,
                isLoading: false,
                userList: action.data
            };
            break;

        case "USERS_LIST_ERROR":
            state = {
                ...state,
                isLoading: false,
                userList: [],
                error: action.error,
            };
            break;

        case "USER_ROLE_LIST_REQUEST":
            state = {
                ...state,
                isRoleLoading: true,
                roleList: []

            };
            break;

        case "USER_ROLE_LIST_SUCCESS":
            state = {
                ...state,
                isRoleLoading: false,
                roleList: action.data
            };
            break;

        case "USER_ROLE_LIST_ERROR":
            state = {
                ...state,
                isRoleLoading: false,
                roleList: [],
                error: action.error,
            };
            break;

        case "USER_ADD_REQUEST":
            state = {
                ...state,
                isAddLoading: true,
                addStatus: ''

            };
            break;

        case "USER_ADD_SUCCESS":
            state = {
                ...state,
                isAddLoading: false,
                addStatus: "success"
            };
            break;

        case "USER_ADD_ERROR":
            state = {
                ...state,
                isAddLoading: false,
                error: action.error,
                addStatus: ""
            };
            break;

        case "CLEAR_USER_STATUS":
            state = {
                ...state,
                addStatus: "",


            };
            break;

        case "UPDATE_TITLE":
            state = {
                ...state,
                title: action.data,

            };
            break;

        case "USER_DETAIL_REQUEST":
            state = {
                ...state,
                isGetLoading: true,
                userData: {}

            };
            break;

        case "USER_DETAIL_SUCCESS":
            state = {
                ...state,
                isGetLoading: false,
                userData: action.data
            };
            break;

        case "USER_DETAIL_ERROR":
            state = {
                ...state,
                isGetLoading: false,
                userData: {},
                error: action.error,
            };
            break;

        case "USER_UPDATE_REQUEST":
            state = {
                ...state,
                isAddLoading: true,
                addStatus: ''

            };
            break;

        case "USER_UPDATE_SUCCESS":
            state = {
                ...state,
                isAddLoading: false,
                addStatus: "success"
            };
            break;

        case "USER_UPDATE_ERROR":
            state = {
                ...state,
                isAddLoading: false,
                error: action.error,
                addStatus: ""
            };
            break;


        case "USER_DELETE_REQUEST":
            state = {
                ...state,
                isDeleteLoading: true,
                deleteStatus: ""

            };
            break;

        case "USER_DELETE_SUCCESS":
            state = {
                ...state,
                isDeleteLoading: false,
                deleteStatus: "success"
            };
            break;

        case "USER_DELETE_ERROR":
            state = {
                ...state,
                isDeleteLoading: false,
                deleteStatus: ""
            };
            break;

        case "USER_TOGGLE_REQUEST":
            state = {
                ...state,
                isToggleLoading: true,
                userStatus: ""

            };
            break;

        case "USER_TOGGLE_SUCCESS":
            state = {
                ...state,
                isToggleLoading: false,
                userStatus: action.data
            };
            break;

        case "USER_TOGGLE_ERROR":
            state = {
                ...state,
                isToggleLoading: false,
                userStatus: ""
            };
            break;

        case "USER_EXPORT_REQUEST":
            state = {
                ...state,
                isExportLoading: true,
                exportData: ""
            };
            break;

        case "USER_EXPORT_SUCCESS":
            state = {
                ...state,
                isExportLoading: false,
                exportData: action.data
            };
            break;

        case "USER_EXPORT_ERROR":
            state = {
                ...state,
                isExportLoading: false,
                exportData: ""
            };
            break;

        case "CONTACT_US_REQUEST":
            state = {
                ...state,
                isContactLoading: true,
                contactMsg: ""
            };
            break;

        case "CONTACT_US_SUCCESS":
            state = {
                ...state,
                isContactLoading: false,
                contactMsg: "success"
            };
            break;

        case "CONTACT_US_ERROR":
            state = {
                ...state,
                isContactLoading: false,
                contactMsg: ""
            };
            break;

        /**----------------**/
        case "SETTINGS_REQUEST":
            state = {
                ...state,
                isSettingLoading: true,
                settingsData: {}
            };
            break;

        case "SETTINGS_SUCCESS":
            state = {
                ...state,
                isSettingLoading: false,
                settingsData: action.data
            };
            break;

        case "SETTINGS_ERROR":
            state = {
                ...state,
                isSettingLoading: false,
                settingsData: {}
            };
            break;

        case "TOGGLE_SETTINGS_REQUEST":
            state = {
                ...state,
                isSettingToggleLoading: true,
                settingToggleStatus: ""

            };
            break;

        case "TOGGLE_SETTINGS_SUCCESS":
            state = {
                ...state,
                isSettingToggleLoading: false,
                settingToggleStatus: action.data
            };
            break;

        case "TOGGLE_SETTINGS_ERROR":
            state = {
                ...state,
                isSettingToggleLoading: false,
                settingToggleStatus: ""
            };
            break;

        case "UPDATE_SETTINGS_REQUEST":
            state = {
                ...state,
                isSettingUpdateLoading: true,
                isSettingUpdateStatus: ""

            };
            break;

        case "UPDATE_SETTINGS_SUCCESS":
            state = {
                ...state,
                isSettingUpdateLoading: false,
                isSettingUpdateStatus: 'success'
            };
            break;

        case "UPDATE_SETTINGS_ERROR":
            state = {
                ...state,
                isSettingUpdateLoading: false,
                isSettingUpdateStatus: ""
            };
            break;

        case "SUPPORT_GUIDE_REQUEST":
            state = {
                ...state,
                isSupportGuideListLoading: true,
                supportGuideList: [],
            };
            break;

        case "SUPPORT_GUIDE_SUCCESS":
            state = {
                ...state,
                isSupportGuideListLoading: false,
                supportGuideList: action.data,
            };
            break;

        case "SUPPORT_GUIDE_ERROR":
            state = {
                ...state,
                isSupportGuideListLoading: false,
                supportGuideList: [],
            };
            break;

        case "UPDATE_SETTINGS_SUPPORT_GUIDE_REQUEST":
            state = {
                ...state,
                supportGuideUploadLoading: true,
                supportGuideUploadStatus: ''

            };
            break;

        case "UPDATE_SETTINGS_SUPPORT_GUIDE_SUCCESS":
            state = {
                ...state,
                supportGuideUploadLoading: false,
                supportGuideUploadStatus: 'success'
            };
            break;

        case "UPDATE_SETTINGS_SUPPORT_GUIDE_ERROR":
            state = {
                ...state,
                supportGuideUploadLoading: false,
                supportGuideUploadStatus: ''
            };
            break;

        case "DELETE_SETTINGS_SUPPORT_GUIDE_REQUEST":
            state = {
                ...state,
                supportGuideDeleteLoading: true,
                supportGuideDeleteStatus: ''

            };
            break;

        case "DELETE_SETTINGS_SUPPORT_GUIDE_SUCCESS":
            state = {
                ...state,
                supportGuideDeleteLoading: false,
                supportGuideDeleteStatus: 'success'
            };
            break;

        case "DELETE_SETTINGS_SUPPORT_GUIDE_ERROR":
            state = {
                ...state,
                supportGuideDeleteLoading: false,
                supportGuideDeleteStatus: ''
            };
            break;


        case "CLEAR_STATUS":
            state = {
                ...state,
                addStatus: "",
                userStatus: "",
                deleteStatus: "",
                isExportLoading: false,
                exportData: "",
                contactMsg: "",
                settingToggleStatus: "",
                isSettingUpdateStatus: ""

            };
            break;
        default:

    }
    return state;
};

export default userReducer;