import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

let selected = 'en';
if(localStorage.getItem('selectedLanguage')) {
    selected = localStorage.getItem('selectedLanguage');
}

i18n
    // learn more: https://github.com/i18next/i18next-xhr-backend
    .use(Backend)
    // connect with React
    .use(LanguageDetector)
    .use(initReactI18next)
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: false,
        enabled: false,
        lng: selected,
        fallbackLng: selected,
        whitelist: ['en', 'fr'],
        lookupLocalStorage: 'i18nextLng',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
     
        backend: {
            loadPath: '/locales/{{lng}}/translation.json',
        },
        react: {
            wait: true,
        },
    });
 
export default i18n;